import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { InlineHiddenTextButton, MessageResponse } from '../../../api';
import { MessageType } from '../../post-type';
import { HiddenTextPreviewDialogComponent } from '../../../components/hidden-text-preview-dialog/hidden-text-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'tg-post-message-item',
  templateUrl: './post-message-item.component.html',
  styleUrls: ['../post-item/post-item.component.scss', './post-message-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostMessageItemComponent implements OnInit, AfterViewInit {
  types = MessageType;

  @Input() msg: MessageResponse;

  @ViewChild('textItem', { static: true })
  textItem: ElementRef;

  collapseText = true;

  constructor(private cd: ChangeDetectorRef, private dialog: MatDialog, private mq: MediaObserver) {}

  ngOnInit() {}

  cdnUrl(value: string) {
    return `${document.location.origin}/api/bot-cdn/file/${value}`;
  }

  get isStickerAnimated() {
    return this.msg.file.sticker.isAnimated;
  }

  get text() {
    if (this.msg.type === 'poll') {
      return this.msg.question;
    }

    return this.msg.quillDelta;
  }

  ngAfterViewInit(): void {
    if (this.textItem) {
      const data = this.textItem.nativeElement.getBoundingClientRect();
      const showAll = this.msg.inTextPhotoUrl ? data.height < 100 : data.height < 200;
      if (showAll) {
        this.collapseText = false;
        this.cd.detectChanges();
      }
    }
  }

  get attachedPhotoFile() {
    const photoRe = /\.(?:jpe?g|png|gif)/i;
    return photoRe.exec(this.msg.inTextPhotoUrl) ? true : false;
  }

  get answersWithVotes() {
    if (this.msg.votes && this.msg.votes.length) {
      let votes = this.msg.votes;
      return this.msg.answers.map((a, index) => `${a} - ${votes[index]}`);
    }
    return this.msg.answers;
  }

  openPreviewHiddenText(data: InlineHiddenTextButton) {
    const isXs = this.mq.isActive('xs');
    this.dialog.open(HiddenTextPreviewDialogComponent, { data: data, minWidth: isXs ? '90vw' : '450px' });
  }
}
