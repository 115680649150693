<div
  class="mat-secondary"
  style="font-size: 12px; margin-bottom: 5px;"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div [ngSwitch]="post.state">
    <ng-template ngSwitchCase="draft">
      <ng-container i18n="Post item|label @@postItem.createdLabel">Создан:</ng-container>
      {{ post.createdAt | dateTime: 'dd MMMM yyyy' }}
      <span class="time-big">{{ post.createdAt | dateTime: 'HH:mm' }}</span>
    </ng-template>
    <ng-template ngSwitchCase="queue">
      <ng-container i18n="Post item|label @@postItem.queueLabel">Запланирован на:</ng-container>
      <span matTooltip="Часовой пояс: {{ post.scheduleAt | dateTime: 'z' }}"
            i18n-matTooltip="Post item|tooltip @@postItem.timezoneTooltip">
        {{ post.scheduleAt | dateTime: 'EEE, dd MMMM yyyy' }}
        <span class="time-big">{{ post.scheduleAt | dateTime: 'HH:mm' }}</span>
      </span>
    </ng-template>
    <ng-template ngSwitchCase="sent">
      <span
        style="cursor: pointer;"
        (click)="showViewsByHours(selectedChannelId)"
        matTooltip="Просмотры"
        i18n-matTooltip="Post item|tooltip @@postItem.viewsTooltip"
        *ngIf="selectedChannelId && post.state === 'sent' && post.views && post.views[selectedChannelId]"
      >
        {{ post.views[selectedChannelId] | numberThousandsFormat }}
        <mat-icon class="icon-16" style="vertical-align: text-bottom;">visibility</mat-icon>
        &nbsp;|&nbsp;
      </span>
      <span
        style="cursor: pointer;"
        matTooltip="Реакции"
        i18n-matTooltip="Post item|tooltip @@postItem.reactionsTooltip"
        (click)="showVoters()"
        *ngIf="selectedChannelId && post.state === 'sent' && post.reactions && post.reactions[selectedChannelId]"
      >
        {{ post.reactions[selectedChannelId] | numberThousandsFormat }}
        <mat-icon class="icon-16" style="vertical-align: text-bottom;">thumbs_up_down</mat-icon>
        &nbsp;|&nbsp;
      </span>
      <span
        style="cursor: pointer;"
        matTooltip="Клики по ссылкам"
        i18n-matTooltip="Post item|tooltip @@postItem.linkClicksTooltip"
        (click)="showClicksByLinks(selectedChannelId)"
        *ngIf="selectedChannelId && post.state === 'sent' && post.clicks && post.clicks[selectedChannelId]"
      >
        {{ post.clicks[selectedChannelId] | numberThousandsFormat }}
        <mat-icon class="icon-16" style="vertical-align: text-bottom;">touch_app</mat-icon>
        &nbsp;|&nbsp;
      </span>
      <span
        style="cursor: pointer;"
        matTooltip="Читать продолжение"
        i18n-matTooltip="Post item|tooltip @@postItem.hiddenTextTooltip"
        (click)="showHiddenTextClicks(selectedChannelId)"
        *ngIf="___hiddenTextStatsFeature && selectedChannelId && post.state === 'sent' && post.hiddenLinksClicksCount && post.hiddenLinksClicksCount[selectedChannelId]"
      >
        {{ post.hiddenLinksClicksCount[selectedChannelId] | numberThousandsFormat }}
        <mat-icon class="icon-16" style="vertical-align: text-bottom;">read_more</mat-icon>
        &nbsp;|&nbsp;
      </span>
      <span matTooltip="Опубликовано, часовой пояс: {{ post.publishedAt | dateTime: 'z' }}"
            i18n-matTooltip="Post item|tooltip @@postItem.sentTooltip">
        {{ post.publishedAt | dateTime: 'dd MMMM yyyy' }}
        <span class="time-big">{{ post.publishedAt | dateTime: 'HH:mm' }}</span>
      </span>
    </ng-template>
    <ng-template ngSwitchCase="trash">
      <ng-container i18n="Post item|label @@postItem.deletedLabel">Удален:</ng-container>
      {{ post.trashAt | dateTime: 'dd MMMM yyyy' }}
      <span class="time-big">{{ post.trashAt | dateTime: 'HH:mm' }}</span></ng-template
    >
    , <span matTooltip="Создатель: {{ post.creator.shortInfo }}"
            i18n-matTooltip="Post item|tooltip @@postItem.creatorTooltip">{{ post.creator.firstName }}</span>
  </div>
  <span style="flex: 1 1 auto;"></span>
  <div style="height: 16px;" class="indicator-setting-post">
    <mat-icon class="icon-16" matTooltip="Закрепить" i18n-matTooltip="Post item|tooltip @@postItem.pinTooltip"
              *ngIf="post.pin">
      <svg style="width: 16px; height: 16px;" viewBox="0 0 24 24">
        <path fill="rgba(0, 0, 0, 0.54)" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
      </svg>
    </mat-icon>
    <mat-icon class="icon-16" matTooltip="Отключить уведомление"
              i18n-matTooltip="Post item|tooltip @@postItem.notificationsTooltip" *ngIf="!post.notifications"
    >notifications_off
    </mat-icon>
    <mat-icon class="icon-16" matTooltip="Комментарии включены"
              i18n-matTooltip="Post item|tooltip @@postItem.commentsTooltip" *ngIf="post.enableComments">comment
    </mat-icon>
    <mat-icon class="icon-16" matTooltip='Повтор публикации "{{post.postTasks?.cron.expression}}"'
              i18n-matTooltip="Post item|tooltip @@postItem.cronTooltip"
              *ngIf="post.postTasks?.cron">repeat
    </mat-icon>
    <mat-icon
      class="icon-16"
      matTooltipCustomClass=".red"
      matTooltip="{{deletedTooltip}}
              {{ post.tgDeletedAt | dateTime: 'dd MMMM yyyy в HH:mm' }}"
      *ngIf="post.deleteAfter"
      color="{{ post.tgDeleted ? 'warn' : 'default' }}"
    >timer
    </mat-icon
    >
  </div>
</div>
<!--<div class="mat-secondary" style="font-size: 12px; margin-bottom: 5px">-->
<!--  <span matTooltip="Просмотры" *ngIf="selectedChannelId && post.state === 'sent' && post.views[selectedChannelId]">-->
<!--    {{ post.views[selectedChannelId] | numberThousandsFormat }}-->
<!--    <mat-icon class="icon-16" style="vertical-align: text-bottom;">visibility</mat-icon>-->
<!--  </span>-->
<!--</div>-->
<div *ngIf="post.channels.length > 1 || forceViewChannel">
  <mat-nav-list [disableRipple]="true">
    <h3 mat-subheader i18n="Post item|header @@postItem.channelListHeader">Каналы публикации</h3>
    <mat-list-item *ngFor="let ch of post.channels">
      <img matListAvatar [src]="ch.photoUrl" />
      <div matLine>
        {{ ch.title }}
      </div>
      <p matLine class="mat-secondary" i18n="Post item|text @@postItem.channelListDesc">@{{ ch.username }}
        , {{ ch.membersCount | number }} подписчиков</p>
    </mat-list-item>
  </mat-nav-list>
</div>
<tg-error-block
  *ngIf="publishError"
  errorMessage="Не удалось опубликовать пост"
  i18n-errorMessage="Post item|error @@postItem.publishError"
  [errorDetails]="publishError"
></tg-error-block>

<tg-post-view-container [post]="post"></tg-post-view-container>

<div *ngIf="showNavigation" [ngSwitch]="post.state" style="margin-top: 8px;">
  <ng-template ngSwitchCase="draft">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button class="draftPostEditBtn" color="secondary" (click)="editPost()">
        <mat-icon>edit</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.edit">Редактировать</ng-container>
      </button>
      <button mat-button class="draftPostQueueBtn" color="secondary" (click)="schedule()">
        <mat-icon>timer</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.queue">Запланировать</ng-container>
      </button>
      <button mat-icon-button fxHide.xs="" class="draftPostMenuBtn" color="secondary" [matMenuTriggerFor]="draftMenu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <button mat-icon-button fxHide.gt-xs="" class="draftPostMenuBtn" color="secondary" (click)="openBottomSheet()">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <mat-menu #draftMenu="matMenu">
      <button mat-menu-item (click)="copyPost()" [disabled]="!canCopyPost">
        <mat-icon>content_copy</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.copy">Создать копию</ng-container>
      </button>
      <button mat-menu-item (click)="sharePost()" [disabled]="!canSharePost">
        <mat-icon>link</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.share">Доступ по ссылке</ng-container>
      </button>
      <button mat-menu-item (click)="sendNow()">
        <mat-icon>send</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.sendNow">Опубликовать сейчас</ng-container>
      </button>
      <button mat-menu-item (click)="previewPost()">
        <mat-icon>visibility</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.botPreview">Предпросмотр в бот</ng-container>
      </button>
      <button mat-menu-item (click)="trashPost()">
        <mat-icon color="warn">delete</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.trash">В корзину</ng-container>
      </button>
    </mat-menu>
  </ng-template>
  <ng-template ngSwitchCase="queue">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button class="queue-post-change-publish-at-btn" color="secondary" (click)="schedule()">
        <mat-icon>schedule</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.editPublishTime">Изменить время публикации</ng-container>
      </button>
      <button
        mat-icon-button
        fxHide.xs=""
        class="queue-post-menu-btn"
        color="secondary"
        [matMenuTriggerFor]="queueMenu"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <button mat-icon-button fxHide.gt-xs="" class="queue-post-menu-btn" color="secondary" (click)="openBottomSheet()">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <mat-menu #queueMenu="matMenu">
      <button mat-menu-item (click)="editPost()">
        <mat-icon>edit</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.edit">Редактировать</ng-container>
      </button>
      <button mat-menu-item (click)="sendNow()">
        <mat-icon>send</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.sendNow">Опубликовать сейчас</ng-container>
      </button>
      <button mat-menu-item (click)="sharePost()" [disabled]="!canSharePost">
        <mat-icon>link</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.share">Доступ по ссылке</ng-container>
      </button>
      <button mat-menu-item (click)="previewPost()">
        <mat-icon>visibility</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.botPreview">Предпросмотр в бот</ng-container>
      </button>
      <button mat-menu-item (click)="removeQueue()">
        <mat-icon>undo</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.dequeue">Снять с очереди</ng-container>
      </button>
      <button mat-menu-item (click)="copyPost()" [disabled]="!canCopyPost">
        <mat-icon>content_copy</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.copy">Создать копию</ng-container>
      </button>
      <button mat-menu-item (click)="trashPost()">
        <mat-icon color="warn">delete</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.trash">В корзину</ng-container>
      </button>
    </mat-menu>
  </ng-template>
  <ng-template ngSwitchCase="sent">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button class="sent-post-edit-btn" color="secondary" (click)="editPost()" *ngIf="!post.tgDeleted">
        <mat-icon>edit</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.editPublished">Изменить публикацию</ng-container>
      </button>
      <div class="post-removed" *ngIf="post.tgDeleted">
        <ng-container i18n="Post item|label @@postItem.actions.deletedLabel">Пост удалён из Телеграм</ng-container>
      </div>

      <!--<button mat-button class="sent-post-trash-btn" color="secondary" (click)="trashPost()">-->
      <!--<mat-icon>delete</mat-icon>-->
      <!--В корзину-->
      <!--</button>-->
      <button mat-icon-button class="sent-post-menu-btn" fxHide.xs="" color="secondary" [matMenuTriggerFor]="sentMenu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <button mat-icon-button fxHide.gt-xs="" class="sent-post-menu-btn" color="secondary" (click)="openBottomSheet()">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>

    <mat-menu #sentMenu="matMenu">
      <button mat-menu-item (click)="copyPost()" [disabled]="!canCopyPost">
        <mat-icon>content_copy</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.copy">Создать копию</ng-container>
      </button>
      <button mat-menu-item (click)="sharePost()" [disabled]="!canSharePost">
        <mat-icon>link</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.share">Доступ по ссылке</ng-container>
      </button>
      <button mat-menu-item (click)="showVoters()" *ngIf="canShowVoters">
        <mat-icon>thumbs_up_down</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.reactionsStats">Статистика реакций</ng-container>
      </button>
      <button mat-menu-item (click)="showPollVoters()" *ngIf="canShowPollVoters">
        <mat-icon>bar_chart</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.pollStats">Статистика опроса</ng-container>
      </button>
      <button mat-menu-item (click)="deleteAutoRemoveTimer()" *ngIf="post.deleteAfter && !post.tgDeleted">
        <mat-icon>delete_sweep</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.cancelAutodelete">Отменить таймер автоудаления</ng-container>
      </button>
      <button mat-menu-item (click)="trashPost()">
        <mat-icon color="warn">delete</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.trash">В корзину</ng-container>
      </button>
      <button mat-menu-item (click)="trashPostWithTelegram()" *ngIf="!post.tgDeleted">
        <mat-icon color="warn">delete</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.deleteFromTelegram">Удалить из Telegram</ng-container>
      </button>
    </mat-menu>
  </ng-template>
  <ng-template ngSwitchCase="trash">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button (click)="copyPost()" [disabled]="!canCopyPost">
        <mat-icon>content_copy</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.copy">Создать копию</ng-container>
      </button>

      <button mat-button color="secondary" (click)="restorePost()">
        <mat-icon>restore</mat-icon>
        <ng-container i18n="Post item|button @@postItem.actions.restore">Восстановить</ng-container>
      </button>
    </div>
  </ng-template>
</div>

<ng-template #selectEditMsg>
  <tg-mobile-full-screen-dialog>
    <h2 class="title" i18n="Post item|title @@postItem.selectEditMessage.title">Выберите сообщение</h2>
    <div class="select-post-for-edit-list" style="padding: 16px 0;">
      <mat-card
        *ngFor="let msg of selectMsgFilter(post.messages)"
        style="margin-bottom: 16px; max-width: 340px; height: 80px; overflow: hidden; position: relative;"
      >
        <tg-post-message-item [msg]="msg"></tg-post-message-item>
        <div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: #ffffffd6;">
          <button mat-button style="width: 100%; height: 100%;" color="primary" (click)="selectMsg(msg)">
            <ng-container i18n="Post item|button @@postItem.selectEditMessage.selectBtn">Выбрать сообщение</ng-container>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </mat-card>
    </div>
  </tg-mobile-full-screen-dialog>
</ng-template>
