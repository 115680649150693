import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostsCalendarV2Component } from './posts-calendar-v2/posts-calendar-v2.component';
import { DayPostsListDialogComponent } from './day-posts-list-dialog/day-posts-list-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { CalendarPostItemComponent } from './calendar-post-item/calendar-post-item.component';
import { PostDialogComponent } from './calendar-post-item/post-dialog/post-dialog.component';
import { PostViewModule } from '../post-view/post-view.module';

@NgModule({
  declarations: [PostsCalendarV2Component, DayPostsListDialogComponent, CalendarPostItemComponent, PostDialogComponent],
  imports: [CommonModule, SharedModule, PostViewModule],
  exports: [PostsCalendarV2Component, DayPostsListDialogComponent],
})
export class PostsCalendarModule {}
