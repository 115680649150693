<tg-top-nav style="position: relative"></tg-top-nav>

<div style="background-color: #eeeeee">
  <div class="container">
    <div class="welcome-landing">
      <h1 class="mat-display-1" style="margin-bottom: 20px" i18n="Home page|title @@homePage.title">Добро пожаловать в
        Telepost</h1>
      <div class="mat-subheading-1" style="max-width: 400px" i18n="Home page|title @@homePage.desc">
        Сервис отложенной публикации постов для Telegram.
        С его помощью вы с легкостью сможете запланировать выход постов на месяцы вперед.
      </div>
    </div>
  </div>
</div>

<div class="container" style="margin-top: 16px; min-height: calc(100vh - 350px)" *ngIf="channels$|async as channels">
  <h3 class="mat-secondary" fxLayout="row" fxLayoutAlign="space-between center">
    <span i18n="Home page|text @@homePage.channelsCount">
      Ваши каналы<span *ngIf="channels.count > 0"> - {{channels.count}}</span>
    </span>
    <button id="addChannelBtn" mat-raised-button color="primary" *ngIf="channels?.count > 0"
            (click)="openAddChannelForm()" i18n="Home page|button @@homePage.addChannelBtn">Подключить канал
    </button>
  </h3>

  <tg-inline-loader *ngIf="channels.loading && !channels.loaded; else channlsList"></tg-inline-loader>

  <ng-template #channlsList>
    <mat-card style="text-align: center" *ngIf="channels.count == 0">
      <h3 i18n="Home page|text @@homePage.noChannels">
        У вас пока нет подключенных каналов.
      </h3>
      <button mat-raised-button color="primary" (click)="openAddChannelForm()"
              i18n="Home page|button @@homePage.addChannelBtn">Подключить канал
      </button>
    </mat-card>

    <tg-channels-list *ngIf="channels.count > 0" [channels]="channels.items"></tg-channels-list>
  </ng-template>
</div>
<tg-footer></tg-footer>
