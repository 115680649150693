<mat-card>
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="end stretch">
      <button mat-icon-button matDialogClose tabindex="-1" matTooltip="закрыть"><mat-icon>close</mat-icon></button>
    </div>
    <div fxLayout="row">
      <div class="content" [class.loading]="!loaded"></div>
      <mat-spinner [diameter]="70" [strokeWidth]="2" style="margin: 20px auto;" *ngIf="!loaded"></mat-spinner>
    </div>

    <div fxLayout="column" style="margin-left: 46px">
      <label for="link">Ссылка на пост в Telegram:</label>
      <div fxLayout="row">
        <input #link id="link" class="custom-input" type="text" value="{{'https://t.me/' + data.channel.username + '/' + data.telegramId}}" readonly (click)="handleClickInput($event)"/>
        <div fxFlex fxFlexAlign="center">
          <button mat-stroked-button color="primary" class="copy-btn" matTooltip="скопировать ссылку" (click)="copyLink(link.value)">Copy</button>
        </div>
      </div>
    </div>

    <div fxLayout="column" style="margin-left: 46px; margin-top: 4px">
      <label for="share-link">Ссылка чтобы поделиться постом:</label>
      <div fxLayout="row">
        <input #shareLink id="share-link" class="custom-input" type="text" value="{{'https://t.me/share/url?url=https://t.me/' + data.channel.username + '/' + data.telegramId}}" readonly (click)="handleClickInput($event)"/>
        <div fxFlex fxFlexAlign="center">
          <button mat-stroked-button color="primary" class="copy-btn" matTooltip="скопировать ссылкой" (click)="copyLink(shareLink.value)">Copy</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
