/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CopyPostDto } from '../model/copyPostDto';
import { CreatePostV3Dto } from '../model/createPostV3Dto';
import { PostResponse } from '../model/postResponse';
import { PostsListResponse } from '../model/postsListResponse';
import { ReactionVotersInfoResponse } from '../model/reactionVotersInfoResponse';
import { SharePostDto } from '../model/sharePostDto';
import { ToQueueDto } from '../model/toQueueDto';
import { UpdateMessageDto } from '../model/updateMessageDto';
import { UpdatePostV3Dto } from '../model/updatePostV3Dto';
import { UploadToTelegraphResponse } from '../model/uploadToTelegraphResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PostsCrudService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create post
     * 
     * @param CreatePostV3Dto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(CreatePostV3Dto: CreatePostV3Dto, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public create(CreatePostV3Dto: CreatePostV3Dto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public create(CreatePostV3Dto: CreatePostV3Dto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public create(CreatePostV3Dto: CreatePostV3Dto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CreatePostV3Dto === null || CreatePostV3Dto === undefined) {
            throw new Error('Required parameter CreatePostV3Dto was null or undefined when calling create.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostResponse>(`${this.basePath}/posts`,
            CreatePostV3Dto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create post copy
     * 
     * @param CopyPostDto 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCopy(CopyPostDto: CopyPostDto, postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public createCopy(CopyPostDto: CopyPostDto, postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public createCopy(CopyPostDto: CopyPostDto, postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public createCopy(CopyPostDto: CopyPostDto, postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CopyPostDto === null || CopyPostDto === undefined) {
            throw new Error('Required parameter CopyPostDto was null or undefined when calling createCopy.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling createCopy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/copy`,
            CopyPostDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete post from queue
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deQueue(postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public deQueue(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public deQueue(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public deQueue(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling deQueue.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/de-queue`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete auto remove timer
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAutoRemoveTimer(postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public deleteAutoRemoveTimer(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public deleteAutoRemoveTimer(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public deleteAutoRemoveTimer(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling deleteAutoRemoveTimer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/delete-auto-remove-timer`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get post by id
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public getById(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public getById(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public getById(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get views
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHiddenTextClicks(postId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getHiddenTextClicks(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getHiddenTextClicks(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getHiddenTextClicks(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getHiddenTextClicks.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/hidden-text-clicks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get views
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLinksClicks(postId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getLinksClicks(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getLinksClicks(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getLinksClicks(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getLinksClicks.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/links-clicks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get reactions voters
     * 
     * @param messageId 
     * @param postId 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMessageVoters(messageId: string, postId: string, channelId?: string, observe?: 'body', reportProgress?: boolean): Observable<ReactionVotersInfoResponse>;
    public getMessageVoters(messageId: string, postId: string, channelId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReactionVotersInfoResponse>>;
    public getMessageVoters(messageId: string, postId: string, channelId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReactionVotersInfoResponse>>;
    public getMessageVoters(messageId: string, postId: string, channelId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (messageId === null || messageId === undefined) {
            throw new Error('Required parameter messageId was null or undefined when calling getMessageVoters.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getMessageVoters.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channelId !== undefined && channelId !== null) {
            queryParameters = queryParameters.set('channelId', <any>channelId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ReactionVotersInfoResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/voters/${encodeURIComponent(String(messageId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get views
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getViewsByHours(postId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getViewsByHours(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getViewsByHours(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getViewsByHours(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getViewsByHours.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/views-by-hour`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Posts list
     * 
     * @param channels 
     * @param state 
     * @param skip 
     * @param take 
     * @param order 
     * @param where Json encoded object
     * @param __search Search query for meiliseach
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(channels?: Array<string>, state?: string, skip?: number, take?: number, order?: string, where?: string, __search?: string, observe?: 'body', reportProgress?: boolean): Observable<PostsListResponse>;
    public list(channels?: Array<string>, state?: string, skip?: number, take?: number, order?: string, where?: string, __search?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostsListResponse>>;
    public list(channels?: Array<string>, state?: string, skip?: number, take?: number, order?: string, where?: string, __search?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostsListResponse>>;
    public list(channels?: Array<string>, state?: string, skip?: number, take?: number, order?: string, where?: string, __search?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (channels) {
            queryParameters = queryParameters.set('channels', channels.join(COLLECTION_FORMATS['csv']));
        }
        if (state !== undefined && state !== null) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (__search !== undefined && __search !== null) {
            queryParameters = queryParameters.set('__search', <any>__search);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PostsListResponse>(`${this.basePath}/posts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Preview post
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public preview(postId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public preview(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public preview(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public preview(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling preview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/preview`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Preview post
     * 
     * @param CreatePostV3Dto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public previewPostRaw(CreatePostV3Dto: CreatePostV3Dto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public previewPostRaw(CreatePostV3Dto: CreatePostV3Dto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public previewPostRaw(CreatePostV3Dto: CreatePostV3Dto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public previewPostRaw(CreatePostV3Dto: CreatePostV3Dto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CreatePostV3Dto === null || CreatePostV3Dto === undefined) {
            throw new Error('Required parameter CreatePostV3Dto was null or undefined when calling previewPostRaw.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/posts/preview`,
            CreatePostV3Dto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Publish post
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publish(postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public publish(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public publish(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public publish(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling publish.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/publish`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete post to trash
     * 
     * @param tgDelete 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public remove(tgDelete: string, postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public remove(tgDelete: string, postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public remove(tgDelete: string, postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public remove(tgDelete: string, postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tgDelete === null || tgDelete === undefined) {
            throw new Error('Required parameter tgDelete was null or undefined when calling remove.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling remove.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tgDelete !== undefined && tgDelete !== null) {
            queryParameters = queryParameters.set('tgDelete', <any>tgDelete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete post from Telegram
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removePostFromTelegram(postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public removePostFromTelegram(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public removePostFromTelegram(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public removePostFromTelegram(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling removePostFromTelegram.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/delete-from-tg`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restore post from trash
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restore(postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public restore(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public restore(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public restore(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling restore.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/restore`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Share post
     * 
     * @param SharePostDto 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public share(SharePostDto: SharePostDto, postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public share(SharePostDto: SharePostDto, postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public share(SharePostDto: SharePostDto, postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public share(SharePostDto: SharePostDto, postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SharePostDto === null || SharePostDto === undefined) {
            throw new Error('Required parameter SharePostDto was null or undefined when calling share.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling share.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/share`,
            SharePostDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send post to queue
     * 
     * @param ToQueueDto 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public toQueue(ToQueueDto: ToQueueDto, postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public toQueue(ToQueueDto: ToQueueDto, postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public toQueue(ToQueueDto: ToQueueDto, postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public toQueue(ToQueueDto: ToQueueDto, postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ToQueueDto === null || ToQueueDto === undefined) {
            throw new Error('Required parameter ToQueueDto was null or undefined when calling toQueue.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling toQueue.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/to-queue`,
            ToQueueDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update post
     * 
     * @param UpdatePostV3Dto 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(UpdatePostV3Dto: UpdatePostV3Dto, postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public update(UpdatePostV3Dto: UpdatePostV3Dto, postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public update(UpdatePostV3Dto: UpdatePostV3Dto, postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public update(UpdatePostV3Dto: UpdatePostV3Dto, postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (UpdatePostV3Dto === null || UpdatePostV3Dto === undefined) {
            throw new Error('Required parameter UpdatePostV3Dto was null or undefined when calling update.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling update.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<PostResponse>(`${this.basePath}/post/${encodeURIComponent(String(postId))}`,
            UpdatePostV3Dto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update publication
     * 
     * @param UpdateMessageDto 
     * @param messageId 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePublishedMessage(UpdateMessageDto: UpdateMessageDto, messageId: string, postId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePublishedMessage(UpdateMessageDto: UpdateMessageDto, messageId: string, postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePublishedMessage(UpdateMessageDto: UpdateMessageDto, messageId: string, postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePublishedMessage(UpdateMessageDto: UpdateMessageDto, messageId: string, postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (UpdateMessageDto === null || UpdateMessageDto === undefined) {
            throw new Error('Required parameter UpdateMessageDto was null or undefined when calling updatePublishedMessage.');
        }

        if (messageId === null || messageId === undefined) {
            throw new Error('Required parameter messageId was null or undefined when calling updatePublishedMessage.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling updatePublishedMessage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/post/${encodeURIComponent(String(postId))}/messages/${encodeURIComponent(String(messageId))}`,
            UpdateMessageDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update inline media
     * 
     * @param photo Photo file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadInlineMedia(photo?: Blob, observe?: 'body', reportProgress?: boolean): Observable<UploadToTelegraphResponse>;
    public uploadInlineMedia(photo?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadToTelegraphResponse>>;
    public uploadInlineMedia(photo?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadToTelegraphResponse>>;
    public uploadInlineMedia(photo?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (photo !== undefined) {
            formParams.append('photo', <any>photo);
        }

        return this.httpClient.post<UploadToTelegraphResponse>(`${this.basePath}/posts/upload-inline-media`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
