/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MessageTaskModel } from './messageTaskModel';


export interface Message { 
    id: string;
    type: Message.TypeEnum;
    mediaGroupType: Message.MediaGroupTypeEnum;
    notifications: boolean;
    enableComments: boolean;
    tasks?: MessageTaskModel;
}
export namespace Message {
    export type TypeEnum = 'text' | 'photo' | 'video' | 'videoNote' | 'animation' | 'voice' | 'audio' | 'document' | 'poll' | 'mediaGroup' | 'sticker';
    export const TypeEnum = {
        Text: 'text' as TypeEnum,
        Photo: 'photo' as TypeEnum,
        Video: 'video' as TypeEnum,
        VideoNote: 'videoNote' as TypeEnum,
        Animation: 'animation' as TypeEnum,
        Voice: 'voice' as TypeEnum,
        Audio: 'audio' as TypeEnum,
        Document: 'document' as TypeEnum,
        Poll: 'poll' as TypeEnum,
        MediaGroup: 'mediaGroup' as TypeEnum,
        Sticker: 'sticker' as TypeEnum
    };
    export type MediaGroupTypeEnum = 'visual' | 'audio' | 'docs';
    export const MediaGroupTypeEnum = {
        Visual: 'visual' as MediaGroupTypeEnum,
        Audio: 'audio' as MediaGroupTypeEnum,
        Docs: 'docs' as MediaGroupTypeEnum
    };
}
