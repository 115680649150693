import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PostResponse } from '../../../api';

@Component({
  selector: 'tg-post-view-container',
  templateUrl: './post-view-container.component.html',
  styleUrls: ['./post-view-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostViewContainerComponent implements OnInit {
  @Input()
  post: PostResponse;

  messagesWithError = [];

  constructor() {}

  ngOnInit() {
    if (this.post.publishErrors && this.post.publishErrors.length) {
      this.messagesWithError = this.post.publishErrors.map(e => e.messageId);
    }
  }

  hasError(msg: any) {
    return this.messagesWithError.includes(msg.id);
  }
}
