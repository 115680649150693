import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AuthModule } from '../auth/auth.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AppRouterService } from './app-router.service';
import { ApiModule, Configuration } from '../api';
import { environment } from '../../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NeedLoginInterceptor } from '../auth/need-login.interceptor';
import { CheckForUpdateService } from './check-for-update.service';
import { OfflineInterceptor } from '../lib/offline.interceptor';
import { ServerErrorInterceptor } from '../lib/server-error.interceptor';

export function apiFactory() {
  return new Configuration({
    basePath: environment.apiPath,
    apiKeys: {},
  });
}

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AuthModule,
    ApiModule.forRoot(apiFactory),
  ],
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NeedLoginInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    AppRouterService,
    CheckForUpdateService,
  ],
  exports: [HttpClientModule, LoadingBarHttpClientModule, LoadingBarRouterModule],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
