/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChannelCreateResponse } from '../model/channelCreateResponse';
import { ChannelResponse } from '../model/channelResponse';
import { ChannelsListResponse } from '../model/channelsListResponse';
import { CreateWithUsernameDto } from '../model/createWithUsernameDto';
import { SetBotDto } from '../model/setBotDto';
import { SetChatDescription } from '../model/setChatDescription';
import { SetChatTitle } from '../model/setChatTitle';
import { UpdateChannelDto } from '../model/updateChannelDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ChannelsService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create channel
     * 
     * @param CreateWithUsernameDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createChannel(CreateWithUsernameDto: CreateWithUsernameDto, observe?: 'body', reportProgress?: boolean): Observable<ChannelCreateResponse>;
    public createChannel(CreateWithUsernameDto: CreateWithUsernameDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelCreateResponse>>;
    public createChannel(CreateWithUsernameDto: CreateWithUsernameDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelCreateResponse>>;
    public createChannel(CreateWithUsernameDto: CreateWithUsernameDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CreateWithUsernameDto === null || CreateWithUsernameDto === undefined) {
            throw new Error('Required parameter CreateWithUsernameDto was null or undefined when calling createChannel.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ChannelCreateResponse>(`${this.basePath}/channels`,
            CreateWithUsernameDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete channel by id
     * 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteChannel(channelId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteChannel(channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteChannel(channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteChannel(channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling deleteChannel.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get channels where :adminId is editor
     * 
     * @param adminId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAdminChannels(adminId: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelsListResponse>;
    public getAdminChannels(adminId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelsListResponse>>;
    public getAdminChannels(adminId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelsListResponse>>;
    public getAdminChannels(adminId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (adminId === null || adminId === undefined) {
            throw new Error('Required parameter adminId was null or undefined when calling getAdminChannels.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChannelsListResponse>(`${this.basePath}/channels/admin/${encodeURIComponent(String(adminId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all user or bot channels
     * 
     * @param botId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBotChannels(botId: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelsListResponse>;
    public getBotChannels(botId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelsListResponse>>;
    public getBotChannels(botId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelsListResponse>>;
    public getBotChannels(botId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (botId === null || botId === undefined) {
            throw new Error('Required parameter botId was null or undefined when calling getBotChannels.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChannelsListResponse>(`${this.basePath}/channels/bot/${encodeURIComponent(String(botId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get channel by Id
     * 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelById(channelId: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelResponse>;
    public getChannelById(channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelResponse>>;
    public getChannelById(channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelResponse>>;
    public getChannelById(channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getChannelById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChannelResponse>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all user channels
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannels(observe?: 'body', reportProgress?: boolean): Observable<ChannelsListResponse>;
    public getChannels(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelsListResponse>>;
    public getChannels(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelsListResponse>>;
    public getChannels(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChannelsListResponse>(`${this.basePath}/channels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update channel description
     * 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChatInfo(channelId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getChatInfo(channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getChatInfo(channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getChatInfo(channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getChatInfo.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/tg/get-chat-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change channel bot
     * 
     * @param SetBotDto 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setChannelBot(SetBotDto: SetBotDto, channelId: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelResponse>;
    public setChannelBot(SetBotDto: SetBotDto, channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelResponse>>;
    public setChannelBot(SetBotDto: SetBotDto, channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelResponse>>;
    public setChannelBot(SetBotDto: SetBotDto, channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SetBotDto === null || SetBotDto === undefined) {
            throw new Error('Required parameter SetBotDto was null or undefined when calling setChannelBot.');
        }

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling setChannelBot.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<ChannelResponse>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/set-bot`,
            SetBotDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update channel description
     * 
     * @param SetChatDescription 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setChatDescription(SetChatDescription: SetChatDescription, channelId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setChatDescription(SetChatDescription: SetChatDescription, channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setChatDescription(SetChatDescription: SetChatDescription, channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setChatDescription(SetChatDescription: SetChatDescription, channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SetChatDescription === null || SetChatDescription === undefined) {
            throw new Error('Required parameter SetChatDescription was null or undefined when calling setChatDescription.');
        }

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling setChatDescription.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/tg/set-chat-description`,
            SetChatDescription,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update channel photo
     * 
     * @param channelId 
     * @param photo Photo file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setChatPhoto(channelId: string, photo?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setChatPhoto(channelId: string, photo?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setChatPhoto(channelId: string, photo?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setChatPhoto(channelId: string, photo?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling setChatPhoto.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (photo !== undefined) {
            formParams.append('photo', <any>photo);
        }

        return this.httpClient.post<any>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/tg/set-chat-photo`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update channel title
     * 
     * @param SetChatTitle 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setChatTitle(SetChatTitle: SetChatTitle, channelId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setChatTitle(SetChatTitle: SetChatTitle, channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setChatTitle(SetChatTitle: SetChatTitle, channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setChatTitle(SetChatTitle: SetChatTitle, channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SetChatTitle === null || SetChatTitle === undefined) {
            throw new Error('Required parameter SetChatTitle was null or undefined when calling setChatTitle.');
        }

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling setChatTitle.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<any>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/tg/set-chat-title`,
            SetChatTitle,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update channel
     * 
     * @param UpdateChannelDto 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(UpdateChannelDto: UpdateChannelDto, channelId: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelResponse>;
    public update(UpdateChannelDto: UpdateChannelDto, channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelResponse>>;
    public update(UpdateChannelDto: UpdateChannelDto, channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelResponse>>;
    public update(UpdateChannelDto: UpdateChannelDto, channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (UpdateChannelDto === null || UpdateChannelDto === undefined) {
            throw new Error('Required parameter UpdateChannelDto was null or undefined when calling update.');
        }

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling update.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<ChannelResponse>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}`,
            UpdateChannelDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
