import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ChannelResponse, ChannelsService, PostFormSettingsDto, RecentService, SetBotDto } from '../../api';
import { Injectable } from '@angular/core';

export class SelectChannel {
  static readonly type = '[SelectedChannelState] SelectChannel';

  constructor(public payload: any) {}
}

export class RemoveChannel {
  static readonly type = '[ChannelsState] RemoveChannel';

  constructor(public payload: string) {}
}

export class RemoveChannelSuccess {
  static readonly type = '[ChannelsState] RemoveChannelSuccess';

  constructor(public payload: string) {}
}

export class RemoveChannelFail {
  static readonly type = '[ChannelsState] RemoveChannelFail';

  constructor(public payload: HttpErrorResponse) {}
}

export class SetChannelBot {
  static readonly type = '[ChannelsState] SetChannelBot';

  constructor(public payload: SetBotDto) {}
}

export class SetChannelBotSuccess {
  static readonly type = '[ChannelsState] SetChannelBotSuccess';

  constructor(public payload: { data: any; msg: string }) {}
}

export class SetChannelBotFail {
  static readonly type = '[ChannelsState] SetChannelBotFail';

  constructor(public payload: HttpErrorResponse) {}
}

export class SetPostFormSettingsSuccess {
  static readonly type = '[ChannelsState] SetPostFormSettingsSuccess';

  constructor(public payload: PostFormSettingsDto) {}
}

export class SetPostFormSettingsFail {
  static readonly type = '[ChannelsState] SetPostFormSettingsFail';

  constructor(public payload: HttpErrorResponse) {}
}

export class SetVkCallbackFail {
  static readonly type = '[ChannelsState] SetVkCallbackFail';

  constructor(public payload: HttpErrorResponse) {}
}

export class GetTopEmoji {
  static readonly type = '[ChannelsState] GetTopEmoji';

  constructor() {}
}

export class SetTopEmoji {
  static readonly type = '[ChannelsState] SetTopEmoji';

  constructor(public payload: string[]) {}
}

export interface SelectedChannelStateModel {
  id?: string;
  data?: ChannelResponse;
  errorDeleteChannel: HttpErrorResponse;
  errorChaneBotChannel: HttpErrorResponse;
  errorPostFormSettings: HttpErrorResponse;
  loading: boolean;
  loaded: boolean;
  topEmoji: string[];
}

@State<SelectedChannelStateModel>({
  name: 'selectedChannel',
  defaults: {
    loading: false,
    loaded: false,
    errorDeleteChannel: null,
    errorChaneBotChannel: null,
    errorPostFormSettings: null,
    topEmoji: [],
  },
})
@Injectable()
export class SelectedChannelState {
  constructor(private api: ChannelsService, private recent: RecentService) {}

  @Selector()
  static id(state: SelectedChannelStateModel) {
    return state.id;
  }

  @Selector()
  static channel(state: SelectedChannelStateModel) {
    return state.data;
  }

  @Selector()
  static channelPostFormSettings(state: SelectedChannelStateModel) {
    return state.data.messageDefaultSettings;
  }

  @Selector()
  static selectedError(state: SelectedChannelStateModel) {
    return state.errorDeleteChannel;
  }

  @Selector()
  static chaneBotChannelError(state: SelectedChannelStateModel) {
    return state.errorChaneBotChannel;
  }

  @Selector()
  static postFormSettingsError(state: SelectedChannelStateModel) {
    return state.errorPostFormSettings;
  }

  @Selector()
  static topEmoji(state: SelectedChannelStateModel) {
    return state.topEmoji;
  }

  @Action(SelectChannel)
  select({ getState, setState }: StateContext<SelectedChannelStateModel>, { payload }: SelectChannel) {
    setState({
      ...getState(),
      id: payload.id,
      data: payload,
      errorDeleteChannel: null,
      errorChaneBotChannel: null,
      errorPostFormSettings: null,
    });
  }

  @Action(RemoveChannel)
  removeChannel({ getState, setState, dispatch }: StateContext<SelectedChannelStateModel>, { payload }: RemoveChannel) {
    setState({ ...getState(), loading: true });
    return this.api.deleteChannel(payload).pipe(
      tap(
        () =>
          dispatch(
            new RemoveChannelSuccess(
              $localize`:Selected channel state|notify @@selectedChannelState.channelRemovedSuccess:Канал удален`
            )
          ),
        error => dispatch(new RemoveChannelFail(error))
      ),
      finalize(() => setState({ ...getState(), loading: false }))
    );
  }

  @Action(RemoveChannelSuccess)
  removedChannel({ getState, setState }: StateContext<SelectedChannelStateModel>, { payload }: RemoveChannelSuccess) {
    setState({
      ...getState(),
      errorDeleteChannel: null,
      errorChaneBotChannel: null,
      errorPostFormSettings: null,
      loaded: true,
    });
  }

  @Action(RemoveChannelFail)
  failRemovedChannel({ getState, setState }: StateContext<SelectedChannelStateModel>, { payload }: RemoveChannelFail) {
    setState({
      ...getState(),
      errorDeleteChannel: payload,
      errorChaneBotChannel: null,
      errorPostFormSettings: null,
      loaded: true,
    });
  }

  @Action(SetChannelBot)
  setBotChannel({ getState, setState, dispatch }: StateContext<SelectedChannelStateModel>, { payload }: SetChannelBot) {
    const state = getState();
    setState({ ...getState(), loading: true });
    return this.api.setChannelBot(payload, state.id).pipe(
      tap(
        rs =>
          dispatch(
            new SetChannelBotSuccess({
              data: rs,
              msg: $localize`:Selected channel state|notify @@selectedChannelState.changeBotSuccess:Бот изменен`,
            })
          ),
        error => dispatch(new SetChannelBotFail(error))
      ),
      finalize(() => setState({ ...getState(), loading: false }))
    );
  }

  @Action(SetChannelBotSuccess)
  setBotChannelSuccess(
    { getState, setState }: StateContext<SelectedChannelStateModel>,
    { payload }: SetChannelBotSuccess
  ) {
    setState({
      ...getState(),
      data: payload.data,
      errorDeleteChannel: null,
      errorChaneBotChannel: null,
      errorPostFormSettings: null,
      loaded: true,
    });
  }

  @Action(SetChannelBotFail)
  failSetBotChannel({ getState, setState }: StateContext<SelectedChannelStateModel>, { payload }: SetChannelBotFail) {
    setState({
      ...getState(),
      errorDeleteChannel: null,
      errorChaneBotChannel: payload,
      errorPostFormSettings: null,
      loaded: true,
    });
  }

  // @Action(SetPostFormSettings)
  // setPostFormSettings(
  //   { getState, setState, dispatch }: StateContext<SelectedChannelStateModel>,
  //   { payload }: SetPostFormSettings
  // ) {
  //   const state = getState();
  //   setState({ ...getState(), loading: true });
  //   return this.api.setPostFormSettings(payload, state.id).pipe(
  //     tap(
  //       rs => dispatch(new SetPostFormSettingsSuccess(rs)),
  //       error => dispatch(new SetPostFormSettingsFail(error))
  //     ),
  //     finalize(() => setState({ ...getState(), loading: false }))
  //   );
  // }

  // @Action(SetPostFormSettingsSuccess)
  // setPostFormSettingsSuccess(
  //   { getState, setState }: StateContext<SelectedChannelStateModel>,
  //   { payload }: SetPostFormSettingsSuccess
  // ) {
  //   const state = getState();
  //   state.data['postFormSettings'] = payload;
  //   setState({
  //     ...getState(),
  //     data: state.data,
  //     errorDeleteChannel: null,
  //     errorChaneBotChannel: null,
  //     errorPostFormSettings: null,
  //     loaded: true,
  //   });
  // }

  // @Action(SetPostFormSettingsFail)
  // setPostFormSettingsFail(
  //   { getState, setState }: StateContext<SelectedChannelStateModel>,
  //   { payload }: SetPostFormSettingsFail
  // ) {
  //   setState({
  //     ...getState(),
  //     errorDeleteChannel: null,
  //     errorChaneBotChannel: null,
  //     errorPostFormSettings: payload,
  //     loaded: true,
  //   });
  // }

  @Action(GetTopEmoji)
  getTopEmoji(ctx: StateContext<SelectedChannelStateModel>) {
    const state = ctx.getState();
    return this.recent.emoji(state.id).pipe(
      tap(rs => {
        ctx.patchState({ topEmoji: rs });
      })
    );
  }

  @Action(SetTopEmoji)
  setTopEmoji(ctx: StateContext<SelectedChannelStateModel>, { payload }: SetTopEmoji) {
    ctx.patchState({ topEmoji: payload });
  }
}
