import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { DialogCreatesService } from '../../../shared/dialog-creates.service';
import {
  LoadPosts,
  PreviewPost,
  QueuePost,
  RemovePost,
  RemovePostFromQueue,
  RemovePostFromTelegram,
  RestorePost,
  SendPostNow,
} from '../../../channel/state/posts-list.state';
import { SelectedChannelState } from '../../../channel/state/selected-channel.state';
import { DialogOpenService } from '../../../channel/components/dialog-open.service';
import { MediaObserver } from '@angular/flex-layout';
import { MessageType } from '../../post-type';
import { OpenBottomSheetService } from '../../../components/custom-bottom-sheet/open-bottom-sheet.service';
import { TelegramPostDialogComponent } from '../telegram-post-dialog/telegram-post-dialog.component';
import { PostCopyDialogComponent } from '../post-copy-dialog/post-copy-dialog.component';
import { SharePostDialogComponent } from '../share-post-dialog/share-post-dialog.component';
import { TimeDiffPipe } from '../../../pipe/time-diff.pipe';
import { FullScreenDialogService } from '../../../shared/full-screen-dialog.service';
import { MessageResponse, PostResponse, PostsCrudService } from '../../../api';
import { DateTime } from 'luxon';
import { VotersDialogComponent } from '../voters-dialog/voters-dialog.component';
import { FORCE_VIEW_CHANNEL } from '../../../providers';
import { PostViewsDialogComponent } from '../post-views-dialog/post-views-dialog.component';
import { LinksClicksDialogComponent } from '../links-clicks-dialog/links-clicks-dialog.component';
import { HiddenTextClicksDialogComponent } from '../hidden-text-clicks/hidden-text-clicks-dialog.component';
import { UserState } from '../../../auth/user.state';

@Component({
  selector: 'tg-post-item',
  templateUrl: './post-item.component.html',
  styleUrls: ['./post-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostItemComponent implements OnInit, AfterViewInit {
  @Input()
  post: PostResponse;
  @Input()
  showNavigation = true;
  @Output()
  updatePosts = new Subject();
  @Output()
  edit = new Subject();

  @Output()
  editMsg = new Subject<{ msg: MessageResponse; post: PostResponse }>();

  type = MessageType;

  collapseText = true;

  @ViewChild('textItem')
  textItem: ElementRef;

  @ViewChild('selectEditMsg', { static: true })
  selectEditMsg: TemplateRef<any>;
  selectDialogRef: MatDialogRef<any>;

  selectMsgFilter = msgs => msgs;
  selectMsg: any = () => null;

  canCopyPost = true;
  canSharePost = true;

  selectedChannelId = this.store.selectSnapshot(SelectedChannelState.id);
  ___hiddenTextStatsFeature = this.store.selectSnapshot(UserState.feature('hidden_text_stats'));

  constructor(
    private cd: ChangeDetectorRef,
    private dos: DialogOpenService,
    private dcs: DialogCreatesService,
    private store: Store,
    private media: MediaObserver,
    private bottomSheet: OpenBottomSheetService,
    private dialog: MatDialog,
    private apiPosts: PostsCrudService,
    private fullScreenDialog: FullScreenDialogService,
    @Optional() @Inject(FORCE_VIEW_CHANNEL) public forceViewChannel = false
  ) {}

  ngOnInit() {
    if (this.post.source === 'bot') {
      let notText = this.post.messages.find(m => m.type !== 'text');
      if (notText) {
        this.canCopyPost = !this.hasBigFiles();
      }
    }
  }

  get publishError() {
    if (this.post.publishErrors && this.post.publishErrors.length) {
      return this.post.publishErrors.map(e => `Канал: ${e.channelDescribe} -> ${e.message}`);
    }

    return false;
  }

  showViewsByHours(channelID: any) {
    this.fullScreenDialog.open(PostViewsDialogComponent, {
      data: {
        postId: this.post.id,
        channelId: channelID,
      },
      autoFocus: false,
      restoreFocus: false,
      width: '600px',
    });
  }

  showClicksByLinks(channelId: string) {
    this.fullScreenDialog.open(LinksClicksDialogComponent, {
      data: {
        postId: this.post.id,
        channelId: channelId,
      },
      autoFocus: false,
      restoreFocus: false,
      width: '600px',
    });
  }

  showHiddenTextClicks(channelId: string) {
    this.fullScreenDialog.open(HiddenTextClicksDialogComponent, {
      data: {
        postId: this.post.id,
        channelId: channelId,
        buttons: this.post.messages.reduce((obj, curr) => {
          for (let row of curr.keyboard || []) {
            if (row.hiddenTextButtons && row.hiddenTextButtons.length) {
              for (const [index, btn] of row.hiddenTextButtons.entries()) {
                obj[`${row.rowId}/${index}`] = btn.buttonText;
              }
            }
          }
          return obj;
        }, {}),
      },
      autoFocus: false,
      restoreFocus: false,
      width: '600px',
    });
  }

  hasBigFiles() {
    let maxSize = 20 * 1024 * 1024;
    for (let msg of this.post.messages) {
      if (msg.type === 'text' || msg.type === 'poll') {
        continue;
      }
      if (msg.type === 'mediaGroup') {
        for (let media of msg.files) {
          if (media.size > maxSize) {
            return true;
          }
        }
        continue;
      }
      if (msg.file.size > maxSize) {
        return true;
      }
    }
    return false;
  }

  ngAfterViewInit() {}

  copyPost() {
    // create dialog
    this.fullScreenDialog.open(PostCopyDialogComponent, {
      data: { postId: this.post.id },
      autoFocus: true,
      restoreFocus: true,
      minWidth: '350px',
    });
  }

  sharePost() {
    // create dialog
    this.dialog.open(SharePostDialogComponent, {
      data: { post: this.post },
      autoFocus: true,
      restoreFocus: true,
      maxWidth: '500px',
      minWidth: '350px',
    });
  }

  editPost() {
    if (this.post.state === 'sent') {
      return this.editMultiPublished();
    }
    this.edit.next(this.post);
  }

  editMultiPublished() {
    if (this.post.messages.length === 1) {
      return this.editMsg.next({ msg: this.post.messages[0], post: this.post });
    }
    this.selectMsgFilter = msgs => msgs;
    this.selectDialogRef = this.fullScreenDialog.open(this.selectEditMsg, {
      autoFocus: false,
      restoreFocus: false,
    });
    this.selectMsg = msg => {
      this.selectDialogRef.close();
      this.editMsg.next({ msg, post: this.post });
    };
  }

  schedule() {
    this.dos
      .openDialogSchedule({ scheduleAt: this.post.scheduleAt ? DateTime.fromISO(this.post.scheduleAt) : null })
      .afterClosed()
      .pipe(
        filter(rs => rs),
        switchMap(rs => this.store.dispatch(new QueuePost({ publish: { scheduleAt: rs }, postId: this.post.id })))
      )
      .subscribe();
  }

  previewPostFromTelegram() {
    const channel = this.store.selectSnapshot(SelectedChannelState.channel);
    this.dialog
      .open(TelegramPostDialogComponent, {
        data: {
          channel: channel,
          telegramId: this.post['publishedId'],
        },
      })
      .afterClosed()
      .subscribe();
  }

  trashPost() {
    const dialogMsg = $localize`:Post item|title @@postItem.trashDialog.title:Вы действительно хотите переместить пост в корзину?`;

    this.dcs
      .openAccessDialog(
        dialogMsg,
        'prompt',
        { name: $localize`:Post item|button @@postItem.trashDialog.bnt:В корзину`, typeBtn: 'error' },
        this.post
      )
      .afterClosed()
      .pipe(
        filter(rs => rs),
        switchMap(params =>
          this.store.dispatch(new RemovePost({ tgDelete: params.removeTelegram, postId: this.post.id }))
        )
      )
      .subscribe();
  }

  restorePost() {
    const dialogMsg = $localize`:Post item|title @@postItem.restoreDialog.title:Вы действительно хотите восстановить пост с корзины?`;

    this.dcs
      .openAccessDialog(
        dialogMsg,
        'confirm',
        { name: $localize`:Post item|button @@postItem.restoreDialog.btn:Восстановить`, typeBtn: 'access' },
        this.post
      )
      .afterClosed()
      .pipe(
        filter(rs => rs),
        switchMap(params => this.store.dispatch(new RestorePost({ tgDelete: null, postId: this.post.id })))
      )
      .subscribe();
  }

  trashPostWithTelegram() {
    if (!this.canDeleteFromTg) {
      return alert(
        $localize`:Post item|text @@postItem.trashWithTelegram.error:Невозможно удалить. Пост опубликован больше 48 часов назад.`
      );
    }
    const dialogMsg = $localize`:Post item|title @@postItem.trashWithTelegram.title:Вы действительно хотите удалить пост из канала в Telegram?`;
    const name = this.media.isActive('xs')
      ? $localize`:Post item|button @@postItem.trashWithTelegram.button:Удалить`
      : $localize`:Post item|button @@postItem.trashWithTelegram.button1:Удалить из Telegram`;

    this.dcs
      .openAccessDialog(dialogMsg, 'confirm', { name: name, typeBtn: 'error' })
      .afterClosed()
      .pipe(
        filter(rs => rs),
        switchMap(() => this.store.dispatch(new RemovePostFromTelegram(this.post.id)))
      )
      .subscribe();
  }

  removeQueue() {
    const dialogMsg = $localize`:Post item|title @@postItem.dequeue.title:Вы действительно хотите снять пост с очереди?`;

    this.dcs
      .openAccessDialog(dialogMsg, 'confirm', {
        name: $localize`:Post item|button @@postItem.dequeue.button:Снять с очереди`,
        typeBtn: 'info',
      })
      .afterClosed()
      .pipe(
        filter(rs => rs),
        switchMap(() => this.store.dispatch(new RemovePostFromQueue(this.post.id)))
      )
      .subscribe();
  }

  previewPost() {
    this.store.dispatch(new PreviewPost(this.post.id));
  }

  sendNow() {
    let dialogSpinnerRef: MatDialogRef<any>;
    const dialogMsg = $localize`:Post item|title @@postItem.sendNow.title:Вы действительно хотите опубликовать пост сейчас?`;
    const name = this.media.isActive('xs')
      ? $localize`:Post item|button @@postItem.sendNow.button:опубликовать`
      : $localize`:Post item|button @@postItem.sendNow.button1:опубликовать сейчас`;

    this.dcs
      .openAccessDialog(dialogMsg, 'confirm', { name: name, typeBtn: 'info' })
      .afterClosed()
      .pipe(
        filter(rs => rs),
        tap(() => (dialogSpinnerRef = this.dcs.openAccessDialog('', 'spinner'))),
        switchMap(() => this.store.dispatch(new SendPostNow(this.post.id))),
        finalize(() => {
          if (dialogSpinnerRef) {
            dialogSpinnerRef.close();
          }
        })
      )
      .subscribe();
  }

  msgHasReactions(msg: MessageResponse) {
    return msg.keyboard && msg.keyboard.some(r => r.type === 'reaction');
  }

  get canShowVoters() {
    let msgWithReactions = this.post.messages.reduce((sum, msg) => sum + (this.msgHasReactions(msg) ? 1 : 0), 0);
    return msgWithReactions >= 1;
  }

  get canShowPollVoters() {
    let msgWithReactions = this.post.messages.reduce((sum, msg) => sum + (msg.type === MessageType.Poll ? 1 : 0), 0);
    return msgWithReactions >= 1;
  }

  /**
   * A message can only be deleted if it was sent less than 48 hours ago.
   */
  get canDeleteFromTg() {
    if (!this.post.publishedAt) {
      return false;
    }
    let publishedAt = new Date(this.post.publishedAt);
    return Date.now() - publishedAt.getTime() < 48 * 24 * 3600 * 100;
  }

  convertSecondsToTime(seconds) {
    return TimeDiffPipe.prototype.transform(seconds);
  }

  openBottomSheet() {
    const data = {
      draft: [
        {
          button: $localize`:Post item|button @@postItem.actions.botPreview:Предпросмотр в бот`,
          act: 'previewPost',
          icon: 'visibility',
        },
        {
          button: $localize`:Post item|button @@postItem.actions.copy:Создать копию`,
          act: 'copyPost',
          icon: 'content_copy',
          hidden: !this.canCopyPost,
        },
        {
          button: $localize`:Post item|button @@postItem.actions.share:Доступ по ссылке`,
          act: 'sharePost',
          icon: 'link',
          hidden: !this.canSharePost,
        },
        {
          button: $localize`:Post item|button @@postItem.actions.sendNow:Опубликовать сейчас`,
          act: 'sendNow',
          icon: 'send',
        },
        {
          button: $localize`:Post item|button @@postItem.actions.trash:В корзину`,
          act: 'trashPost',
          icon: 'delete',
          color: '#f44336',
        },
      ],
      queue: [
        { button: $localize`:Post item|button @@postItem.actions.edit:Редактировать`, act: 'editPost', icon: 'edit' },
        {
          button: $localize`:Post item|button @@postItem.actions.sendNow:Опубликовать сейчас`,
          act: 'sendNow',
          icon: 'send',
        },
        {
          button: $localize`:Post item|button @@postItem.actions.copy:Создать копию`,
          act: 'copyPost',
          icon: 'content_copy',
          hidden: !this.canCopyPost,
        },
        {
          button: $localize`:Post item|button @@postItem.actions.share:Доступ по ссылке`,
          act: 'sharePost',
          icon: 'link',
          hidden: !this.canSharePost,
        },
        {
          button: $localize`:Post item|button @@postItem.actions.botPreview:Предпросмотр в бот`,
          act: 'previewPost',
          icon: 'visibility',
        },
        {
          button: $localize`:Post item|button @@postItem.actions.dequeue:Снять с очереди`,
          act: 'removeQueue',
          icon: 'undo',
        },
        {
          button: $localize`:Post item|button @@postItem.actions.trash:В корзину`,
          act: 'trashPost',
          icon: 'delete',
          color: '#f44336',
        },
      ],
      sent: [
        {
          button: $localize`:Post item|button @@postItem.actions.copy:Создать копию`,
          act: 'copyPost',
          icon: 'content_copy',
          hidden: !this.canCopyPost,
        },
        {
          button: $localize`:Post item|button @@postItem.actions.share:Доступ по ссылке`,
          act: 'sharePost',
          icon: 'link',
          hidden: !this.canSharePost,
        },
        {
          button: $localize`:Post item|button @@postItem.actions.reactionsStats:Статистика реакций`,
          act: 'showVoters',
          icon: 'thumbs_up_down',
          hidden: !this.canShowVoters,
        },
        {
          button: $localize`:Post item|button @@postItem.actions.pollStats:Статистика опроса`,
          act: 'showPollVoters',
          icon: 'bar_chart',
          hidden: !this.canShowPollVoters,
        },
        {
          button: $localize`:Post item|button @@postItem.actions.trash:В корзину`,
          act: 'trashPost',
          icon: 'delete',
          color: '#f44336',
        },
        {
          button: $localize`:Post item|button @@postItem.actions.deleteFromTelegram:Удалить из Telegram`,
          act: 'trashPostWithTelegram',
          icon: 'delete',
          color: '#f44336',
        },
        {
          button: $localize`:Post item|button @@postItem.actions.cancelAutodelete:Отменить таймер автоудаления`,
          act: 'deleteAutoRemoveTimer',
          icon: 'delete_sweep',
          hidden: !this.post.deleteAfter,
        },
      ],
    };

    this.bottomSheet
      .openBottomSheet(data[this.post.state].filter(b => !b.hidden))
      .afterDismissed()
      .pipe(filter(rs => rs))
      .subscribe(res => this.selectAction(res));
  }

  showPollVoters() {
    let msgPoll = this.post.messages.reduce((sum, msg) => sum + (msg.type === 'poll' ? 1 : 0), 0);
    if (msgPoll === 1) {
      let msg = this.post.messages.find(msg => msg.type === 'poll');
      return this.showMessagePollVoters(msg.id);
    }
    return this.selectMsgForPollVotersList();
  }

  selectMsgForPollVotersList() {
    this.selectMsgFilter = (msgs: MessageResponse[]) => msgs.filter(m => m.type === 'poll');
    this.selectDialogRef = this.fullScreenDialog.open(this.selectEditMsg, {
      autoFocus: false,
      restoreFocus: false,
    });
    this.selectMsg = msg => {
      this.selectDialogRef.close();
      this.showMessagePollVoters(msg.id);
    };
  }

  showMessageReactionsVoters(msgId: string) {
    this.fullScreenDialog.open(VotersDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: { msgId, postId: this.post.id, type: 'reactions' },
    });
  }

  showMessagePollVoters(msgId: string) {
    this.fullScreenDialog.open(VotersDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: { msgId, postId: this.post.id, type: 'poll' },
    });
  }

  showVoters() {
    let msgWithReactions = this.post.messages.reduce((sum, msg) => sum + (this.msgHasReactions(msg) ? 1 : 0), 0);
    if (msgWithReactions === 1) {
      let msg = this.post.messages.find(msg => this.msgHasReactions(msg));
      return this.showMessageReactionsVoters(msg.id);
    }
    return this.selectMsgForReactionsList();
  }

  selectMsgForReactionsList() {
    this.selectMsgFilter = (msgs: MessageResponse[]) => msgs.filter(m => this.msgHasReactions(m));
    this.selectDialogRef = this.fullScreenDialog.open(this.selectEditMsg, {
      autoFocus: false,
      restoreFocus: false,
    });
    this.selectMsg = msg => {
      this.selectDialogRef.close();
      this.showMessageReactionsVoters(msg.id);
    };
  }

  deleteAutoRemoveTimer() {
    this.apiPosts.deleteAutoRemoveTimer(this.post.id).subscribe(rs => this.store.dispatch(new LoadPosts()));
  }

  selectAction(action) {
    if (action === 'sendNow') {
      this.sendNow();
    } else if (action === 'editPost') {
      this.editPost();
    } else if (action === 'copyPost') {
      this.copyPost();
    } else if (action === 'sharePost') {
      this.sharePost();
    } else if (action === 'previewPost') {
      this.previewPost();
    } else if (action === 'removeQueue') {
      this.removeQueue();
    } else if (action === 'trashPost') {
      this.trashPost();
    } else if (action === 'trashPostWithTelegram') {
      this.trashPostWithTelegram();
    } else if (action === 'deleteAutoRemoveTimer') {
      this.deleteAutoRemoveTimer();
    } else if (action === 'showVoters') {
      this.showVoters();
    } else if (action === 'showPollVoters') {
      this.showPollVoters();
    }
  }

  get deletedTooltip() {
    return this.post.tgDeleted
      ? $localize`:Post item|tooltip @@postItem.deletedTooltip.withTelega:Пост удалён из Telegram `
      : this.post['autoDeleteAt']
      ? $localize`:Post item|tooltip @@postItem.deletedTooltip.willBeDeletedTelega:Пост будет удалён из Telegram `
      : $localize`:Post item|tooltip @@postItem.deletedTooltip.willBeDeleted:Удалить после публикации через: ` + this.convertSecondsToTime(this.post.deleteAfter);
  }
}
