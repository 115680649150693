import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ScheduleDialogData } from '../../lib/types';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-native-date-time-piker',
  templateUrl: './native-date-time-piker.component.html',
  styleUrls: ['./native-date-time-piker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativeDateTimePikerComponent implements OnInit, AfterViewInit {
  @ViewChild('inputElement') private inputElement: ElementRef<HTMLInputElement>;

  minDate = DateTime.local();
  // max date is now +3 months
  maxDate = DateTime.local().plus({ months: 3 });

  _scheduleForm: FormGroup;

  selectedDate: DateTime;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ScheduleDialogData,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<NativeDateTimePikerComponent>
  ) {}

  ngOnInit() {
    this.selectedDate = DateTime.local().plus({ minutes: 5 });
    if (this.data.scheduleAt && DateTime.isDateTime(this.data.scheduleAt)) {
      this.selectedDate = this.data.scheduleAt;
    }
    this._scheduleForm = new FormGroup(
      {
        datetime: new FormControl(this.selectedDate),
      },
      (control: AbstractControl) => {
        return this.validateDateAndTime(control);
      }
    );
  }

  ngAfterViewInit(): void {
    this.inputElement.nativeElement.value = this.selectedDate.toFormat("yyyy-MM-dd'T'HH:mm");
    this.inputElement.nativeElement.focus();
  }

  validateDateAndTime(control: AbstractControl) {
    if (!this._scheduleForm) {
      return null;
    }
    let selected = this._scheduleForm.value.datetime;
    if (selected === null) {
      return null;
    }
    if (this.minDate > selected) {
      return { lessNow: true };
    }
    if (this.maxDate < selected) {
      return { getMax: true };
    }
    return null;
  }

  selectDate(e) {
    let value = this.inputElement.nativeElement.value;
    console.log({ value });
    if (!value) {
      this._scheduleForm.setValue({ datetime: null });
      return;
    }
    let dt = DateTime.fromISO(value);
    this._scheduleForm.setValue({ datetime: dt });
    // this.cd.detectChanges();
  }

  saveResult() {
    this.dialogRef.close(this._scheduleForm.value.datetime);
  }
}
