<mat-nav-list>
  <ng-container *ngFor="let action of data">
    <a mat-list-item [style.color]="action.color" [class.disabled]="action.disabled"
       *ngIf="action.visible"
        (click)="handleClick({event: $event, action: action.act})">
    <span mat-line>
      <mat-icon style="vertical-align: middle;">{{action.icon}}</mat-icon>
      {{ action.button }}
    </span>
    </a>
  </ng-container>
</mat-nav-list>
