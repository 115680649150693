<div class="error-box" *ngIf="errorMessage" [class.withoutIcon]="!showIcon">
  <div class="error-box-body">
    <mat-icon class="status-icon" *ngIf="showIcon">warning</mat-icon>
    <span class="error-box-message">{{errorMessage}}</span>
    <span class="error-box-details" *ngIf="errorDetails">{{errorDetails}}</span>
  </div>
  <button *ngIf="closebtn" class="close-btn" mat-icon-button tabindex="-1" (click)="removeError()">
    <mat-icon>close</mat-icon>
  </button>
</div>
