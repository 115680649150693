import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeAgoPipe } from './time-ago.pipe';
import { LinkInTextPipe } from './link-in-text.pipe';
import { EmojiInTextPipe } from './emoji-in-text.pipe';
import { HashtagInTextPipe } from './hashtag-in-text.pipe';
import { UserNameInTextPipe } from './user-name-in-text.pipe';
import { MarkdownPipe } from './markdown.pipe';
import { ImageProxyPipe } from './image-proxy.pipe';
import { DateTimePipe } from './date-time.pipe';
import { TimeDiffPipe } from './time-diff.pipe';
import { QuillRenderPipe } from './quill-render.pipe';
import { BytesToSizePipe } from './bytes-to-size.pipe';
import { SearchPipe } from './search.pipe';
import { DurationPipe } from './duration.pipe';
import { BotCdnPipe } from './bot-cdn.pipe';
import { NumberThousandsFormatPipe } from './number-thousands-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TimeAgoPipe,
    LinkInTextPipe,
    EmojiInTextPipe,
    HashtagInTextPipe,
    UserNameInTextPipe,
    MarkdownPipe,
    ImageProxyPipe,
    DateTimePipe,
    TimeDiffPipe,
    QuillRenderPipe,
    BytesToSizePipe,
    SearchPipe,
    DurationPipe,
    BotCdnPipe,
    NumberThousandsFormatPipe,
  ],
  exports: [
    TimeAgoPipe,
    LinkInTextPipe,
    EmojiInTextPipe,
    HashtagInTextPipe,
    UserNameInTextPipe,
    MarkdownPipe,
    ImageProxyPipe,
    DateTimePipe,
    TimeDiffPipe,
    QuillRenderPipe,
    BytesToSizePipe,
    SearchPipe,
    DurationPipe,
    BotCdnPipe,
    NumberThousandsFormatPipe,
  ],
})
export class PipeModule {}
