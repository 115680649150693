import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageProxy',
  pure: true,
})
export class ImageProxyPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value.match(/telegra\.ph/)) {
      return value.replace('http://telegra.ph/', '/api/images/proxy/');
    }
    return value;
  }
}
