<div class="dialog-head">
  <h2 mat-dialog-title i18n="Hidden text preview dialog|title @@hiddenTextDialog.title">Скрытый текст</h2>
  <button class="close-btn" mat-icon-button tabindex="-1" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <h4 i18n="Hidden text preview dialog|label @@hiddenTextDialog.notMemberLabel">Если пользователь НЕ подписан</h4>
  <p>{{data.notMemberText}}</p>
  <h4 i18n="Hidden text preview dialog|label @@hiddenTextDialog.memberLabel">Если пользователь подписан</h4>
  <p>{{data.memberText}}</p>
</mat-dialog-content>
