import { Injectable } from '@angular/core';
import * as tus from 'tus-js-client';
import { environment } from '../../environments/environment';
import { Observable, Subscriber } from 'rxjs';
import { AuthHelper } from '../auth/auth.helper';

export class UploadProgress {
  constructor(public bytesUploaded: number, public bytesTotal) {}

  get percentageInt() {
    return (this.bytesUploaded / this.bytesTotal) * 100;
  }

  get percentage() {
    return ((this.bytesUploaded / this.bytesTotal) * 100).toFixed(2);
  }
}

export class UploadSuccess {
  constructor(private upload: tus.Upload) {}

  get url() {
    return this.upload.url as string;
  }

  get file() {
    return this.upload.file;
  }
}

@Injectable()
export class UploadsService {
  create(file: File | Blob, metadata: { [key: string]: string } = {}): Observable<UploadSuccess | UploadProgress> {
    return new Observable((observer: Subscriber<any>) => {
      const upload = new tus.Upload(file, {
        endpoint: environment.apiPath + '/uploads-v2',
        retryDelays: [0, 1000, 3000, 5000],
        removeFingerprintOnSuccess: true,
        resume: true,
        chunkSize: 1024 * 1024, // 1M
        metadata: {
          filename: file instanceof File ? file.name : 'blob.' + file.type.split('/').pop(),
          contentType: file.type,
          ...metadata,
        },
        headers: {
          'ngsw-bypass': '1',
          Authorization: AuthHelper.getAuthorizationHeader(),
        },
        onError: function(error) {
          console.log('Failed because: ' + error);
          observer.error(error);
        },
        onProgress: function(bytesUploaded, bytesTotal) {
          const progress = (bytesUploaded / bytesTotal) * 100;
          const percentage = progress.toFixed(2);
          if (Number(progress.toFixed(0)) % 5 === 0) {
            console.log(bytesUploaded, bytesTotal, percentage + '%');
          }
          observer.next(new UploadProgress(bytesUploaded, bytesTotal));
        },
        onSuccess: function() {
          console.log(`UploadSuccess ${upload.file['name']} url ${upload.url}`);
          observer.next(new UploadSuccess(upload));
          observer.complete();
        },
      } as tus.UploadOptions);

      // Start the upload
      upload.start();

      return () => upload.abort();
    });
  }
}
