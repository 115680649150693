import jwt_decode from 'jwt-decode';
import { UserStateModel } from './user.state.model';

export interface StoreData {
  access_token: string;
  expires_at: number;
}

export enum StoreFields {
  AccessToken = 'tp_access_token',
  ExpiresAt = 'tp_expires_at',
}

export class AuthHelper {
  static getState() {
    return AuthHelper.createStateObject(AuthHelper.loadData());
  }

  static createStateObject(data: StoreData): UserStateModel {
    return {
      user: AuthHelper.decodeJwtToken(data.access_token),
      access_token: data.access_token,
      expires_at: data.expires_at,
      isAuth: AuthHelper.notExpired(data.expires_at),
    };
  }

  static decodeJwtToken(token) {
    if (!token || token === 'undefined') {
      return null;
    }
    return jwt_decode(token);
  }

  static notExpired(expiresAt: number) {
    return new Date().getTime() < expiresAt;
  }

  static resetStoreData() {
    localStorage.removeItem(StoreFields.AccessToken);
    localStorage.removeItem(StoreFields.ExpiresAt);
  }

  static loadData(): StoreData {
    const expires = localStorage.getItem(StoreFields.ExpiresAt);
    return {
      access_token: localStorage.getItem(StoreFields.AccessToken),
      expires_at: expires ? Number(expires) : 0,
    };
  }

  static saveAuthRes(authRes: any) {
    if (!authRes.access_token) {
      return;
    }
    localStorage.setItem(StoreFields.AccessToken, authRes.access_token);
    localStorage.setItem(
      StoreFields.ExpiresAt,
      String(authRes.expires_in ? new Date().getTime() + authRes.expires_in * 1000 : authRes['expires_at'])
    );
  }

  static getAuthorizationHeader() {
    return 'Bearer ' + localStorage.getItem(StoreFields.AccessToken);
  }
}
