<h2 mat-dialog-title i18n="Share post dialog|title @@sharePostDialog.title">Открыть доступ к посту по ссылке</h2>
<mat-dialog-content>
  <tg-inline-loader *ngIf="loading"></tg-inline-loader>
  <p *ngIf="!loading && !data.post.shareCode" i18n="Share post dialog|desc @@sharePostDialog.desc">
    По ссылке будет доступна возможность просматривать пост и делать его копию.
  </p>
  <div *ngIf="data.post.shareCode">
    <div fxLayout="column" style="min-width: 300px">
      <label for="link" i18n="Share post dialog|label @@sharePostDialog.linkLabel">Ссылка на пост:</label>
      <div fxLayout="row">
        <input
          #link
          id="link"
          class="custom-input"
          type="text"
          value="{{ 'https://app.telepost.me/sp/' + data.post.id + '/' + data.post.shareCode }}"
          readonly
          (click)="handleClickInput($event)"
        />
        <div fxFlex fxFlexAlign="center">
          <button
            mat-icon-button
            color="primary"
            class="copy-btn"
            matTooltip="скопировать ссылку"
            i18n-matTooltip="Share post dialog|button @@sharePostDialog.copyLinkButton"
            (click)="copyLink(link.value)"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close i18n="Share post dialog|button @@sharePostDialog.cancelBtn">Отмена</button>
  <button style="margin-left: 16px" mat-flat-button color="primary" (click)="share(true)" [disabled]="loading"
          *ngIf="!data.post.shareCode">
    <mat-icon>link</mat-icon>
    <ng-container i18n="Share post dialog|button @@sharePostDialog.shareBtn">Открыть доступ</ng-container>
  </button>
  <button style="margin-left: 16px" mat-flat-button color="primary" (click)="share(false)" [disabled]="loading"
          *ngIf="data.post.shareCode">
    <mat-icon>link_off</mat-icon>
    <ng-container i18n="Share post dialog|button @@sharePostDialog.unshareBtn">Закрыть доступ</ng-container>
  </button>
</mat-dialog-actions>
