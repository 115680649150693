/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MessageDefaultSettings } from './messageDefaultSettings';
import { UserShortInfoResponse } from './userShortInfoResponse';


export interface ChannelParticipantFull { 
    createdAt: string;
    creator: UserShortInfoResponse;
    user: UserShortInfoResponse;
    role: ChannelParticipantFull.RoleEnum;
    messageDefaultSettings: MessageDefaultSettings;
}
export namespace ChannelParticipantFull {
    export type RoleEnum = 'trainee' | 'editor' | 'main_editor' | 'maintainer' | 'owner';
    export const RoleEnum = {
        Trainee: 'trainee' as RoleEnum,
        Editor: 'editor' as RoleEnum,
        MainEditor: 'main_editor' as RoleEnum,
        Maintainer: 'maintainer' as RoleEnum,
        Owner: 'owner' as RoleEnum
    };
}
