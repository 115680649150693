/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ChannelDescribeInfo } from './channelDescribeInfo';
import { MessageResponse } from './messageResponse';
import { MessageTaskModel } from './messageTaskModel';
import { PublishError } from './publishError';
import { UserBIOResponse } from './userBIOResponse';


export interface PostResponse { 
    id: string;
    state: PostResponse.StateEnum;
    source: PostResponse.SourceEnum;
    notifications: boolean;
    enableComments: boolean;
    pin: boolean;
    deleteAfter: number;
    channels: Array<ChannelDescribeInfo>;
    creator: UserBIOResponse;
    createdAt: string;
    updatedAt: string;
    scheduleAt?: string;
    publishedAt?: string;
    trashAt?: string;
    lockAt?: string;
    messages: Array<MessageResponse>;
    shareCode?: string;
    tgDeleted: boolean;
    tgDeletedAt?: string;
    tgDeletedErrors?: any;
    tgDeletedErrorsCount: number;
    publishErrors?: Array<PublishError>;
    views: any;
    reactions: any;
    clicks: any;
    hiddenLinksClicksCount: any;
    hiddenLinksClicksBnts: any;
    postTasks?: MessageTaskModel;
}
export namespace PostResponse {
    export type StateEnum = 'draft' | 'queue' | 'sent' | 'trash';
    export const StateEnum = {
        Draft: 'draft' as StateEnum,
        Queue: 'queue' as StateEnum,
        Sent: 'sent' as StateEnum,
        Trash: 'trash' as StateEnum
    };
    export type SourceEnum = 'unknown' | 'bot' | 'web' | 'cron';
    export const SourceEnum = {
        Unknown: 'unknown' as SourceEnum,
        Bot: 'bot' as SourceEnum,
        Web: 'web' as SourceEnum,
        Cron: 'cron' as SourceEnum
    };
}
