import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberThousandsFormat',
})
export class NumberThousandsFormatPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    return this.number_short_format(value, 2, 1);
  }

  number_short_format(val, dm, dk) {
    dm = dm || 2;
    dk = dk || 1;

    if (val >= 1000000) {
      val = this.number_format(val / 1000000, dm, '.', '') + 'М';
    } else {
      if (val >= 1000) {
        val = this.number_format(val / 1000, dk, '.') + 'K';
      } else {
        val = this.number_format(val, 0);
      }
    }
    return val;
  }

  number_format(numbers, decimals, decPoint?, thousandsSep?) {
    let n = numbers;
    const c = isNaN((decimals = Math.abs(decimals))) ? 2 : decimals;
    const d = decPoint === undefined ? ',' : decPoint;
    const t = thousandsSep === undefined ? '.' : thousandsSep;
    const s = n < 0 ? '-' : '';
    const i: any = parseInt((n = Math.abs(+n || 0).toFixed(c)), 10) + '';
    let j = i.length;
    if (j > 3) {
      j = j % 3;
    } else {
      j = 0;
    }
    // j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : '')
    );
  }
}
