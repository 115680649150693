import { LoginWithTgDto, MessageDefaultSettings, SelfInfo } from '../api';
import { UserStateModel } from './user.state.model';
import { HttpErrorResponse } from '@angular/common/http';

export class Login {
  static readonly type = '[UserState] Login';
  constructor(public payload: LoginWithTgDto) {}
}

export class LoginSuccess {
  static readonly type = '[UserState] LoginSuccess';
  constructor(public payload: UserStateModel) {}
}

export class LoginFail {
  static readonly type = '[UserState] LoginFail';
  constructor(public payload: HttpErrorResponse) {}
}

export class SignOut {
  static readonly type = '[UserState] SignOut';
}

export class LoadUserInfo {
  static readonly type = '[UserState] LoadInfo';
}

export class SetUserInfo {
  static readonly type = '[UserState] SetUserInfo';
  constructor(public payload: SelfInfo) {}
}

export class UpdatePostFromSettings {
  static readonly type = '[UserState] UpdatePostFromSettings';
  constructor(public payload: MessageDefaultSettings) {}
}
