<tg-mobile-full-screen-dialog [fixedHeader]="true">
  <h1 class="title" i18n="Links clicks stats|title @@linkClicksStats.title">
    Клики по ссылкам
  </h1>
  <tg-inline-loader *ngIf="loading"></tg-inline-loader>
  <table *ngIf="stats && stats[data.channelId]">
    <thead>
      <tr>
        <th i18n="Links clicks stats|header @@linkClicksStats.tableHeader.clicks">Clicks</th>
        <th i18n="Links clicks stats|header @@linkClicksStats.tableHeader.url">Url</th>
      </tr>
      <tr *ngFor="let link of stats[data.channelId]">
        <td><b>{{link.count}}</b></td>
        <td>{{link.url}}</td>
      </tr>
    </thead>
  </table>
</tg-mobile-full-screen-dialog>
