import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { AuthService, UsersService } from '../api';
import { AuthHelper } from './auth.helper';
import { tap } from 'rxjs/operators';
import {
  LoadUserInfo,
  Login,
  LoginFail,
  LoginSuccess,
  SetUserInfo,
  SignOut,
  UpdatePostFromSettings,
} from './auth.actions';
import { AppRouterService } from '../core/app-router.service';
import { MatDialog } from '@angular/material/dialog';
import * as Sentry from '@sentry/browser';
import { AppState } from '../app.state';
import { UserStateModel } from './user.state.model';
import { Settings } from 'luxon';
import { Inject, Injectable, LOCALE_ID } from "@angular/core";

function setSentryContext(auth: UserStateModel) {
  Sentry.setUser({
    lastName: auth.user.lastName,
    firstName: auth.user.firstName,
    username: auth.user.username,
    id: auth.user.id,
  });
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    ...AuthHelper.getState(),
    loading: false,
  },
})
@Injectable()
export class UserState {
  constructor(
    private api: AuthService,
    private userApi: UsersService,
    private router: AppRouterService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string
  ) {
    const auth = AuthHelper.getState();
    if (auth.isAuth) {
      setSentryContext(auth);
    }
  }

  @Selector()
  static userInfo(model: UserStateModel) {
    return model.info;
  }

  @Selector()
  static features(model: UserStateModel) {
    return model.features;
  }

  @Selector()
  static timeZone(model: UserStateModel) {
    return model.info.timeZone;
  }

  @Selector()
  static messageDefaultSettings(model: UserStateModel) {
    return model.info.messageDefaultSettings;
  }

  @Selector()
  static feature(name: string) {
    return createSelector([UserState], (state: AppState) => {
      return state.user.features && state.user.features.indexOf(name) !== -1;
    });
  }

  @Action(LoadUserInfo)
  loadInfo(ctx: StateContext<UserStateModel>) {
    return this.userApi.me().pipe(
      tap(rs => {
        ctx.dispatch(new SetUserInfo(rs));
      })
    );
  }

  @Action(Login)
  login(ctx: StateContext<UserStateModel>, { payload }: Login) {
    ctx.setState({ ...ctx.getState(), loading: true });

    return this.api.loginWithTg(payload).pipe(
      tap(
        res => {
          // TODO: refactor @misha
          // use $res var as payload for LoginSuccess
          AuthHelper.saveAuthRes(res);
          ctx.dispatch(new LoginSuccess(AuthHelper.getState()));
        },
        e => ctx.dispatch(new LoginFail(e))
      )
    );
  }

  @Action(LoginSuccess)
  loginSuccess(ctx: StateContext<UserStateModel>, { payload }: LoginSuccess) {
    ctx.setState({ ...ctx.getState(), ...payload, loading: false });

    setSentryContext(payload);
  }

  @Action(SignOut)
  signOut(ctx: StateContext<UserStateModel>) {
    AuthHelper.resetStoreData();
    ctx.setState({ ...ctx.getState(), isAuth: false, user: null, access_token: '', expires_at: 0 });
    this.dialog.closeAll();
    this.router.goToLoginPage();
  }

  @Action(UpdatePostFromSettings)
  UpdatePostFromSettings(ctx: StateContext<UserStateModel>, { payload }: UpdatePostFromSettings) {
    let curr = ctx.getState();
    ctx.patchState({
      info: {
        ...curr.info,
        messageDefaultSettings: payload,
      },
    });
  }

  @Action(SetUserInfo)
  SetUserInfo(ctx: StateContext<UserStateModel>, { payload }: SetUserInfo) {
    Settings.defaultLocale = this.locale || 'ru';
    if (payload.info.timeZone) {
      Settings.defaultZoneName = payload.info.timeZone;
    }
    ctx.patchState({
      info: payload.info,
      features: payload.features,
    });
  }
}
