import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '-';
    }
    return DateTime.fromISO(value).toFormat(args || 'dd MMMM yyyy');
  }
}
