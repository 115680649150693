<div style="margin: 0; padding: 0px; max-width: 600px">
  <div fxLayout="column" class="group-settings" [formGroup]="form">
    <tg-error-block *ngIf="lastError" [error]="lastError" [closebtn]="true"></tg-error-block>

    <div class="setting" fxLayout="row" fxLayoutAlign="space-between center">
      <span i18n="Post form settings|label @@postFormSettings.linksPreviewsLabel">Предпросмотр ссылок</span>
      <mat-slide-toggle color="primary" formControlName="previewLinks"></mat-slide-toggle>
    </div>
    <div class="setting" fxLayout="row" fxLayoutAlign="space-between center">
      <span i18n="Post form settings|label @@postFormSettings.notificationsLabel">Уведомление</span>
      <mat-slide-toggle color="primary" formControlName="notifications"></mat-slide-toggle>
    </div>
    <div class="setting" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="___commentsFeature">
      <span i18n="Post form settings|label @@postFormSettings.commentsLabel">Комментарии</span>
      <mat-slide-toggle color="primary" formControlName="enableComments"></mat-slide-toggle>
    </div>
  </div>

  <div class="actions">
    <button
      aria-label="save form default settings"
      mat-raised-button
      [disabled]="form.pristine"
      color="primary"
      (click)="save()"
      i18n="Post form settings|button @@postFormSettings.saveButton"
    >
      Сохранить
    </button>
  </div>
</div>
