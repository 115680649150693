<div
  (click)="openPostDialog($event)"
  (mouseenter)="openPopover($event)"
  (mouseleave)="closePopover()"
  class="post-mark"
  style="padding: 0"
  cdkOverlayOrigin
>
  <div
    *ngFor="let msg of post.messages"
    [style.backgroundColor]="colors(msg.type)"
    class="post-mark"
    style="height: 14px"
  ></div>
</div>

<ng-template #popover>
  <div class="popover-block">
    <tg-post-item
      [post]="post"
      (edit)="editPost($event)"
      (editMsg)="editPostMsg($event)"
      [showNavigation]="false"
    ></tg-post-item>
  </div>
</ng-template>
