import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { SelectedChannelState } from '../../../channel/state/selected-channel.state';
import { Store } from '@ngxs/store';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChannelResponse, PostsCrudService } from '../../../api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-voters-dialog',
  templateUrl: './voters-dialog.component.html',
  styleUrls: ['./voters-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VotersDialogComponent implements OnInit {
  votersLoad = false;
  voters: any;

  constructor(
    private store: Store,
    private apiPosts: PostsCrudService,
    @Inject(MAT_DIALOG_DATA) private data: { msgId: string; postId: string; type: 'poll' | 'reactions' },
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.votersLoad = true;
    const channel = this.store.selectSnapshot(SelectedChannelState.channel);
    if (this.data.type === 'poll') {
      this.getPollVoters(channel);
    } else {
      this.getReactionsVoters(channel);
    }
  }

  getReactionsVoters(channel: ChannelResponse) {
    this.apiPosts
      .getMessageVoters(this.data.msgId, this.data.postId, channel.id)
      .pipe(untilDestroyed(this))
      .subscribe(rs => {
        this.voters = rs;
        this.voters.getChoice = ch => this.voters.info['text:' + ch];
        this.voters.reactions = Object.keys(rs.info || {})
          .filter(r => r.startsWith('text:'))
          .map(k => {
            let value = k.replace('text:', '');
            return {
              name: rs.info[k] + ' - ' + rs.info['count:' + value],
              value: value,
            };
          });
        this.votersLoad = false;
        this.cd.detectChanges();
      });
  }

  getPollVoters(channel: ChannelResponse) {
    this.apiPosts
      .getMessageVoters(this.data.msgId, this.data.postId, channel.id)
      .pipe(untilDestroyed(this))
      .subscribe(rs => {
        this.voters = rs;
        if (rs.info) {
          this.voters.getChoice = ch => this.voters.info.answers[ch];
          this.voters.reactions = rs.info.answers.map((k, index) => ({
            name: k,
            value: index,
          }));
        }
        this.votersLoad = false;
        this.cd.detectChanges();
      });
  }

  filterVotersByReaction(val: any[]) {
    if (typeof this.voters.filter !== 'undefined' && this.voters.filter !== null) {
      return val.filter(value => value.choice == this.voters.filter);
    }
    return val;
  }

  filterVoters(ev) {
    this.voters.filter = ev.value;
  }
}
