import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRouterService } from '../core/app-router.service';
import { Select, Store } from '@ngxs/store';
import { UserState } from './user.state';
import { map, tap } from 'rxjs/operators';
import { UserStateModel } from './user.state.model';

@Injectable()
export class AuthGuard implements CanActivate {
  @Select(UserState)
  user$: Observable<UserStateModel>;

  constructor(private store: Store, private router: AppRouterService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.user$.pipe(
      map(u => u.isAuth),
      tap(isAuth => {
        if (!isAuth) {
          this.router.goToLoginPage();
        }
      })
    );
  }
}
