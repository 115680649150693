import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BotsService } from '../../api';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'tg-dialog-create-bot',
  templateUrl: './dialog-create-bot.component.html',
  styleUrls: ['./dialog-create-bot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCreateBotComponent implements OnInit {
  token = new FormGroup({ token: new FormControl() });

  constructor(public dialogRef: MatDialogRef<DialogCreateBotComponent>, private botsService: BotsService) {}

  ngOnInit() {}

  saveToken() {
    return () => this.botsService.createBot(this.token.value);
  }

  botCreated(res) {
    this.dialogRef.close({
      msg: $localize`:Create bot dialog|notify @@createBotDialog.createSuccess:Бот успешно подключен`,
      res,
    });
  }
}
