export function getClickPath(el: HTMLElement) {
  if (!el) {
    return '';
  }
  const parent = el.parentElement;

  if (el.hasAttribute('aria-label')) {
    return '~~> ' + el.getAttribute('aria-label');
  }

  let elementDesc = el.tagName.toLocaleLowerCase();
  if (el.id) {
    elementDesc += '#' + el.id;
  } else if (el.classList && el.classList.length) {
    elementDesc += '.' + [].slice.apply(el.classList).join('.');
  }

  if (el && el.tagName.toLocaleLowerCase() === 'button') {
    const btnText = el.textContent;
    if (btnText) {
      elementDesc += '=' + btnText.trim();
    }
  }

  let path = [elementDesc];

  if (parent && parent.tagName.toLocaleLowerCase() === 'button') {
    const btnText = parent.textContent;
    if (btnText) {
      path = [btnText];
    }
  }

  while ((el = el.parentElement) && path.length < 3) {
    if (el.hasAttribute('aria-label')) {
      return '~~> ' + el.getAttribute('aria-label');
    }

    const tagName = el.tagName.toLocaleLowerCase();
    if (el.id) {
      path.push('#' + el.id);
    } else if (tagName.startsWith('tg-') && tagName !== 'tg-root') {
      path.push(tagName);
    }
  }

  return path.reverse().join(' > ');
}

export function convertFormValueToJsonObject(form: any) {
  const onlyVals = obj => {
    const o = {};
    Object.keys(obj).forEach(key => {
      o[key] = obj[key];
    });
    return o;
  };
  return onlyVals(form);
}

export function arrayMove(arr: any[], from: number, to: number) {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
}
