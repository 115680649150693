/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthResult } from '../model/authResult';
import { BotShowResponse } from '../model/botShowResponse';
import { BotsListResponse } from '../model/botsListResponse';
import { ChannelResponse } from '../model/channelResponse';
import { ChannelsListResponse } from '../model/channelsListResponse';
import { CountItemsResponse } from '../model/countItemsResponse';
import { FeatureModel } from '../model/featureModel';
import { SetFeaturesDto } from '../model/setFeaturesDto';
import { SetUserLimitsDto } from '../model/setUserLimitsDto';
import { UserSetActiveDto } from '../model/userSetActiveDto';
import { UserShowResponse } from '../model/userShowResponse';
import { UsersListResponse } from '../model/usersListResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Disable feature
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public disableFeature(id: string, observe?: 'body', reportProgress?: boolean): Observable<FeatureModel>;
    public disableFeature(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FeatureModel>>;
    public disableFeature(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FeatureModel>>;
    public disableFeature(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling disableFeature.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<FeatureModel>(`${this.basePath}/admin/features/${encodeURIComponent(String(id))}/disable`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Enable feature
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enableFeature(id: string, observe?: 'body', reportProgress?: boolean): Observable<FeatureModel>;
    public enableFeature(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FeatureModel>>;
    public enableFeature(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FeatureModel>>;
    public enableFeature(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling enableFeature.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.patch<FeatureModel>(`${this.basePath}/admin/features/${encodeURIComponent(String(id))}/enable`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bot by id with user and channels
     * 
     * @param botId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBotById(botId: string, observe?: 'body', reportProgress?: boolean): Observable<BotShowResponse>;
    public getBotById(botId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BotShowResponse>>;
    public getBotById(botId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BotShowResponse>>;
    public getBotById(botId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (botId === null || botId === undefined) {
            throw new Error('Required parameter botId was null or undefined when calling getBotById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BotShowResponse>(`${this.basePath}/admin/bots/${encodeURIComponent(String(botId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get channel by id with user and bot
     * 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelById(channelId: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelResponse>;
    public getChannelById(channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelResponse>>;
    public getChannelById(channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelResponse>>;
    public getChannelById(channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getChannelById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChannelResponse>(`${this.basePath}/admin/channels/${encodeURIComponent(String(channelId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get new channels registrations
     * 
     * @param where Json encoded object
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelsRegStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<CountItemsResponse>;
    public getChannelsRegStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountItemsResponse>>;
    public getChannelsRegStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountItemsResponse>>;
    public getChannelsRegStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CountItemsResponse>(`${this.basePath}/admin/stats/channels-reg`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users with feature enabled
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFeatureUsers(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UsersListResponse>>;
    public getFeatureUsers(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UsersListResponse>>>;
    public getFeatureUsers(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UsersListResponse>>>;
    public getFeatureUsers(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFeatureUsers.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<UsersListResponse>>(`${this.basePath}/admin/features/${encodeURIComponent(String(id))}/feature-users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all features
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFeatures(observe?: 'body', reportProgress?: boolean): Observable<Array<FeatureModel>>;
    public getFeatures(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FeatureModel>>>;
    public getFeatures(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FeatureModel>>>;
    public getFeatures(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<FeatureModel>>(`${this.basePath}/admin/features`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get files size
     * 
     * @param where Json encoded object
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilesSizeStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<CountItemsResponse>;
    public getFilesSizeStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountItemsResponse>>;
    public getFilesSizeStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountItemsResponse>>;
    public getFilesSizeStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CountItemsResponse>(`${this.basePath}/admin/stats/files-size-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get published messages stats
     * 
     * @param where Json encoded object
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMessagesPublishedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<CountItemsResponse>;
    public getMessagesPublishedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountItemsResponse>>;
    public getMessagesPublishedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountItemsResponse>>;
    public getMessagesPublishedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CountItemsResponse>(`${this.basePath}/admin/stats/messages-published-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get post by id
     * 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostById(postId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPostById(postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPostById(postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPostById(postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling getPostById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/admin/posts/${encodeURIComponent(String(postId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get new posts stats
     * 
     * @param where Json encoded object
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostsCreatedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<CountItemsResponse>;
    public getPostsCreatedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountItemsResponse>>;
    public getPostsCreatedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountItemsResponse>>;
    public getPostsCreatedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CountItemsResponse>(`${this.basePath}/admin/stats/posts-created-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get published posts stats
     * 
     * @param where Json encoded object
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostsPublishedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<CountItemsResponse>;
    public getPostsPublishedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountItemsResponse>>;
    public getPostsPublishedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountItemsResponse>>;
    public getPostsPublishedStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CountItemsResponse>(`${this.basePath}/admin/stats/posts-published-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get published posts to unique channels stats
     * 
     * @param where Json encoded object
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPostsPublishedUniqueChannelsStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<CountItemsResponse>;
    public getPostsPublishedUniqueChannelsStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountItemsResponse>>;
    public getPostsPublishedUniqueChannelsStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountItemsResponse>>;
    public getPostsPublishedUniqueChannelsStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CountItemsResponse>(`${this.basePath}/admin/stats/posts-published-unique-channels-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get published posts to unique channels stats
     * 
     * @param where Json encoded object
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUniqueUsersCreatedPostsStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<CountItemsResponse>;
    public getUniqueUsersCreatedPostsStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountItemsResponse>>;
    public getUniqueUsersCreatedPostsStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountItemsResponse>>;
    public getUniqueUsersCreatedPostsStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CountItemsResponse>(`${this.basePath}/admin/stats/posts-unique-creators-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get access token for any user
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserAccessToken(userId: string, observe?: 'body', reportProgress?: boolean): Observable<AuthResult>;
    public getUserAccessToken(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthResult>>;
    public getUserAccessToken(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthResult>>;
    public getUserAccessToken(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserAccessToken.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<AuthResult>(`${this.basePath}/admin/users/${encodeURIComponent(String(userId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get channels where user is admin
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserAdmin(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelsListResponse>;
    public getUserAdmin(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelsListResponse>>;
    public getUserAdmin(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelsListResponse>>;
    public getUserAdmin(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserAdmin.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChannelsListResponse>(`${this.basePath}/admin/users/${encodeURIComponent(String(userId))}/admins`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get user by id with his bots and channels
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserById(userId: string, observe?: 'body', reportProgress?: boolean): Observable<UserShowResponse>;
    public getUserById(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserShowResponse>>;
    public getUserById(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserShowResponse>>;
    public getUserById(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<UserShowResponse>(`${this.basePath}/admin/users/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get new users registrations
     * 
     * @param where Json encoded object
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersRegStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<CountItemsResponse>;
    public getUsersRegStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CountItemsResponse>>;
    public getUsersRegStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CountItemsResponse>>;
    public getUsersRegStats(where?: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<CountItemsResponse>(`${this.basePath}/admin/stats/users-reg`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bots list
     * Find bots with sql query: where&#x3D;id&#x3D;6&amp;skip&#x3D;0&amp;take&#x3D;10&amp;order&#x3D;-id
     * @param where 
     * @param skip 
     * @param take 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBots(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<BotsListResponse>;
    public searchBots(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BotsListResponse>>;
    public searchBots(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BotsListResponse>>;
    public searchBots(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<BotsListResponse>(`${this.basePath}/admin/bots`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get channels list
     * Find channels with sql query: where&#x3D;id&#x3D;6&amp;skip&#x3D;0&amp;take&#x3D;10&amp;order&#x3D;-id
     * @param where 
     * @param skip 
     * @param take 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchChannels(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<ChannelsListResponse>;
    public searchChannels(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ChannelsListResponse>>;
    public searchChannels(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ChannelsListResponse>>;
    public searchChannels(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ChannelsListResponse>(`${this.basePath}/admin/channels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get posts list
     * Find posts with sql query: where&#x3D;id&#x3D;6&amp;skip&#x3D;0&amp;take&#x3D;10&amp;order&#x3D;-id
     * @param where 
     * @param skip 
     * @param take 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPosts(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public searchPosts(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public searchPosts(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public searchPosts(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/admin/posts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users list
     * Find users with sql query: where&#x3D;id&#x3D;6 and active&#x3D;true&amp;skip&#x3D;0&amp;take&#x3D;10&amp;order&#x3D;-id
     * @param where 
     * @param skip 
     * @param take 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUsers(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<UsersListResponse>;
    public searchUsers(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsersListResponse>>;
    public searchUsers(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsersListResponse>>;
    public searchUsers(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<UsersListResponse>(`${this.basePath}/admin/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change user features
     * 
     * @param SetUserLimitsDto 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setLimits(SetUserLimitsDto: SetUserLimitsDto, userId: string, observe?: 'body', reportProgress?: boolean): Observable<UserShowResponse>;
    public setLimits(SetUserLimitsDto: SetUserLimitsDto, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserShowResponse>>;
    public setLimits(SetUserLimitsDto: SetUserLimitsDto, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserShowResponse>>;
    public setLimits(SetUserLimitsDto: SetUserLimitsDto, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SetUserLimitsDto === null || SetUserLimitsDto === undefined) {
            throw new Error('Required parameter SetUserLimitsDto was null or undefined when calling setLimits.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling setLimits.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserShowResponse>(`${this.basePath}/admin/users/${encodeURIComponent(String(userId))}/setLimits`,
            SetUserLimitsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ban/Unban user
     * 
     * @param UserSetActiveDto 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserActive(UserSetActiveDto: UserSetActiveDto, userId: string, observe?: 'body', reportProgress?: boolean): Observable<UserShowResponse>;
    public setUserActive(UserSetActiveDto: UserSetActiveDto, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserShowResponse>>;
    public setUserActive(UserSetActiveDto: UserSetActiveDto, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserShowResponse>>;
    public setUserActive(UserSetActiveDto: UserSetActiveDto, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (UserSetActiveDto === null || UserSetActiveDto === undefined) {
            throw new Error('Required parameter UserSetActiveDto was null or undefined when calling setUserActive.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling setUserActive.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserShowResponse>(`${this.basePath}/admin/users/${encodeURIComponent(String(userId))}/set-active`,
            UserSetActiveDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change user features
     * 
     * @param SetFeaturesDto 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserFeatures(SetFeaturesDto: SetFeaturesDto, userId: string, observe?: 'body', reportProgress?: boolean): Observable<UserShowResponse>;
    public setUserFeatures(SetFeaturesDto: SetFeaturesDto, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserShowResponse>>;
    public setUserFeatures(SetFeaturesDto: SetFeaturesDto, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserShowResponse>>;
    public setUserFeatures(SetFeaturesDto: SetFeaturesDto, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SetFeaturesDto === null || SetFeaturesDto === undefined) {
            throw new Error('Required parameter SetFeaturesDto was null or undefined when calling setUserFeatures.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling setUserFeatures.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserShowResponse>(`${this.basePath}/admin/users/${encodeURIComponent(String(userId))}/setFeatures`,
            SetFeaturesDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users tg notifications
     * 
     * @param where 
     * @param skip 
     * @param take 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersTgNotifications(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersTgNotifications(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersTgNotifications(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersTgNotifications(where?: { [key: string]: string; }, skip?: number, take?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (where !== undefined && where !== null) {
            queryParameters = queryParameters.set('where', <any>where);
        }
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/admin/user-tg-notifications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
