import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostItemComponent } from './components/post-item/post-item.component';
import { PostMessageItemComponent } from './components/post-message-item/post-message-item.component';
import { PostCopyDialogComponent } from './components/post-copy-dialog/post-copy-dialog.component';
import { SharePostDialogComponent } from './components/share-post-dialog/share-post-dialog.component';
import { PostViewContainerComponent } from './components/post-view-container/post-view-container.component';
import { BottomSheetModule } from '../components/custom-bottom-sheet/bottom-sheet.module';
import { SharedModule } from '../shared/shared.module';
import { VotersDialogComponent } from './components/voters-dialog/voters-dialog.component';
import { PostViewsDialogComponent } from './components/post-views-dialog/post-views-dialog.component';
import { LinksClicksDialogComponent } from './components/links-clicks-dialog/links-clicks-dialog.component';
import { HiddenTextClicksDialogComponent } from './components/hidden-text-clicks/hidden-text-clicks-dialog.component';
import { DocumentContainerComponent } from './components/post-message-item/document-container/document-container.component';
import { VideoContainerComponent } from './components/post-message-item/video-container/video-container.component';
import { AudioContainerComponent } from './components/post-message-item/audio-container/audio-container.component';
import { VoiceContainerComponent } from './components/post-message-item/voice-container/voice-container.component';

@NgModule({
  imports: [CommonModule, SharedModule, BottomSheetModule],
  declarations: [
    PostItemComponent,
    PostMessageItemComponent,
    PostCopyDialogComponent,
    SharePostDialogComponent,
    PostViewContainerComponent,
    VotersDialogComponent,
    PostViewsDialogComponent,
    LinksClicksDialogComponent,
    HiddenTextClicksDialogComponent,
    DocumentContainerComponent,
    VideoContainerComponent,
    AudioContainerComponent,
    VoiceContainerComponent,
  ],
  entryComponents: [PostCopyDialogComponent, SharePostDialogComponent],
  exports: [PostItemComponent, PostMessageItemComponent, PostViewContainerComponent],
})
export class PostViewModule {}
