import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ChannelResponse } from '../api';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserState } from './user.state';
import { LoadUserInfo } from './auth.actions';
import { of } from 'rxjs';

@Injectable()
export class UserResolver implements Resolve<ChannelResponse> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const loaded = this.store.selectSnapshot(UserState.userInfo);
    if (!loaded) {
      return this.store.dispatch(new LoadUserInfo());
    }

    return of(null);
  }
}
