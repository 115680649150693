<div style="height: 100vh; padding: 20px; box-sizing: border-box">
  <div style="padding-top: 100px"></div>

  <mat-card style="max-width: 400px; margin: 0 auto;" class="mat-elevation-z10">
    <mat-card-title style="text-align: center" i18n="Login page|title @@loginPage.title">Вход в Telepost</mat-card-title>
    <mat-card-subtitle style="text-align: center" i18n="Login page|title @@loginPage.subTitle">Отложенный постинг в
      Telegram
    </mat-card-subtitle>

    <mat-card-content style="text-align: center; padding: 50px 0 20px;">
      <div #widgetLabel style="padding-bottom: 16px; display: none" i18n="Login page|label @@loginPage.widgetLabel">Авторизация с помощью виджета Telegram</div>
      <div id="telegramLoginWidget"></div>
      <br>
      <div style="padding-bottom: 16px" i18n="Login page|label @@loginPage.botLabel">Авторизация с помощью бота Telepost</div>
      <div class="auth-with-bot">
        <a href="https://t.me/{{botUsername}}?start=web">
          <mat-icon>android</mat-icon>
          <ng-container i18n="Login page|button @@loginPage.botLoginBtn">Вход через Telepost Bot</ng-container>
        </a>
      </div>
      <div *ngIf="isFeature">
        <a [href]="furl">Авторизация для фич сюда</a>
      </div>
    </mat-card-content>
    <p style="text-align: center" class="help-link">
      <a href="https://telepost.me/help/base/login/" i18n="Login page|link @@loginPage.helpLink">
        Нужна помощь?
      </a>
    </p>

  </mat-card>
</div>
