<tg-top-nav style="position: relative"></tg-top-nav>

<div style="background-color: rgb(245, 245, 245)">
  <div class="container" style="padding: 56px 16px;">
    <h1 class="mat-display-1" style="margin-bottom: 20px" i18n="Posts calendar page|title @@postsCalendar.title">Календарь публикаций</h1>
    <div class="mat-subheading-1" i18n="Posts calendar page|text @@postsCalendar.subHeader">
      Здесь ты можешь посмотреть все запланирование и опубликованые публикации во всех каналах к которым у тебя есть
      доступ.
    </div>
  </div>
</div>

<div class="container">
  <tg-posts-calendar-v2 *ngIf="channels.length" [channels]="channels" [state]="'queue'" [showStateSwitcher]="true"></tg-posts-calendar-v2>
  <h3 *ngIf="!channels.length" style="text-align: center; padding: 16px" i18n="Posts calendar page|text @@postsCalendar.noChannels">Нет каналов</h3>
</div>
