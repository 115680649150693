<div class="dialog-wrapper">
  <button class="close-btn" mat-icon-button tabindex="-1" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
  <h2 mat-dialog-title>{{dialogTitle}}</h2>

  <mat-dialog-content cdkFocusInitial class="content" [class.add-channel]="typeDialog === 'addChannel'">
    <ng-container *ngIf="errorSave">
      <tg-error-block [error]="errorSave"></tg-error-block>
    </ng-container>

    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button id="dg_ad_ch_cancel_btn" mat-button mat-dialog-close color="secondary"
            i18n="Dialog form|button @@dialogForm.cancelBtn">ОТМЕНА
    </button>
    <button id="dg_ad_ch_save_btn" mat-raised-button color="primary" [disabled]="form.invalid || saving"
            (click)="submit()">
      <mat-spinner *ngIf="saving"></mat-spinner>
      <ng-container i18n="Dialog form|button @@dialogForm.saveBtn">СОХРАНИТЬ</ng-container>
    </button>
  </mat-dialog-actions>
</div>
