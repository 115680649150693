import { Directionality } from '@angular/cdk/bidi';
import { CdkOverlayOrigin, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { PostDialogComponent } from './post-dialog/post-dialog.component';
import { OpenEditForm, OpenEditMessageForm } from '../../post-form/post-from.actions';

@Component({
  selector: 'tg-calendar-post-item',
  templateUrl: './calendar-post-item.component.html',
  styleUrls: ['./calendar-post-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarPostItemComponent implements OnInit, OnDestroy {
  @Input()
  post;
  @Output()
  updatePost = new Subject();

  _colors = {
    text: '#00B0FF',
    photo: '#FF877E',
    video: '#69E6BF',
    document: '#FFD668',
    poll: '#DDABFC',
    mediaGroup: '#a9f33f',
    audio: '#120cfc',
    voice: '#fc30df',
    animation: '#fcf957',
    videoNote: '#69E6BF',
    sticker: '#575757',
  };

  overlayRef: OverlayRef | null;

  @ViewChild(CdkOverlayOrigin)
  _overlayOrigin: CdkOverlayOrigin;
  @ViewChild('popover', { static: true })
  overlayTemplate: TemplateRef<any>;

  constructor(
    private store: Store,
    private dialog: MatDialog,
    public overlay: Overlay,
    public dir: Directionality,
    public viewContainerRef: ViewContainerRef
  ) {}

  colors(type) {
    return this._colors[type] || '#FF877E';
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  openPostDialog(e) {
    e.stopPropagation();
    this.dialog
      .open(PostDialogComponent, {
        maxWidth: 600,
        minWidth: 400,
        autoFocus: false,
        data: {
          post: this.post,
        },
        viewContainerRef: this.viewContainerRef,
      })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.updatePost.next();
        }
      });
  }

  openPopover(e) {
    if (this.overlayRef) {
      return;
    }
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this._overlayOrigin.elementRef)
      .withFlexibleDimensions(true)
      .withPush(true)
      .withViewportMargin(10)
      .withGrowAfterOpen(true)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
          offsetX: 0,
          offsetY: 0,
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      direction: this.dir.value,
      minWidth: 200,
      minHeight: 50,
      // maxWidth: 400
    });

    this.overlayRef.attach(new TemplatePortal(this.overlayTemplate, this.viewContainerRef));
  }

  closePopover() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  editPost(post) {
    this.store.dispatch(new OpenEditForm(post));
  }

  editPostMsg(msg) {
    this.store.dispatch(new OpenEditMessageForm(msg));
  }
}
