/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CopyPostDto } from '../model/copyPostDto';
import { PostResponse } from '../model/postResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SharedPostService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create post copy
     * 
     * @param CopyPostDto 
     * @param shareCode 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copy(CopyPostDto: CopyPostDto, shareCode: string, postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public copy(CopyPostDto: CopyPostDto, shareCode: string, postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public copy(CopyPostDto: CopyPostDto, shareCode: string, postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public copy(CopyPostDto: CopyPostDto, shareCode: string, postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (CopyPostDto === null || CopyPostDto === undefined) {
            throw new Error('Required parameter CopyPostDto was null or undefined when calling copy.');
        }

        if (shareCode === null || shareCode === undefined) {
            throw new Error('Required parameter shareCode was null or undefined when calling copy.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling copy.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PostResponse>(`${this.basePath}/shared-post/${encodeURIComponent(String(postId))}/${encodeURIComponent(String(shareCode))}/copy`,
            CopyPostDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get shared post info
     * 
     * @param shareCode 
     * @param postId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(shareCode: string, postId: string, observe?: 'body', reportProgress?: boolean): Observable<PostResponse>;
    public get(shareCode: string, postId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostResponse>>;
    public get(shareCode: string, postId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostResponse>>;
    public get(shareCode: string, postId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (shareCode === null || shareCode === undefined) {
            throw new Error('Required parameter shareCode was null or undefined when calling get.');
        }

        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling get.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PostResponse>(`${this.basePath}/shared-post/${encodeURIComponent(String(postId))}/${encodeURIComponent(String(shareCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
