import { Pipe, PipeTransform } from '@angular/core';

function declineOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

@Pipe({
  name: 'timeDiff',
})
export class TimeDiffPipe implements PipeTransform {
  transform(seconds: any, args?: any): any {
    if (!seconds) return null;
    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    const hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    const mnts = Math.floor(seconds / 60);
    return `
      ${days ? days + declineOfNum(days, [' день', ' дня', ' дней']) : ''} 
      ${hrs ? hrs + declineOfNum(hrs, [' час', ' часа', ' часов']) : ''} 
      ${mnts ? mnts + declineOfNum(mnts, [' минуту', ' минуты', ' минут']) : ''}`;
  }
}
