import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoginService } from '../../auth/login.service';
import { AuthHelper } from '../../auth/auth.helper';
import { LoginSuccess } from '../../auth/auth.actions';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SelectLangDialogComponent } from '../../components/select-lang-dialog/select-lang-dialog.component';

@Component({
  selector: 'tg-top-nav-user',
  templateUrl: './top-nav-user.component.html',
  styleUrls: ['./top-nav-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TopNavUserComponent implements OnInit {
  @Input()
  showAvatar = true;

  @Select('user.user')
  user$: Observable<any>;
  @Select('user.isAuth')
  isAuth$: Observable<boolean>;

  constructor(public login: LoginService, private store: Store, private router: Router, private dialog: MatDialog) {}

  ngOnInit() {}

  logOutUser(userId) {
    const oldAccount = this.getOldToken();
    localStorage.removeItem('oldToken');
    this.logoutWithAccount(oldAccount, userId);
  }

  logoutWithAccount(access, logOutUserId) {
    AuthHelper.saveAuthRes(access);
    this.store.dispatch(new LoginSuccess(AuthHelper.getState()));
    this.router.navigateByUrl('/admins/users/' + logOutUserId);
  }

  getOldToken() {
    return JSON.parse(localStorage.getItem('oldToken'));
  }

  changeLanguageDialog() {
    this.dialog.open(SelectLangDialogComponent, {autoFocus: false});
  }
}
