<div>
  <div class="attach-doc">
    <img *ngIf="file.posterUrl"
         class="attach-doc-mark"
         [src]="file.posterUrl"
         style="margin-right: 10px"
         [alt]="file.filename"
    />
    <mat-icon class="attach-doc-mark" *ngIf="!file.posterUrl">insert_drive_file</mat-icon>
    <div fxLayout="column">
      <span class="file-name" title="{{ file.filename }}">
        <a [href]="file.publicUrl" target="_blank">{{ file.filename }}</a>
      </span>
      <span class="size">{{ file.size | bytesToSize }}</span>
    </div>
  </div>
</div>
