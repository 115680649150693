import { Component, OnInit, ChangeDetectionStrategy, Input } from "@angular/core";
import { FileResponse, MessageResponse } from "../../../../api";
import { MessageType } from "../../../post-type";

@Component({
  selector: 'tg-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoContainerComponent implements OnInit {
  types = MessageType;
  @Input() msg: MessageResponse;
  @Input() file: FileResponse;
  constructor() { }

  ngOnInit(): void {
  }

}
