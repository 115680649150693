<div class="head" mat-dialog-title>
  <h2 *ngIf="step === steps.Date" i18n="DateTime picker dialog|title @@dateTimePickerDialog.titleDate">Выберите
    дату</h2>
  <h2 *ngIf="step === steps.Time" i18n="DateTime picker dialog|title @@dateTimePickerDialog.titleTime">Выберите
    время</h2>
  <button class="close-btn" mat-icon-button tabindex="-1" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="content">
  <!--Current selected date time show-->
  <div
    tabindex="1"
    style="outline: none; margin-bottom: 8px"
    *ngIf="data.scheduleAt && step === steps.Date"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <span>
      {{ selectedDate.toFormat('EEEE, dd MMMM yyyy')  }}
      <span class="time-big">{{ data.scheduleAt.toFormat('HH:mm') }}</span>
    </span>
    <button mat-icon-button color="warn" matTooltip="Очистить"
            i18n-matTooltip="DateTime picker dialog|tooltip @@dateTimePickerDialog.clearTooltip"
            (click)="clearSelectedDateTime()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div
    fxLayout="row"
    fxLayoutAlign="space-around stretch"
    fxLayoutGap="8px"
    *ngIf="step === steps.Date && !data.scheduleAt"
  >
    <button aria-label="set today" mat-stroked-button (click)="setToday()" style="width: 50%">
      <mat-icon>today</mat-icon>
      <ng-container i18n="DateTime picker dialog|button @@dateTimePickerDialog.todayButton">сегодня</ng-container>
    </button>
    <button aria-label="set tomorrow" mat-stroked-button (click)="setTomorrow()" style="width: 50%">
      <mat-icon>today</mat-icon>
      <ng-container i18n="DateTime picker dialog|button @@dateTimePickerDialog.yesterdayButton">завтра</ng-container>
    </button>
  </div>

  <div *ngIf="_scheduleForm.getError('lessNow')" style="color: red"
       i18n="DateTime picker dialog|error @@dateTimePickerDialog.pastDateError">Время публикации уходит в прошлое
  </div>

  <div class="calendar-body" [hidden]="step === steps.Time">
    <mat-calendar
      cdkTrapFocus
      #matCalendar="matCalendar"
      (selectedChange)="selectDate($event)"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [selected]="selectedDate"
      [startAt]="selectedDate"
    ></mat-calendar>
  </div>

  <div *ngIf="step === steps.Time" [formGroup]="_scheduleForm" class="calendar-body">
    <div style="padding: 8px 0; margin-bottom: 24px">
      <button mat-stroked-button color="primary" style="width: 100%" (click)="setDateView()">
        <mat-icon>calendar_today</mat-icon>
        {{ selectedDate.toFormat('EEEE, dd MMMM yyyy') }}
      </button>
    </div>

    <mat-form-field style="width: calc(50% - 12px); margin-left: 0px;">
      <mat-select placeholder="Часы"
                  i18n-placeholder="DateTime picker dialog|placeholder @@dateTimePickerDialog.hourPlaceholder"
                  formControlName="hour">
        <mat-option [value]="h" *ngFor="let h of hours">{{ h < 10 ? '0' + h : h }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field style="width: calc(50% - 24px); margin-left: 24px;">
      <mat-select placeholder="Минуты"
                  i18n-placeholder="DateTime picker dialog|placeholder @@dateTimePickerDialog.minutesPlaceholder"
                  formControlName="minutes">
        <mat-option [value]="h" *ngFor="let h of minutes">{{ h < 10 ? '0' + h : h }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <mat-chip-list>
        <mat-chip (click)="setTime(time)" *ngFor="let time of cachedTimes" [removable]="true"
                  (removed)="delCached(time)">
          {{ padStart(time) }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <br />
  </div>
  <div style="text-align: center" i18n="DateTime picker dialog|text @@dateTimePickerDialog.currentTimezone">
    Часовой пояс: {{ minDate | dateTime: 'z' }}</div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="step === steps.Time">
  <button mat-button mat-dialog-close i18n="DateTime picker dialog|button @@dateTimePickerDialog.cancelButton">
    Отмена
  </button>
  <button
    aria-label="save datetime"
    mat-raised-button
    color="primary"
    [disabled]="_scheduleForm.invalid"
    (click)="saveResult()"
    i18n="DateTime picker dialog|button @@dateTimePickerDialog.saveButton"
  >
    Сохранить
  </button>
</mat-dialog-actions>
