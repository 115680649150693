import { PreloadingStrategy, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';

let disabled = ['admins', 'bots', 'sent'];

@Injectable()
export class TpPreloadStrategy extends PreloadingStrategy {
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    // console.log(route);
    if (disabled.includes(route.path)) {
      return of(null);
    }
    return fn();
  }
}
