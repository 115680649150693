import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BotResponse, BotsListResponse, BotsService } from '../api/index';
import { finalize, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

export class LoadBots {
  static readonly type = '[BotsState] LoadBots';
}

export class LoadBotsSuccess {
  static readonly type = '[BotsState] LoadBotsSuccess';
  constructor(public payload: BotsListResponse) {}
}

export class SetBotWebhook {
  static readonly type = '[BotsState] SetBotWebhook';
  constructor(public payload: string) {}
}

export class SetBotWebhookSuccess {
  static readonly type = '[BotsState] SetBotWebhookSuccess';
  constructor(public readonly payload: string) {}
}

export class SetBotWebhookFail {
  static readonly type = '[BotsState] SetBotWebhookFail';
  constructor(public readonly payload: HttpErrorResponse) {}
}

export class SelectBot {
  static readonly type = '[BotsState] SelectBot';
  constructor(public payload: string) {}
}

export class DeleteBotSuccess {
  static readonly type = '[BotsState] DeleteBotSuccess';
  constructor(public readonly payload: string) {}
}

export class DeleteBot {
  static readonly type = '[BotsState] DeleteBot';
  constructor(public payload: string) {}
}

export class DeleteBotFail {
  static readonly type = '[BotsState] DeleteBotFail';
  constructor(public payload: any) {}
}

export interface BotsStateModel {
  loading: boolean;
  loaded: boolean;
  count: number;
  items: BotResponse[];
  selectedBotId?: string;
  errorDeleteBot: HttpErrorResponse;
}

@State<BotsStateModel>({
  name: 'bots',
  defaults: {
    loading: false,
    loaded: false,
    count: 0,
    items: [],
    errorDeleteBot: null,
  },
})
@Injectable()
export class BotsState {
  constructor(private botsApi: BotsService) {}

  @Selector()
  static selectedBot(state: BotsStateModel) {
    return state.items.find(bot => bot.id === state.selectedBotId);
  }

  @Selector()
  static selectedError(state: BotsStateModel) {
    return state.errorDeleteBot;
  }

  @Selector()
  static bots(state: BotsStateModel) {
    return state.items;
  }

  @Action(LoadBots)
  loadBots({ setState, getState, dispatch }: StateContext<BotsStateModel>) {
    setState({ ...getState(), loading: true });

    return this.botsApi.getBots().pipe(
      tap(res => dispatch(new LoadBotsSuccess(res))),
      finalize(() => setState({ ...getState(), loading: false }))
    );
  }

  @Action(LoadBotsSuccess)
  loadSuccess({ getState, setState }: StateContext<BotsStateModel>, { payload }: LoadBotsSuccess) {
    setState({ ...getState(), ...payload, errorDeleteBot: null, loaded: true });
  }

  @Action(SelectBot)
  selectBot({ getState, setState }: StateContext<BotsStateModel>, { payload }: SelectBot) {
    setState({ ...getState(), selectedBotId: payload, errorDeleteBot: null });
  }

  @Action(DeleteBot)
  deleteBot({ setState, getState, dispatch }: StateContext<BotsStateModel>, { payload }: DeleteBot) {
    setState({ ...getState(), loading: true });

    return this.botsApi.deleteBot(payload).pipe(
      tap(
        () => dispatch(new DeleteBotSuccess($localize`:Bots state|notify @@botsState.createdSuccess:Бот успешно удален`)),
        error => dispatch(new DeleteBotFail(error))
      ),
      finalize(() => setState({ ...getState(), loading: false }))
    );
  }

  @Action(DeleteBotSuccess)
  deleteBotSuccess({ getState, setState }: StateContext<BotsStateModel>, { payload }: DeleteBotSuccess) {
    setState({ ...getState(), errorDeleteBot: null, loaded: true });
  }

  @Action(DeleteBotFail)
  deleteBotFail({ getState, setState }: StateContext<BotsStateModel>, { payload }: DeleteBotFail) {
    setState({ ...getState(), errorDeleteBot: payload, loaded: true });
  }

  @Action(SetBotWebhook)
  setBotWebhook({ setState, getState, dispatch }: StateContext<BotsStateModel>, { payload }: SetBotWebhook) {
    setState({ ...getState(), loading: true });

    return this.botsApi.setBotWebhook(payload).pipe(
      tap(
        () => dispatch(new SetBotWebhookSuccess($localize`:Bots state|notify @@botsState.webhookSuccess:Webhook успешно установлен`)),
        error => dispatch(new SetBotWebhookFail(error))
      ),
      finalize(() => setState({ ...getState(), loading: false }))
    );
  }

  @Action(SetBotWebhookSuccess)
  setBotWebhookSuccess({ getState, setState }: StateContext<BotsStateModel>, { payload }: SetBotWebhookSuccess) {
    setState({ ...getState(), errorDeleteBot: null, loaded: true });
  }

  @Action(SetBotWebhookFail)
  setBotWebhookFail({ getState, setState }: StateContext<BotsStateModel>, { payload }: SetBotWebhookFail) {
    setState({ ...getState(), errorDeleteBot: null, loaded: true });
  }
}
