import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'tg-select-lang-dialog',
  templateUrl: './select-lang-dialog.component.html',
  styleUrls: ['./select-lang-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLangDialogComponent implements OnInit {
  links = ['en', 'ru', 'uk'];

  names = {
    en: 'English',
    ru: 'Русский',
    uk: 'Українська',
  };

  constructor() {}

  ngOnInit(): void {}

  isSelected(link: string) {
    return location.pathname.startsWith('/' + link);
  }
}
