import { interval, concat } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class CheckForUpdateService {
  constructor(private updates: SwUpdate, private snack: MatSnackBar, private appRef: ApplicationRef) {}

  pollUpdates() {
    console.log('CheckForUpdateService start');
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    // 2 minutes
    const everyTwoMinutes$ = interval(2 * 60 * 1000);
    const everyTwoMinutesOnceAppIsStable$ = concat(appIsStable$, everyTwoMinutes$);

    everyTwoMinutesOnceAppIsStable$.pipe(untilDestroyed(this)).subscribe(() => {
      console.log('CheckForUpdate start');
      this.updates
        .checkForUpdate()
        .then(() => console.log('CheckForUpdate done', new Date()))
        .catch(e => console.log('CheckForUpdate err', e));
    });

    this.updates.available.pipe(untilDestroyed(this)).subscribe(event => {
      this.snack
        .open('Доступна новая версия', 'Обновить', {duration: null})
        .onAction()
        .subscribe(() => {
          this.updates.activateUpdate().then(() => document.location.reload());
        });

      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });

    this.updates.activated.pipe(untilDestroyed(this)).subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });
  }
}
