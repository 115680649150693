import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { PostsCrudService } from '../../../api';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FullScreenDialogService } from '../../../shared/full-screen-dialog.service';

@Component({
  selector: 'tg-hidden-text-clicks',
  templateUrl: './hidden-text-clicks-dialog.component.html',
  styleUrls: ['./hidden-text-clicks-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenTextClicksDialogComponent implements OnInit {
  loading = true;

  stats: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { postId: string; channelId: string; buttons: any },
    private api: PostsCrudService,
    private cd: ChangeDetectorRef,
    public full: FullScreenDialogService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.api.getHiddenTextClicks(this.data.postId).subscribe(rs => {
      this.stats = rs;
      this.loading = false;
      this.cd.detectChanges();
    });
  }
}
