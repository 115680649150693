import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FileResponse } from '../../../../api';

@Component({
  selector: 'tg-document-container',
  templateUrl: './document-container.component.html',
  styleUrls: ['./document-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentContainerComponent implements OnInit {
  @Input() file: FileResponse;

  constructor() {

  }

  ngOnInit(): void {
    console.log(this.file);
  }
}
