import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscribable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'tg-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DialogFormComponent implements OnInit {
  saving = false;

  errorSave;

  @Input()
  dialogTitle;
  @Input()
  typeDialog = 'addBot';
  @Input()
  closeAfterSave = true;

  @Input()
  form: FormGroup;
  @Input()
  saveFn: (...args: any[]) => Subscribable<any>;
  @Output()
  save = new Subject();
  @Output()
  saved = new Subject();
  @Output()
  close = new Subject();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  submit() {
    if (this.saveFn) {
      delete this.errorSave;
      this.saving = true;
      this.saveFn(this.form).subscribe(
        rs => {
          this.saving = false;
          this.saved.next(rs);
          this.cd.detectChanges();
          if (this.closeAfterSave) {
            this.close.next();
          }
        },
        (err: HttpErrorResponse) => {
          this.errorSave = err;
          this.saving = false;
          this.cd.detectChanges();
        }
      );
    } else {
      this.save.next(this.form.value);
    }
  }
}
