<tg-dialog-form dialogTitle="Подключение канала"
                i18n-dialogTitle="Create channel dialog|title @@createChannelDialog.title"
                #channelCreateDialog [form]="channelForm"
                [typeDialog]="(bots$|async)?.count > 1 ? 'addChannel' : 'addBot'"
                [saveFn]="saveChannel()" (saved)="channelCreated($event)">
  <form [formGroup]="channelForm">
    <mat-vertical-stepper [linear]="true" *ngIf="(bots$|async)?.count > 1" (selectionChange)="onChange($event)">
      <mat-step [stepControl]="channelForm.get('botId')" id="selectBotStepper">
        <ng-template matStepLabel>
          <ng-container i18n="Create channel dialog|label @@createChannelDialog.selectBotLabel">Выберите бот
          </ng-container>
        </ng-template>
        <div class="bot-block">
          <p i18n="Create channel dialog|text @@createChannelDialog.selectBotHint">
            Сначала выберите бот, с помощью которого будет осуществляться взаимодействие с каналом.
          </p>

          <mat-form-field style="width: 100%">
            <mat-select placeholder="Ваш бот"
                        i18n-placeholder="Create channel dialog|placeholder @@createChannelDialog.botPlaceholder"
                        formControlName="botId" required>
              <mat-option *ngFor="let bot of (bots$|async)?.items" [value]="bot.id">
                @{{bot.username}} - {{bot.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <button id="nextStepperChanelBtn" mat-raised-button matStepperNext color="primary"
                  i18n="Create channel dialog|button @@createChannelDialog.nextButton">Дальше
          </button>
        </div>
      </mat-step>
      <mat-step [stepControl]="channelForm.get('username')">
        <ng-template matStepLabel>
          <ng-container i18n="Create channel dialog|label @@createChannelDialog.idLabel">Введите адрес или ID канала
          </ng-container>
        </ng-template>
        <ng-container [ngTemplateOutlet]="channelUsername"></ng-container>
      </mat-step>
    </mat-vertical-stepper>

    <div *ngIf="(bots$|async)?.count === 1" [ngTemplateOutlet]="channelUsername"></div>

    <ng-template #channelUsername>
      <p i18n="Create channel dialog|text @@createChannelDialog.addBotInstuct">
        1. Добавьте бот <a href="https://t.me/{{(selectedBot$|async)?.username}}"
                           target="_blank">@{{(selectedBot$|async)?.username}}</a> в админы канала и предоставьте ему
        права на управление постами.<br>
      </p>
      <p i18n="Create channel dialog|text @@createChannelDialog.addBotInstuct2">
        2. Введите короткий адрес или ID канала в поле ниже. <br>
      </p>
      <mat-form-field style="width: 100%; margin-bottom: 16px;">
        <input matInput #inputChannelUsername
               placeholder="username или ID канала"
               i18n-placeholder="Create channel dialog|placeholder @@createChannelDialog.idPlaceholder"
               type="text"
               autocomplete="false" formControlName="username" required>
        <mat-hint>Например: durov, https://t.me/durov или 10011426151</mat-hint>
        <mat-error *ngIf="channelForm.controls['username'].hasError('isClosedChannel')"
                   i18n="Create channel dialog|error @@createChannelDialog.closedError">Для закрытых каналов введите
          ID канала.
        </mat-error>
        <mat-error *ngIf="channelForm.controls['username'].hasError('isUserId')"
                   i18n="Create channel dialog|error @@createChannelDialog.userIdError">Похоже это ID пользователя.
          Введите ID канала.
        </mat-error>
        <mat-error *ngIf="channelForm.controls['username'].hasError('botUsername')"
                   i18n="Create channel dialog|error @@createChannelDialog.botUsernameError">Похоже это юзернейм бота.
          Введите юзернейм канала.
        </mat-error>
      </mat-form-field>
      <p class="mat-hint" style="font-size: 13px" i18n="Create channel dialog|hint @@createChannelDialog.hintLinked">
        Инструкция по подключению находится <a target="_blank"
                                               href="https://telepost.me/help/base/channel-create/">здесь</a>
      </p>
    </ng-template>
  </form>
</tg-dialog-form>
