import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdminService } from './api/admin.service';
import { AuthService } from './api/auth.service';
import { BotsService } from './api/bots.service';
import { ChannelAdminsService } from './api/channelAdmins.service';
import { ChannelCommentsService } from './api/channelComments.service';
import { ChannelStatsService } from './api/channelStats.service';
import { ChannelsService } from './api/channels.service';
import { FilesService } from './api/files.service';
import { PostsCrudService } from './api/postsCrud.service';
import { RecentService } from './api/recent.service';
import { SharedPostService } from './api/sharedPost.service';
import { UsersService } from './api/users.service';
import { UtilsService } from './api/utils.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdminService,
    AuthService,
    BotsService,
    ChannelAdminsService,
    ChannelCommentsService,
    ChannelStatsService,
    ChannelsService,
    FilesService,
    PostsCrudService,
    RecentService,
    SharedPostService,
    UsersService,
    UtilsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
