/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthResult } from '../model/authResult';
import { LoginWithTgDto } from '../model/loginWithTgDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Authorize user with Telegram login widget
     * 
     * @param LoginWithTgDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginWithTg(LoginWithTgDto: LoginWithTgDto, observe?: 'body', reportProgress?: boolean): Observable<AuthResult>;
    public loginWithTg(LoginWithTgDto: LoginWithTgDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthResult>>;
    public loginWithTg(LoginWithTgDto: LoginWithTgDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthResult>>;
    public loginWithTg(LoginWithTgDto: LoginWithTgDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (LoginWithTgDto === null || LoginWithTgDto === undefined) {
            throw new Error('Required parameter LoginWithTgDto was null or undefined when calling loginWithTg.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AuthResult>(`${this.basePath}/auth/with-tg`,
            LoginWithTgDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Authorize user with Telegram login widget
     * 
     * @param id 
     * @param first_name 
     * @param auth_date 
     * @param hash 
     * @param last_name 
     * @param username 
     * @param photo_url 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginWithTgGet(id: number, first_name: string, auth_date: number, hash: string, last_name?: string, username?: string, photo_url?: string, observe?: 'body', reportProgress?: boolean): Observable<AuthResult>;
    public loginWithTgGet(id: number, first_name: string, auth_date: number, hash: string, last_name?: string, username?: string, photo_url?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthResult>>;
    public loginWithTgGet(id: number, first_name: string, auth_date: number, hash: string, last_name?: string, username?: string, photo_url?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthResult>>;
    public loginWithTgGet(id: number, first_name: string, auth_date: number, hash: string, last_name?: string, username?: string, photo_url?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling loginWithTgGet.');
        }

        if (first_name === null || first_name === undefined) {
            throw new Error('Required parameter first_name was null or undefined when calling loginWithTgGet.');
        }

        if (auth_date === null || auth_date === undefined) {
            throw new Error('Required parameter auth_date was null or undefined when calling loginWithTgGet.');
        }

        if (hash === null || hash === undefined) {
            throw new Error('Required parameter hash was null or undefined when calling loginWithTgGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (first_name !== undefined && first_name !== null) {
            queryParameters = queryParameters.set('first_name', <any>first_name);
        }
        if (last_name !== undefined && last_name !== null) {
            queryParameters = queryParameters.set('last_name', <any>last_name);
        }
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }
        if (photo_url !== undefined && photo_url !== null) {
            queryParameters = queryParameters.set('photo_url', <any>photo_url);
        }
        if (auth_date !== undefined && auth_date !== null) {
            queryParameters = queryParameters.set('auth_date', <any>auth_date);
        }
        if (hash !== undefined && hash !== null) {
            queryParameters = queryParameters.set('hash', <any>hash);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AuthResult>(`${this.basePath}/auth/with-tg`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
