<div class="video-preview" [class.round]="msg.type === types.VideoNote">

  <ng-container *ngIf="msg.source === 'web' || file.size <= 20 * 1024 * 1024">
    <img src="{{file.posterUrl}}" *ngIf="file.posterUrl" class="poster">
    <!--    <video-->
    <!--      mat-card-image-->
    <!--      [src]="fileUrl"-->
    <!--      preload="metadata"-->
    <!--      [poster]="file.video.poster"-->
    <!--      *ngIf="file.video.poster"-->
    <!--    ></video>-->
    <video mat-card-image [src]="file.publicUrl" preload="metadata" *ngIf="!file.posterUrl"></video>
    <a [href]="file.publicUrl" target="_blank">
      <mat-icon class="play-mark">play_circle_outline</mat-icon>
    </a>
  </ng-container>
  <ng-container *ngIf="msg.source === 'bot' && file.size >= 20 * 1024 * 1024">
    <h2 i18n="Post message item|error @@postMessageItem.largeFileError">Файл слишком большой</h2>
  </ng-container>
</div>
