import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { BottomSheetComponent } from './bottom-sheet.component';
import { OpenBottomSheetService } from './open-bottom-sheet.service';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [BottomSheetComponent],
  exports: [BottomSheetComponent],
  entryComponents: [BottomSheetComponent],
  providers: [OpenBottomSheetService],
})
export class BottomSheetModule {}
