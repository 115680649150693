<tg-top-nav style="position: relative"></tg-top-nav>

<div class="container" style="min-height: calc(100vh - 350px); max-width: 500px; margin: 16px auto 0;">
  <h2 i18n="Shared post page|title @@sharedPostPage.title">Доступ к посту по ссылке</h2>
  <ng-container *ngIf="post">
<!--    todo-->
    <tg-post-view-container [post]="post"></tg-post-view-container>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button color="secondary" (click)="copyPost()">
        <mat-icon>content_copy</mat-icon>
        <ng-container i18n="Shared post page|button @@sharedPostPage.makeCopyButton">Создать копию</ng-container>
      </button>
    </div>
  </ng-container>
  <tg-inline-loader *ngIf="loading"></tg-inline-loader>
  <tg-error-block *ngIf="err" [error]="err"></tg-error-block>
</div>
