import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TpPreloadStrategy } from './core/tp-preload.strategy';
import { UserResolver } from './auth/user.resolver';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { LoginWithTokenComponent } from './pages/login-with-token/login-with-token.component';
import { SharedPostComponent } from './pages/shared-post/shared-post.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { PostsCalendarComponent } from './pages/posts-calendar/posts-calendar.component';
import { ChannelsResolver } from './pages/channels.resolver';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'login-with-token', component: LoginWithTokenComponent },
  { path: 'settings', resolve: { config: UserResolver }, component: UserSettingsComponent },
  // todo: comment on prod
  // { path: 'debug-stand', component: DebugStandComponent },
  // {
  //   path: 'access-token-extension',
  //   component: AccessExtensionComponent,
  //   canActivate: [AuthGuard],
  //   data: { title: 'Подключить расширение' },
  // },
  {
    path: 'sp/:postId/:shareCode',
    resolve: { config: UserResolver },
    component: SharedPostComponent,
  },
  {
    path: 'admins',
    resolve: { config: UserResolver },
    loadChildren: () => import('./site-admin/site-admin.module').then(m => m.SiteAdminModule),
  },
  {
    path: 'bots',
    resolve: { config: UserResolver },
    loadChildren: () => import('./bots/bots.module').then(m => m.BotsModule),
  },
  {
    path: 'channel',
    resolve: { config: UserResolver },
    loadChildren: () => import('./channel/channel.module').then(m => m.ChannelModule),
  },
  {
    path: 'post-form',
    resolve: { config: UserResolver, channels: ChannelsResolver },
    loadChildren: () => import('./post-form/post-form.module').then(m => m.PostFormModule),
  },
  {
    path: 'posts-calendar',
    resolve: { config1: UserResolver, channels: ChannelsResolver },
    component: PostsCalendarComponent,
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    // DebugModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: TpPreloadStrategy,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [TpPreloadStrategy, UserResolver],
  declarations: [],
})
export class AppRoutingModule {}
