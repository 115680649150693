<ng-container>
  <ng-template #refH1><ng-content select=".title"></ng-content></ng-template>
  <mat-toolbar color="primary" fxLayout="row" class="mat-elevation-z1" [class.fixedHeader]="fixedHeader" *ngIf="useFullScreen">
    <div fxFlex>
      <ng-container [ngTemplateOutlet]="refH1"></ng-container>
    </div>
    <div>
      <button mat-icon-button mat-dialog-close tabindex="-1"><mat-icon>close</mat-icon></button>
    </div>
  </mat-toolbar>
  <div *ngIf="!useFullScreen" fxLayout="row">
    <div fxFlex>
      <ng-container [ngTemplateOutlet]="refH1"></ng-container>
    </div>
    <div>
      <button mat-icon-button mat-dialog-close tabindex="-1"><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <mat-dialog-content [class.mobile]="useFullScreen" [class.fixedHeader]="fixedHeader">
    <ng-content></ng-content>
  </mat-dialog-content>
</ng-container>

