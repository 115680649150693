import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private snack: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(
        () => {},
        (err: HttpErrorResponse) => {
          if (err.error && err.error.message && err.error.displayMessage) {
            return this.snack.open(err.error.message, 'ok', { duration: null });
          }
          if (err.status >= 500) {
            if (err.error && err.error.message && err.error.displayMessage) {
              return this.snack.open(err.error.message, 'ok', { duration: null });
            }
            this.snack.open(
              $localize`:Server error service|notify @@serverErrorService.serverError:Упс, произошла ошибка, мы уже работаем над ее устранением.`,
              'ok',
              { duration: 10000 }
            );
          }
        }
      )
    );
  }
}
