<div class="container">
  <div fxLayout="row" fxLayout.xs="column">
    <div style="padding: 0 16px 8px 0px;">
      <img src="assets/telepost.me.svg" alt="Telepost Logo" width="128px" />
    </div>
    <div>
      <p class="mat-secondary" i18n="Footer|text @@footer.findError">Есть вопрос, нашли ошибку.</p>
      <div fxLayout="row" class="mat-secondary">
        <div fxFlex="100px"><strong>Telegram</strong></div>
        <div><a href="https://t.me/TelepostSupport">@TelepostSupport</a></div>
      </div>
      <div fxLayout="row" class="mat-secondary">
        <div fxFlex="100px"><strong>Email</strong></div>
        <div><a href="mail://telepost.me@gmail.com">telepost.me@gmail.com</a></div>
      </div>
      <div fxLayout="row" class="mat-secondary">
        <div fxFlex="100px"><strong i18n="Footer|text @@footer.chat">Чат</strong></div>
        <div><a href="https://t.me/joinchat/Ypg01CdfpW5jNWFi">Ypg01CdfpW5jNWFi</a></div>
      </div>
    </div>
  </div>
</div>
