import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

export interface BottomSheetData {
  button: string;
  act: 'showChannelAdmin' | 'deleteAdmin' | 'sendPostDraft' | 'sendPostNow' | 'sendPostBot';
  icon: string;
  color: string;
  disabled: boolean;
  visible: boolean;
}

@Component({
  selector: 'tg-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetComponent implements OnInit {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetData[],
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>
  ) {}

  ngOnInit() {}

  handleClick(eventAction: { event: MouseEvent; action: string }) {
    eventAction.event.preventDefault();
    this.bottomSheetRef.dismiss(eventAction.action);
  }
}
