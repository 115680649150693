<div *ngIf="countries; else loading" [formGroup]="form" style="margin: 0; padding: 0px; max-width: 600px">
  <tg-error-block *ngIf="lastError" [error]="lastError" [closebtn]="true"></tg-error-block>
  <mat-form-field style="width: 200px; font-size: 16px">
    <input
      type="text"
      matInput
      placeholder="Timezone"
      formControlName="timeZone"
      [matAutocomplete]="autoGroup"
    />
    <mat-autocomplete #autoGroup="matAutocomplete">
      <mat-option value="">-- None --</mat-option>
      <mat-optgroup *ngFor="let group of countries$ | async" [label]="group.id + ' - ' + group.name">
        <mat-option *ngFor="let zone of group.timezones" [value]="zone">
          {{ zone }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
  <div i18n="Timezone settings|text @@timezoneSettings.nowTimeLabel">Сейчас: {{dateTimeNow}}</div>
  <div class="actions">
    <button
      aria-label="save timezone"
      mat-raised-button
      [disabled]="form.pristine || form.invalid"
      color="primary"
      (click)="save()"
      i18n="Timezone settings|button @@timezoneSettings.saveButton"
    >
      Сохранить
    </button>
  </div>
</div>
<ng-template #loading>
  <tg-inline-loader></tg-inline-loader>
</ng-template>
