import { HttpErrorResponse } from '@angular/common/http';
import {
  CreatePostV3Dto,
  MessageResponse,
  PostResponse,
  PostsListResponse,
  UpdateMessageDto,
  UpdatePostV3Dto,
} from '../api';

export const LS_SAVE_KEY = 'multi_post_form_data_v2';

export class OpenCreateForm {
  static readonly type = '[PostFormState] OpenCreateForm';
  constructor(public readonly channels: string[]) {}
}

export class OpenEditForm {
  static readonly type = '[PostFormState] OpenEditForm';

  constructor(public readonly payload: PostResponse) {}
}

export class SetEditPost {
  static readonly type = '[PostFormState] SetEditPost';

  constructor(public readonly payload: PostResponse) {}
}

export class CreatePost {
  static readonly type = '[PostFormState] CreatePost';

  constructor(public readonly payload: CreatePostV3Dto) {}
}

export class CreatePostSuccess {
  static readonly type = '[PostFormState] CreatePostSuccess';

  constructor(public readonly payload: PostResponse) {}
}

export class UpdatePost {
  static readonly type = '[PostFormState] UpdatePost';

  constructor(public readonly payload: UpdatePostV3Dto) {}
}

export class UpdatePostSuccess {
  static readonly type = '[PostFormState] UpdatePostSuccess';

  constructor(public readonly payload: PostResponse) {}
}

export class SavePostError {
  static readonly type = '[PostFormState] SavePostError';

  constructor(public readonly payload: HttpErrorResponse) {}
}

export class SendToBot {
  static readonly type = '[PostFormState] SendToBot';

  constructor(public readonly payload: CreatePostV3Dto) {}
}

export class RestoreValues {
  static readonly type = '[PostFormState] RestoreValues';

  constructor(public readonly payload: any) {}
}

export class OpenEditMessageForm {
  static readonly type = '[PostFormState] OpenEditMessageForm';

  constructor(public readonly payload: { msg: MessageResponse; post: PostResponse }) {}
}

export class UpdatePublishedMessage {
  static readonly type = '[PostFormState] UpdatePublishedMessage';
  constructor(public readonly payload: UpdateMessageDto) {}
}

export class SetChannels {
  static readonly type = '[PostFormState] SetChannels';
  constructor(public readonly channels: string[]) {}
}
