<tg-dialog-form dialogTitle="Подключение бота"
                i18n-dialogTitle="Create bot dialog|title @@createBotDialog.title"
                [form]="token" [saveFn]="saveToken()"
                (saved)="botCreated($event)">
  <form [formGroup]="token">
    <p i18n="Create bot dialog|text @@createBotDialog.desc">
      Взаимодествие с каналами осуществляется с помощь вашего бота.<br>
    </p>
    <p i18n="Create bot dialog|text @@createBotDialog.instruct">
      Создайте бота с помощью <a href="https://t.me/BotFather">@BotFather</a>, и вставьте токен бота ниже.<br>
      <!--Подробная инструкция <a href="#">здесь</a>-->
    </p>
    <mat-form-field style="width: 100%">
      <input matInput placeholder="Токен бота: 1234567:dfDuEtNvpSOB11kRdaKdah"
             i18n-placeholder="Create bot dialog|placeholder @@createBotDialog.tokenPlaceholder"
             type="text" autocomplete="false"
             formControlName="token"
             [maxlength]="56"
             [minlength]="30" required pattern="\d{3,12}:[a-zA-Z\d_\-]{30,50}">
    </mat-form-field>

    <p class="mat-hint" i18n="Create bot dialog|hint @@createBotDialog.hint">
      Важно! Не подключай боты, которые уже используются другими сервисами, такими как ControllerBot, Chatfuel, Combot и
      т/д.
    </p>
    <p class="mat-hint" i18n="Create bot dialog|hint @@createBotDialog.hint2">Инструкция по подключению находится <a
      target="_blank"
      href="https://telepost.me/help/bots/add/">здесь</a></p>
  </form>
</tg-dialog-form>
