import { Injectable } from '@angular/core';
import { mapTo, retryWhen, switchMap, tap } from 'rxjs/operators';
import { fromEvent, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class OfflineInterceptor {
  constructor(private snack: MatSnackBar) {}

  private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));

  get isOnline() {
    return navigator.onLine;
  }

  intercept(req, next) {
    return next.handle(req).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        let snack = this.snack.open(
          $localize`:Offline service|notify @@offlineService.noConnect:Нет соединения с интернетом`,
          'ok'
        );

        return this.onlineChanges$.pipe(tap(() => snack.dismiss()));
      })
    );
  }
}
