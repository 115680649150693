import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InlineHiddenTextButton } from '../../api';

@Component({
  selector: 'tg-hidden-text-preview-dialog',
  templateUrl: './hidden-text-preview-dialog.component.html',
  styleUrls: ['./hidden-text-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenTextPreviewDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: InlineHiddenTextButton) {}

  ngOnInit(): void {}
}
