import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCalendar, MatMonthView } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ScheduleDialogData } from '../../lib/types';
import { DateTime } from 'luxon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export enum Steps {
  Date = 'date',
  Time = 'time',
}

@UntilDestroy()
@Component({
  selector: 'tg-date-time-picker-dialog-v2',
  templateUrl: './date-time-picker-dialog-v2.component.html',
  styleUrls: ['./date-time-picker-dialog-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimePickerDialogV2Component implements OnInit, AfterViewInit {
  hours = Array.from(Array(24).keys());
  minutes = Array.from(Array(60).keys());
  steps = Steps;
  step: Steps = Steps.Date;

  minDate = DateTime.local();
  // max date is now +3 months
  maxDate = DateTime.local().plus({ months: 6 });

  selectedDate: DateTime;

  _scheduleForm: FormGroup;

  @ViewChild(MatCalendar, { static: true })
  matCalendar: MatCalendar<any>;

  cachedTimes = this.getCachedTimes();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ScheduleDialogData,
    public cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<DateTimePickerDialogV2Component>
  ) {}

  ngOnInit() {
    let hour = null;
    let minutes = null;
    console.log('scheduleAt', this.data.scheduleAt);
    if (this.data.scheduleAt) {
      let dateTime = this.data.scheduleAt;
      hour = dateTime.get('hour');
      minutes = dateTime.get('minute');
      this.selectedDate = dateTime;
    }
    this._scheduleForm = new FormGroup(
      {
        hour: new FormControl(hour, Validators.required),
        minutes: new FormControl(minutes, Validators.required),
      },
      (control: AbstractControl) => {
        return this.validateDateAndTime(control);
      }
    );
  }

  ngAfterViewInit(): void {
    // жосткий костыль, при клику на ту же дату переходим к выбору времени
    let monthView = this.matCalendar.monthView as MatMonthView<DateTime>;

    monthView._userSelection.pipe(untilDestroyed(this)).subscribe(({ value }: { value: DateTime }) => {
      if (
        monthView.selected instanceof DateTime &&
        monthView.selected.day === value.day &&
        monthView.selected.month === value.month &&
        monthView.selected.year === value.year
      ) {
        this.setTimeView();
      }
    });
  }

  setDateView() {
    this.step = Steps.Date;
  }

  setTimeView() {
    this.step = Steps.Time;
  }

  createDateTimeObject() {
    let { hour, minutes } = this._scheduleForm.value;
    let date = this.selectedDate;
    let dateTime = DateTime.local();
    let selected = dateTime.set({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: hour,
      minute: minutes,
    });
    return selected;
  }

  validateDateAndTime(control: AbstractControl) {
    if (!this._scheduleForm) {
      return null;
    }
    let { hour, minutes } = control.value;
    if (hour === null || hour === undefined) {
      return null;
    }
    if (minutes === null || minutes === undefined) {
      return null;
    }
    let selected = this.createDateTimeObject();
    let now = DateTime.local();
    if (now > selected) {
      return { lessNow: true };
    }
    return null;
  }

  selectDate(e) {
    console.log('Select', e);
    this.selectedDate = e;
    this.step = Steps.Time;
  }

  setToday() {
    let now = DateTime.local();
    this.selectDate(now);
  }

  setTomorrow() {
    let now = DateTime.local();
    this.selectDate(now.plus({ days: 1 }));
  }

  clearSelectedDateTime() {
    this.dialogRef.close(null);
  }

  saveResult() {
    this.cacheTimes();
    this.dialogRef.close(this.createDateTimeObject());
  }

  cacheTimes() {
    let key = '_schedule_times';
    let { hour, minutes } = this._scheduleForm.value;
    let time = `${hour}:${minutes}`;
    if (!this.cachedTimes.includes(time)) {
      this.cachedTimes.push(time);
      localStorage.setItem(key, this.cachedTimes.slice(-10).join('|'));
    }
  }

  getCachedTimes() {
    let key = '_schedule_times';
    let old = localStorage.getItem(key);
    let oldArr = [];
    if (old !== null) {
      oldArr = old.split('|');
    }
    return oldArr.sort((a, b) => {
      return Number(a.split(':').join('')) - Number(b.split(':').join(''));
    });
  }

  delCached(time: string) {
    let key = '_schedule_times';
    this.cachedTimes = this.cachedTimes.filter(s => s !== time)
    localStorage.setItem(key, this.cachedTimes.join('|'));
  }

  /**
   *
   * @param time like 5:19
   */
  setTime(time: string) {
    let [h, m] = time.split(':');
    this._scheduleForm.patchValue({
      hour: Number(h),
      minutes: Number(m),
    });
  }

  /**
   *
   * @param time like 4:8
   * @return time with pad 0: 04:08
   */
  padStart(time: string): string {
    return time
      .split(':')
      .map(t => t.padStart(2, '0'))
      .join(':');
  }
}
