import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginService } from './login.service';
import { AuthGuard } from './auth.guard';
import { NgxsModule } from '@ngxs/store';
import { UserState } from './user.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([UserState])],
  declarations: [],
  providers: [LoginService, { provide: AuthGuard, useClass: AuthGuard }],
})
export class AuthModule {}
