import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DialogCreatesService } from '../../shared/dialog-creates.service';
import { Select, Store } from '@ngxs/store';
import { ChannelsState, ChannelsStateModel, LoadChannels } from '../../state/channels.state';
import { LoadBots } from '../../state/bots.state';
import { delay, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadUserInfo } from '../../auth/auth.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Select(ChannelsState)
  channels$: Observable<ChannelsStateModel>;

  constructor(
    private toastr: ToastrService,
    private dialodCreate: DialogCreatesService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit() {
    this.store.dispatch([new LoadChannels(), new LoadBots(), new LoadUserInfo()]);
  }

  botCreated(message) {
    this.toastr.success(message);
    this.store.dispatch(new LoadChannels());
    this.store
      .dispatch(new LoadBots())
      .pipe(untilDestroyed(this), delay(1))
      .subscribe(() => this.openAddChannelForm());
  }

  channelCreated(message) {
    this.toastr.success(message);
    this.store.dispatch(new LoadChannels());
  }

  openAddChannelForm() {
    const botsCount = this.store.selectSnapshot(state => state.bots.count);

    if (botsCount === 0) {
      this.openCreateBotDialog();
      return;
    }
    this.openCreateChannelDialog();
  }

  openCreateBotDialog() {
    this.dialodCreate
      .openCreateBot()
      .afterClosed()
      .pipe(filter(rs => rs))
      .subscribe(rs => this.botCreated(rs.msg));
  }

  openCreateChannelDialog() {
    this.dialodCreate
      .openCreateChannel()
      .afterClosed()
      .pipe(filter(rs => rs))
      .subscribe(addedChannel => {
        this.channelCreated(addedChannel.msg);
        this.router.navigateByUrl(`channel/${addedChannel.res.id}`);
      });
  }
}
