<div class="attach-doc">
  <mat-icon class="attach-doc-mark" *ngIf="!file.posterUrl"
  >play_circle_filled
  </mat-icon>
  <img
    *ngIf="file.posterUrl"
    class="attach-doc-mark"
    [src]="file.posterUrl"
    style="margin-right: 10px"
    [alt]="file.filename"
  />
  <div fxLayout="column">
      <span class="file-name" title="{{ file.audio?.performer }}">
        <a [href]="file.publicUrl" target="_blank">{{ file.audio?.performer }} - {{ file.audio?.title }}</a>
      </span>
    <span class="size">{{ file.size | bytesToSize }}, {{ file.audio?.duration | duration }}</span>
  </div>
</div>
