import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostResponse } from '../../api';
import { OpenEditForm, OpenEditMessageForm } from '../../post-form/post-from.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'tg-day-posts-list-dialog',
  templateUrl: './day-posts-list-dialog.component.html',
  styleUrls: ['./day-posts-list-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayPostsListDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public posts: PostResponse[], private store: Store) {}

  ngOnInit() {}

  editPost(post) {
    this.store.dispatch(new OpenEditForm(post));
  }

  editPostMsg(msg) {
    this.store.dispatch(new OpenEditMessageForm(msg));
  }
}
