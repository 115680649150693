<mat-chip-list>
  <mat-chip *ngIf="msg.tasks?.repost" style="font-size: 11px"
            i18n="Post message item|label @@postMessageItem.repostChip">Репост: {{msg.tasks.repost.url}}</mat-chip>
  <mat-chip *ngIf="msg.tasks?.reply" style="font-size: 11px"
            i18n="Post message item|label @@postMessageItem.replayChip">Ответ: {{msg.tasks.reply.url}}</mat-chip>
</mat-chip-list>

<div class="image-wrap">
  <img *ngIf="msg.type === types.Photo" [src]="msg.file?.posterUrl" class="poster" />
</div>


<div *ngIf="msg.type === types.Animation" class="video-preview">
  <video mat-card-image [src]="msg.file.publicUrl" preload="auto" controls loop></video>
</div>

<ng-container *ngIf="msg.type === types.MediaGroup">
  <div style="margin-bottom: 8px">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="grid"
         *ngIf="!msg.mediaGroupType || msg.mediaGroupType==='visual'">
      <div *ngFor="let file of msg.files" fxFlex="50">
        <div>
          <img [src]="file.posterUrl" [alt]="file.filename" />
        </div>
      </div>
    </div>
    <div *ngIf="msg.mediaGroupType && msg.mediaGroupType === 'audio'">
      <div *ngFor="let file of msg.files">
        <tg-audio-container [file]="file" *ngIf="file.kind === 'audio'"></tg-audio-container>
        <tg-voice-container [file]="file" *ngIf="file.kind === 'voice'"></tg-voice-container>
      </div>
    </div>
    <div *ngIf="msg.mediaGroupType && msg.mediaGroupType === 'docs'">
      <div *ngFor="let file of msg.files">
        <tg-document-container [file]="file"></tg-document-container>
      </div>
    </div>
  </div>
</ng-container>

<tg-video-container *ngIf="msg.type === types.Video || msg.type === types.VideoNote" [msg]="msg"
                    [file]="msg.file"></tg-video-container>
<tg-document-container *ngIf="msg.type === types.Document" [file]="msg.file"></tg-document-container>
<tg-audio-container *ngIf="msg.type === types.Audio" [file]="msg.file"></tg-audio-container>
<tg-voice-container *ngIf="msg.type === types.Voice" [file]="msg.file"></tg-voice-container>

<ng-container *ngIf="msg.type === types.Sticker">
  <i>Sticker</i>
  <i *ngIf="isStickerAnimated"> Animated</i>
  <div class="sticker-wrap">
    <img [src]="msg.file.posterUrl" />
  </div>
</ng-container>

<p
  #textItem
  style="white-space: pre-line; word-wrap: break-word"
  [hidden]="!text"
  class="post-text"
  [class.all]="!collapseText"
  [class.noAll]="msg.inTextPhotoUrl"
  [innerHtml]="text | quillRender"
></p>
<div class="show-all" *ngIf="collapseText" (click)="collapseText = !collapseText"
     i18n="Post message item|button @@postMessageItem.showAllText">Показать текст полностью ↓
</div>

<ng-container *ngIf="msg.inTextPhotoUrl">
  <div class="text-image" fxLayout="row" fxLayoutAlign="start stretch">
    <div class="before-line"></div>
    <img *ngIf="attachedPhotoFile" class="in-text-photo" [src]="msg.inTextPhotoUrl | imageProxy" />
    <video *ngIf="!attachedPhotoFile" class="in-text-photo" [src]="msg.inTextPhotoUrl | imageProxy"></video>
  </div>
</ng-container>

<ng-container *ngIf="msg.keyboard">
  <ng-container *ngFor="let item of msg.keyboard; let i = index">
    <!--Links row Button-->
    <ng-container *ngIf="item && item.type === 'links-row'">
      <div
        class="links-block"
        fxLayout="row"
        fxLayoutGap="8px"
        [style.marginTop.px]="msg.inTextPhotoUrl || i > 0 ? 8 : 0"
      >
        <a
          class="link-button"
          *ngFor="let link of item.links"
          [href]="link.url"
          title="{{ link.text }}"
          target="_blank"
        >
          <span [innerHtml]="link.text | emojiInText"></span>
          <mat-icon class="right-icon">call_made</mat-icon>
        </a>
      </div>
    </ng-container>
    <!--END Link Button-->
    <!--Reaction Button-->
    <ng-container *ngIf="item && item.type === 'reaction'">
      <div class="reaction-block" fxLayout="row" fxLayoutGap="8px" [style.marginTop.px]="i > 0 ? 8 : 0">
        <ng-container *ngFor="let reaction of item.reactions">
          <div class="reaction-item" [innerHtml]="reaction.text + ' ' + (reaction.count || '') | emojiInText"></div>
        </ng-container>
      </div>
    </ng-container>
    <!--END Reaction Button-->
    <ng-container *ngIf="item && item.type === 'hidden-text'">
      <div class="reaction-block" fxLayout="row" fxLayoutGap="8px" [style.marginTop.px]="i > 0 ? 8 : 0">
        <ng-container *ngFor="let reaction of item.hiddenTextButtons">
          <div class="reaction-item" style="cursor: pointer" (click)="openPreviewHiddenText(reaction)">
            {{reaction.buttonText}}
            <mat-icon class="right-icon">read_more</mat-icon>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="msg.answers">
  <span
    *ngFor="let item of answersWithVotes; let i = index"
    class="poll-button"
    [style.marginTop.px]="msg.inTextPhotoUrl || i > 0 ? 8 : 0"
  >
    <span [innerHtml]="item | emojiInText"></span>
    <mat-icon class="poll-icon">bar_chart</mat-icon>
  </span>
</ng-container>
