import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FileResponse } from '../../../../api';

@Component({
  selector: 'tg-voice-container',
  templateUrl: './voice-container.component.html',
  styleUrls: ['./voice-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoiceContainerComponent implements OnInit {
  @Input() file: FileResponse;

  constructor() {}

  ngOnInit(): void {}
}
