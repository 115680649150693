import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FileResponse } from '../../../../api';

@Component({
  selector: 'tg-audio-container',
  templateUrl: './audio-container.component.html',
  styleUrls: ['./audio-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioContainerComponent implements OnInit {
  @Input() file: FileResponse;
  constructor() {}

  ngOnInit(): void {}
}
