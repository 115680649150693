<div class="head" mat-dialog-title>
  <h2 i18n="Native date time picker|title @@nativeDateTimePicker.title">Выберите время</h2>
  <button class="close-btn" mat-icon-button tabindex="-1" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <input type="datetime-local" (change)="selectDate($event)" #inputElement />
  <button mat-button color="primary" i18n="Native date time picker|button @@nativeDateTimePicker.nowBtn">Сейчас</button>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null" i18n="Native date time picker|button @@nativeDateTimePicker.cancelBtn">
    Отмена
  </button>
  <button
    aria-label="save datetime native"
    mat-flat-button
    color="primary"
    [disabled]="_scheduleForm.invalid"
    (click)="saveResult()"
    i18n="Native date time picker|button @@nativeDateTimePicker.saveBtn"
  >
    Сохранить
  </button>
</mat-dialog-actions>
