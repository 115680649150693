import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostsCrudService } from '../../../api';
import { Chart } from 'highcharts';
import { FullScreenDialogService } from '../../../shared/full-screen-dialog.service';
import { configHighchart } from '../../../lib/highcharts';

const color = '#12A4ED';
const gradient = ['rgba(18, 164, 237, 0.4)', 'rgba(18, 164, 237, 0.05)'];

@Component({
  selector: 'tg-post-views-dialog',
  templateUrl: './post-views-dialog.component.html',
  styleUrls: ['./post-views-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostViewsDialogComponent implements OnInit, OnDestroy {
  loading = true;

  @ViewChild('container', { static: true }) container: ElementRef<HTMLDialogElement>;

  chart: Chart = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { postId: string; channelId: string },
    private api: PostsCrudService,
    private cd: ChangeDetectorRef,
    public full: FullScreenDialogService
  ) {}

  ngOnInit() {
    this.api.getViewsByHours(this.data.postId).subscribe(rs => this.draw(rs));
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  draw(data: any) {
    // console.log(data[this.data.channelId].all);

    let series = Object.entries(data[this.data.channelId].all).map(v => [Number(v[0]), Number(v[1])]);
    import('highcharts/highstock').then(highcharts => {
      configHighchart(highcharts);

      this.loading = false;
      this.container.nativeElement.style.height = '300px';
      this.cd.detectChanges();

      // console.log(this.container);
      // console.log(rs.());

      this.chart = highcharts.stockChart(this.container.nativeElement, {
        chart: {
          events: {
            load: (e: any) => {
              const rangeDate = `${highcharts.dateFormat(
                '%H %d %b %Y',
                e.target.xAxis[0].min
              )} - ${highcharts.dateFormat('%H %d %b %Y', e.target.xAxis[0].max)}`;

              console.log('rangeDate', rangeDate);
              // todo: show current range
              // document.querySelector(`#chart_${field}_${channelId}`)
              //   .previousElementSibling
              //   .querySelector('#range-date')
              //   .innerHTML = rangeDate;
            },
          },
          style: {
            fontFamily: `Montserrat, 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif`,
          },
        },
        rangeSelector: {
          enabled: false,
        },
        xAxis: {
          ordinal: false,
          // min: new Date(series[0][0] * 1000).getTime(),
          // max: new Date(series[series.length - 1][0] * 1000).getTime(),
          type: 'datetime',
          // labels: {
          //   format: '{value:%Y-%m-%d}'
          // }
          events: {
            setExtremes: e => {
              const rangeDate = `${highcharts.dateFormat('%d %b %Y', e.min)} - ${highcharts.dateFormat(
                '%d %b %Y',
                e.max
              )}`;
              // todo: show range
              // document.querySelector(`#chart_${field}_${channelId}`)
              //   .previousElementSibling
              //   .querySelector('#range-date')
              //   .innerHTML = rangeDate;
            },
          },
          labels: {
            align: 'center',
            // format: '',
          },
        },
        yAxis: {
          opposite: false,
          left: 30,
          labels: {
            align: 'left',
          },
          // min: minValue,
          startOnTick: false,
        },
        series: [
          {
            name: $localize`:Post views stats dialog|label @@postViewsStatsDialog.seriesViewsLabel:Просмотры`,
            data: series.map(item => {
              return [new Date(item[0] * 1000).getTime(), item[1]];
            }),
            type: 'areaspline',
            color: color,
            lineWidth: 2,
            tooltip: {
              valueDecimals: 0,
            },
            fillColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, gradient[0]],
                [1, gradient[1]],
              ],
            },
          },
        ],
        scrollbar: {
          enabled: true,
          liveRedraw: true,
          height: 0,
          buttonArrowColor: '#ffffff',
        },
        navigator: {
          enabled: false,
          // maskFill: 'rgba(60, 85, 165, 0.1)',
          // maskInside: false,
          // // outlineColor: 'rgba(115, 113, 115, 0.1)',
          // outlineWidth: 0,
          // height: 30,
          // margin: 12,
          // handles: {
          //   backgroundColor: '#C5D3E1',
          //   borderColor: '#ffffff',
          //   enabled: true,
          //   height: 38,
          //   lineWidth: 1,
          //   symbols: ['url(/assets/highchart/handles-left.svg)', 'url(/assets/highchart/handles-right.svg)'],
          //   width: 34,
          // },
          // xAxis: {
          //   labels: {
          //     enabled: false,
          //   },
          //   gridLineWidth: 0,
          // },
        },
        tooltip: {
          // pointFormat: '{series.name}: <b>{point.y}</b>',
          xDateFormat: '%d %B %H:00',
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          useHTML: true,
          split: false,
          distance: 8,
          pointFormat: `<span style="color:{point.color}">■</span> {series.name}: <b>{point.y}</b><br/>`,
        },
        credits: {
          enabled: false,
        },
      });
    });
  }
}
