import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ChannelsListComponent } from './channels-list/channels-list.component';
import { PopoverModule } from './popover/popover.module';
import { TooltipModule } from './tooltip/tooltip.module';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { DateTimePickerDialogV2Component } from './date-time-picker-dialog-v2/date-time-picker-dialog-v2.component';
import { ChannelListDialogComponent } from './channel-list-dialog/channel-list-dialog.component';
import { MenuGroupComponent } from './menu-group/menu-group.component';
import { NativeDateTimePikerComponent } from './native-date-time-piker/native-date-time-piker.component';
import { HiddenTextPreviewDialogComponent } from './hidden-text-preview-dialog/hidden-text-preview-dialog.component';
import { CronDialogComponent } from './cron-dialog/cron-dialog.component';
import { SelectLangDialogComponent } from './select-lang-dialog/select-lang-dialog.component';

@NgModule({
  imports: [CommonModule, SharedModule, TooltipModule, PopoverModule],
  declarations: [
    MenuGroupComponent,
    ChannelsListComponent,
    ScrollTopComponent,
    DateTimePickerDialogV2Component,
    ChannelListDialogComponent,
    NativeDateTimePikerComponent,
    HiddenTextPreviewDialogComponent,
    CronDialogComponent,
    SelectLangDialogComponent,
  ],
  exports: [
    ChannelsListComponent,
    ScrollTopComponent,
    TooltipModule,
    PopoverModule,
    MenuGroupComponent,
    CronDialogComponent,
  ],
  entryComponents: [DateTimePickerDialogV2Component, NativeDateTimePikerComponent, ChannelListDialogComponent],
})
export class ComponentsModule {}
