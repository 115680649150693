import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, Injectable, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import * as Sentry from "@sentry/browser";
import { environment } from "../environments/environment";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { ServiceWorkerModule } from "@angular/service-worker";
import { revision, version } from "../environments/version";
import { getClickPath } from "./shared/lib";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { PagesModule } from "./pages/pages.module";
import { NgxsModule, NoopNgxsExecutionStrategy } from "@ngxs/store";
import { BotsState } from "./state/bots.state";
import { ChannelsState } from "./state/channels.state";

// registerLocaleData(localeRu, 'ru');

let environmentName = 'dev';
let getSentryUrl = () => {
  if (location.host.startsWith('app.dev')) {
    return 'https://26afbcb773f5420eab9f6ee8afb47d57@sentry2.glab.pw/4';
  }
  if (location.host.startsWith('app.staging') || location.host.startsWith('app.stagging')) {
    environmentName = 'staging';
    return 'https://26afbcb773f5420eab9f6ee8afb47d57@sentry2.glab.pw/4';
  }
  if (location.host.startsWith('app.telepost')) {
    environmentName = 'prod';
    return 'https://26afbcb773f5420eab9f6ee8afb47d57@sentry2.glab.pw/4';
  }
  environmentName = 'local';
  return 'https://26afbcb773f5420eab9f6ee8afb47d57@sentry2.glab.pw/4';
};

Sentry.init({
  dsn: getSentryUrl(),
  release: version,
  maxBreadcrumbs: 150,
  enabled: environment.production,
  environment: environmentName,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click') {
      // console.log(breadcrumb, hint);
      const { target }: { target: HTMLElement } = hint.event;
      // log button name
      try {
        const msg = getClickPath(target);
        if (msg) {
          breadcrumb.message = msg;
        }
      } catch (e) {
        console.log(e);
      }
      // console.log(breadcrumb.message, {target});
    }
    return breadcrumb;
  },
});

Sentry.setTag('commit', revision);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      return window.location.reload();
    }

    console.error(error);
    Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    CoreModule,
    SharedModule,
    NgxsModule.forRoot([ChannelsState, BotsState], {
      executionStrategy: NoopNgxsExecutionStrategy,
    }),
    NgxsLoggerPluginModule.forRoot({
      /**
       *
       * Logger to implement. Defaults to console.
       */
      logger: console,

      /**
       * Collapse the log by default or not. Defaults to true.
       */
      collapsed: false,
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
