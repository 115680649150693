import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthHelper } from '../../auth/auth.helper';
import { LoginSuccess } from '../../auth/auth.actions';
import { AppRouterService } from '../../core/app-router.service';

@Component({
  selector: 'tg-login-with-token',
  templateUrl: './login-with-token.component.html',
  styleUrls: ['./login-with-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginWithTokenComponent implements OnInit {
  error = '';

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private appRouter: AppRouterService
  ) {
    console.log(this.route.snapshot.queryParams);
  }

  ngOnInit() {
    const { action, access_token, expires_in } = this.route.snapshot.queryParams;
    AuthHelper.saveAuthRes({ access_token, expires_in });
    this.store.dispatch(new LoginSuccess(AuthHelper.getState()));
    if (action === 'editPost') {
      const { channelId, postId } = this.route.snapshot.queryParams;
      setTimeout(() => {
        this.router.navigate(['channel', channelId, 'draft', { edit: postId }]);
      }, 100);
      return;
    }
    if (action === 'createPost') {
      const { channelId } = this.route.snapshot.queryParams;
      setTimeout(() => {
        this.router.navigate(['channel', channelId, 'draft']);
      }, 100);
      return;
    }
    if (action === 'scheduled') {
      const { channelId } = this.route.snapshot.queryParams;
      setTimeout(() => {
        this.router.navigate(['channel', channelId, 'queue']);
      }, 100);
      return;
    }

    if (action === 'sent') {
      const { channelId } = this.route.snapshot.queryParams;
      setTimeout(() => {
        this.router.navigate(['channel', channelId, 'sent']);
      }, 100);
      return;
    }

    if (action === 'channelStatistics') {
      const { channelId } = this.route.snapshot.queryParams;
      setTimeout(() => {
        this.router.navigate(['channel', channelId, 'statistics']);
      }, 100);
      return;
    }

    if (!action) {
      return this.appRouter.goToHomePage();
    }

    this.error = 'Unknown action: ' + action;
  }
}
