import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { LoginService } from '../../auth/login.service';
import { AppRouterService } from '../../core/app-router.service';
import { ActivatedRoute } from '@angular/router';
import { UserState } from '../../auth/user.state';
import { Actions, ofActionDispatched, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { first, map, tap } from 'rxjs/operators';
import { UserStateModel } from '../../auth/user.state.model';
import { LoginFail } from '../../auth/auth.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, AfterViewInit {
  isFeature = environment.isFeature;
  furl = 'https://app.dev.telepost.pw/api/login#redirect=' + document.location.host;

  @Select(UserState)
  user$: Observable<UserStateModel>;

  botUsername;

  @ViewChild('widgetLabel', { static: true })
  widgetLabel: ElementRef<HTMLDivElement>;

  constructor(
    private loginService: LoginService,
    private router: AppRouterService,
    private zone: NgZone,
    private route: ActivatedRoute,
    private actions$: Actions,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.actions$.pipe(untilDestroyed(this), ofActionDispatched(LoginFail)).subscribe(({ payload: err }) => {
      console.log(err);
      this.snackBar.open('Login fail: ' + (err.error.message ? err.error.message : err.message), 'ok', {
        duration: 5000,
      });
    });
    // redirect to home if user already authenticated
    //
    const qp = this.route.snapshot.queryParamMap;
    this.user$
      .pipe(
        untilDestroyed(this),
        map(u => u.isAuth),
        first(),
        tap(isAuth => {
          console.log('isAuth', isAuth);
          if (isAuth) {
            this.router.goToHomePage();
          } else if (qp.has('auth_date') && qp.has('hash')) {
            console.log('need login', !isAuth);
            this.login({
              ...this.route.snapshot.queryParams,
              id: parseInt(qp.get('id'), 10),
              auth_date: parseInt(qp.get('auth_date'), 10),
            });
          }
        })
      )
      .subscribe();
    this.botUsername = document.location.host.match(/stagging/) ? 'stagging_telepost_login_bot' : 'tg_login_test_bot';
    if (document.location.host === 'app.telepost.me') {
      this.botUsername = 'telepostbot';
    }
  }

  login(user) {
    this.loginService.login(user);
  }

  ngAfterViewInit() {
    const self = this;
    window['___TgOnLogin'] = function(user) {
      self.zone.run(() => self.login(user));
    };
    const el = document.createElement('script');
    el.setAttribute('src', 'https://telegram.org/js/telegram-widget.js?3');
    el.setAttribute('data-telegram-login', this.botUsername);
    el.setAttribute('data-size', 'large');
    // el.setAttribute('data-auth-url', 'http://local.telepost.me/auth/with-tg');
    el.setAttribute('data-onauth', '___TgOnLogin(user)');
    el.setAttribute('data-request-access', 'write');
    el.onload = () => {
      this.widgetLabel.nativeElement.style.display = 'block';
    };
    let container = document.getElementById('telegramLoginWidget');
    if (container) {
      container.appendChild(el);
    }
  }
}
