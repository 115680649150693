import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class FullScreenDialogService {
  constructor(private matDialog: MatDialog) {}

  get useFullScreen() {
    return window.matchMedia('screen and (max-width: 800px)').matches;
  }

  // open dialog full screen on mobile devices
  open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: MatDialogConfig<D> = {}
  ): MatDialogRef<T, R> {
    if (this.useFullScreen) {
      config.width = '100%';
      config.height = '100%';
      config.panelClass = 'mobile-full-screen-dialog';
      config.maxHeight = '100vh';
      config.maxWidth = '100%';
    }

    return this.matDialog.open(componentOrTemplateRef, config);
  }
}
