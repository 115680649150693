<div *ngIf="isAuth$ | async" style="height: 100%">

  <mat-menu #userTopMenu="matMenu"
            [overlapTrigger]="false" xPosition="before"
            class="user-top-menu">
    <a mat-menu-item routerLink="/channels">
      <mat-icon mat-list-icon>rss_feed</mat-icon>
      <ng-container i18n="Top menu|button @@topMenu.myChannels">Мои Каналы Telegram</ng-container>
    </a>
    <a mat-menu-item routerLink="/posts-calendar">
      <mat-icon mat-list-icon>date_range</mat-icon>
      <ng-container i18n="Top menu|button @@topMenu.publicationsCalendar">Календарь публикаций</ng-container>
    </a>
    <a mat-menu-item routerLink="/bots">
      <mat-icon mat-list-icon>android</mat-icon>
      <ng-container i18n="Top menu|button @@topMenu.myBots">Мои Боты</ng-container>
    </a>
    <a mat-menu-item routerLink="/settings">
      <mat-icon mat-list-icon>settings_applications</mat-icon>
      <ng-container i18n="Top menu|button @@topMenu.settings">Настройки</ng-container>
    </a>
    <a mat-menu-item (click)="changeLanguageDialog()">
      <mat-icon mat-list-icon>language</mat-icon>
      <ng-container i18n="Top menu|button @@topMenu.language">Язык</ng-container>
    </a>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="login.signOut()">
      <mat-icon>exit_to_app</mat-icon>
      <ng-container i18n="Top menu|button @@topMenu.logOut">Выйти</ng-container>
    </button>
    <ng-container *ngIf="user$ | async as user">
      <button mat-menu-item *ngIf="getOldToken()" (click)="logOutUser(user.id)">
        <mat-icon>exit_to_app</mat-icon>
        Выйти с пользователя
      </button>
      <a mat-menu-item routerLink="/admins" *ngIf="user.siteAdmin">
        <mat-icon mat-list-icon>recent_actors</mat-icon>
        Админка</a>
    </ng-container>
  </mat-menu>

  <button class="user-top-menu-trigger" mat-button #trigger="matMenuTrigger"
          [matMenuTriggerFor]="userTopMenu"
          style="height: 100%;"
          [class.menuOpen]="trigger.menuOpen">
    <ng-container *ngIf="user$ | async as user">
      <img class="circle-img" *ngIf="showAvatar" [src]="user?.photoUrl" width="40" height="40" alt="">
      <span fxHide.xs="" style="display: inline-block; max-width: 150px"
            class="short-string">{{user?.firstName}} {{user?.lastName}}</span>
      <!--<mat-icon fxHide.xs="" class="material-icons">arrow_drop_down</mat-icon>-->
    </ng-container>
  </button>
</div>

