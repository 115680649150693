export let BASE_URL = document.location.protocol + '//' + document.location.host;
let isFeature = false;
if (document.location.host.match(/^feature/)) {
  BASE_URL = 'https://dev.telepost.pw';
  isFeature = true;
}

if (localStorage.API_URL) {
  BASE_URL = localStorage.API_URL;
}

export const environment = {
  production: true,
  hmr: false,
  e2e: false,
  apiPath: BASE_URL + '/api',
  isFeature,
};
