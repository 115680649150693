/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChannelStatsModel } from '../model/channelStatsModel';
import { ReactionsStatsResponse } from '../model/reactionsStatsResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ChannelStatsService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get channel accepted join requests stats
     * 
     * @param channelId 
     * @param aggregate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAcceptedJoinRequestsStats(channelId: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getAcceptedJoinRequestsStats(channelId: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getAcceptedJoinRequestsStats(channelId: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getAcceptedJoinRequestsStats(channelId: string, aggregate?: 'hour' | 'day' | 'week' | 'month' | 'year', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getAcceptedJoinRequestsStats.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/get-accepted-join-requests-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get channel members stats
     * 
     * @param skip 
     * @param take 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMembersCount(skip: number, take: number, channelId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ChannelStatsModel>>;
    public getMembersCount(skip: number, take: number, channelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ChannelStatsModel>>>;
    public getMembersCount(skip: number, take: number, channelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ChannelStatsModel>>>;
    public getMembersCount(skip: number, take: number, channelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling getMembersCount.');
        }

        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling getMembersCount.');
        }

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getMembersCount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (skip !== undefined && skip !== null) {
            queryParameters = queryParameters.set('skip', <any>skip);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ChannelStatsModel>>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/members-count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get channel publications stats
     * 
     * @param channelId 
     * @param aggregate 
     * @param operator 
     * @param timeZone Timezone
     * @param creators 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishStats(channelId: string, aggregate?: 'day' | 'week' | 'month', operator?: '$sum' | '$avg', timeZone?: string, creators?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPublishStats(channelId: string, aggregate?: 'day' | 'week' | 'month', operator?: '$sum' | '$avg', timeZone?: string, creators?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPublishStats(channelId: string, aggregate?: 'day' | 'week' | 'month', operator?: '$sum' | '$avg', timeZone?: string, creators?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPublishStats(channelId: string, aggregate?: 'day' | 'week' | 'month', operator?: '$sum' | '$avg', timeZone?: string, creators?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getPublishStats.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (operator !== undefined && operator !== null) {
            queryParameters = queryParameters.set('operator', <any>operator);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }
        if (creators) {
            queryParameters = queryParameters.set('creators', creators.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/get-publications-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get channel reactions stats
     * 
     * @param channelId 
     * @param aggregate 
     * @param timeZone Timezone
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReactionsStats(channelId: string, aggregate?: 'day' | 'week' | 'month', timeZone?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ReactionsStatsResponse>>;
    public getReactionsStats(channelId: string, aggregate?: 'day' | 'week' | 'month', timeZone?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReactionsStatsResponse>>>;
    public getReactionsStats(channelId: string, aggregate?: 'day' | 'week' | 'month', timeZone?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReactionsStatsResponse>>>;
    public getReactionsStats(channelId: string, aggregate?: 'day' | 'week' | 'month', timeZone?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getReactionsStats.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (aggregate !== undefined && aggregate !== null) {
            queryParameters = queryParameters.set('aggregate', <any>aggregate);
        }
        if (timeZone !== undefined && timeZone !== null) {
            queryParameters = queryParameters.set('timeZone', <any>timeZone);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<ReactionsStatsResponse>>(`${this.basePath}/channels/${encodeURIComponent(String(channelId))}/get-reactions-stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
