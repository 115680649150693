import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { TopNavComponent } from './top-nav/top-nav.component';
import { CommonModule } from '@angular/common';
import { PipeModule } from '../pipe/pipe.module';
import { DialogAccessComponent } from './dialog-access/dialog-access.component';
import { DialogFormComponent } from './dialog-form/dialog-form.component';
import { InlineLoaderComponent } from './inline-loader/inline-loader.component';
import { DialogCreateBotComponent } from './dialog-create-bot/dialog-create-bot.component';
import { DialogCreateChannelComponent } from './dialog-create-channel/dialog-create-channel.component';
import { TopNavUserComponent } from './top-nav-user/top-nav-user.component';
import { ErrorBlockComponent } from './error-block/error-block.component';
import { UploadsService } from './uploads.service';
import { FooterComponent } from './footer/footer.component';
import { FileSelectComponent } from './file-select/file-select.component';
import { ResumableUploaderComponent } from './resumable-uploader/resumable-uploader.component';
import { MobileFullScreenDialogComponent } from './mobile-full-screen-dialog/mobile-full-screen-dialog.component';

@NgModule({
  imports: [MaterialModule, CommonModule, PipeModule],
  declarations: [
    TopNavComponent,
    DialogAccessComponent,
    DialogFormComponent,
    InlineLoaderComponent,
    DialogCreateBotComponent,
    DialogCreateChannelComponent,
    TopNavUserComponent,
    ErrorBlockComponent,
    FooterComponent,
    FileSelectComponent,
    ResumableUploaderComponent,
    MobileFullScreenDialogComponent,
  ],
  exports: [
    MaterialModule,
    CommonModule,
    TopNavComponent,
    PipeModule,
    DialogAccessComponent,
    DialogFormComponent,
    InlineLoaderComponent,
    DialogCreateChannelComponent,
    DialogCreateBotComponent,
    TopNavUserComponent,
    ErrorBlockComponent,
    FooterComponent,
    FileSelectComponent,
    ResumableUploaderComponent,
    MobileFullScreenDialogComponent,
  ],
  entryComponents: [DialogAccessComponent, DialogCreateChannelComponent, DialogCreateBotComponent],
  providers: [UploadsService],
})
export class SharedModule {}
