import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ChannelResponse, PostResponse, PostsCrudService, SharedPostService } from '../../../api';
import { ChannelsState } from '../../../state/channels.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-post-copy-dialog',
  templateUrl: './post-copy-dialog.component.html',
  styleUrls: ['./post-copy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCopyDialogComponent implements OnInit {
  loading = false;
  post: PostResponse;
  err: any;
  // save post
  // show edit btn
  channels$ = this.store.select(ChannelsState.items);

  selectedChannel: ChannelResponse;
  constructor(
    public matDialogRef: MatDialogRef<PostCopyDialogComponent>,
    private router: Router,
    private store: Store,
    private api: PostsCrudService,
    private sharedPost: SharedPostService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: { postId: string; shareCode?: string }
  ) {}

  ngOnInit() {
    this.router.events.pipe(untilDestroyed(this), take(1)).subscribe(() => this.closeDialog());
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  avatarUrl(channel: ChannelResponse) {
    return channel.photoUrl || 'https://robohash.org/' + channel.username + '.png';
  }

  selectChannel(channel: ChannelResponse) {
    this.loading = true;
    this.selectedChannel = channel;
    let req$ = this.data.shareCode
      ? this.sharedPost.copy({ dstChannelId: channel.id }, this.data.shareCode, this.data.postId)
      : this.api.createCopy({ dstChannelId: channel.id }, this.data.postId);
    req$
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.loading = false;
          this.cd.detectChanges();
        })
      )
      .subscribe(
        rs => (this.post = rs),
        err => (this.err = err)
      );
  }
}
