<tg-top-nav style="position: relative"></tg-top-nav>

<div style="background-color: #eeeeee">
  <div class="container" style="padding-bottom: 56px; padding-top: 56px">
    <h1 class="mat-display-1" style="margin-bottom: 20px"
        i18n="User settings page|title @@userSettingsPage.title">Настройки аккаунта</h1>
    <div class="mat-subheading-1" i18n="User settings page|text @@userSettingsPage.subTitle">
      Здесь находятся настройки формы поста по умолчанию, <br>
      а также часовой пояс в котором будут отображаться даты.
    </div>
  </div>
</div>

<div class="container">
  <mat-tab-group color="primary">
    <mat-tab label="Форма создания поста"
             i18n-label="User settings page|tab label @@userSettingsPage.postFormSettingLabel">
      <ng-template matTabContent>
        <div style="padding: 16px">
          <h2 i18n="User settings page|title @@userSettingsPage.postFormSettingsTitle">Настройки формы создания
            поста</h2>
          <tg-post-form-default-settings></tg-post-form-default-settings>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Часовой пояс" i18n-label="User settings page|title @@userSettingsPage.timezoneSettingsLabel">
      <ng-template matTabContent>
        <div style="padding: 16px">
          <h2 i18n="User settings page|title @@userSettingsPage.timezoneSettingsTitle">Укажите часовой пояс в котором
            отображать время</h2>
          <tg-timezone-settings></tg-timezone-settings>
        </div>
      </ng-template>
    </mat-tab>
    <!--    <mat-tab label="Статистика"> Content 3 </mat-tab>-->
  </mat-tab-group>
</div>
