import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { ChannelResponse } from '../../api';

@Component({
  selector: 'tg-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelsListComponent implements OnInit {
  @Input()
  channels: ChannelResponse[];
  @Input()
  type = 'elevation';
  @Output()
  select = new Subject();

  constructor(private store: Store) {}

  ngOnInit() {}

  avatarUrl(channel: ChannelResponse) {
    return channel.photoUrl || 'https://robohash.org/' + channel.username + '.png';
  }
}
