import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BotResponse, ChannelsService } from "../../api";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { BotsState, BotsStateModel, SelectBot } from "../../state/bots.state";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

// export function isClosedChannelValidator(c: AbstractControl) {
//   if (!c.value) {
//     return null;
//   }
//   const re = /t\.me\/joinchat\/.+/g;
//   return re.test(c.value) ? { isClosedChannel: true } : null;
// }

export function isBotUsername(c: AbstractControl) {
  if (!c.value) {
    return null;
  }
  const val = c.value.trim();
  if (val && val.endsWith('bot')) {
    return { botUsername: true };
  }
  return null;
}

export function isUserId(c: AbstractControl) {
  if (!c.value) {
    return null;
  }
  const val = c.value.trim();
  if (val && val.match(/^\d+$/) && !val.startsWith('100')) {
    return { isUserId: true };
  }
  return null;
}

@Component({
  selector: 'tg-dialog-create-channel',
  templateUrl: './dialog-create-channel.component.html',
  styleUrls: ['./dialog-create-channel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCreateChannelComponent implements OnInit {
  @Select(BotsState)
  bots$: Observable<BotsStateModel>;

  @Select(BotsState.selectedBot)
  selectedBot$: Observable<BotResponse>;

  channelForm = new FormGroup({
    username: new FormControl('', [isUserId, isBotUsername]),
    botId: new FormControl(),
  });

  constructor(
    public dialogRef: MatDialogRef<DialogCreateChannelComponent>,
    private channelsService: ChannelsService,
    private store: Store,
    public cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    public selectedBotId?: string | undefined
  ) {}

  @ViewChild('inputChannelUsername')
  inputChannelUsername;

  ngOnInit() {
    let selectedBotId = this.selectedBotId;
    if (!this.selectedBotId) {
      selectedBotId = this.store.selectSnapshot(state => state.bots.items[0].id);
    }

    this.store.dispatch(new SelectBot(selectedBotId));
    this.channelForm.get('botId').patchValue(selectedBotId);

    this.channelForm.get('botId').valueChanges.subscribe(botId => this.store.dispatch(new SelectBot(botId)));
  }

  channelCreated(res) {
    this.dialogRef.close({
      msg: $localize`:Create channel dialog|notify @@createChannelDialog.createdSuccess:Канал успешно подключен`,
      res,
    });
  }

  saveChannel() {
    return () => this.channelsService.createChannel(this.channelForm.value);
  }

  private setFocus() {
    const targerElement = this.inputChannelUsername;
    setTimeout(function waitTargetElem() {
      if (targerElement.nativeElement) {
        targerElement.nativeElement.focus();
      } else {
        setTimeout(waitTargetElem, 100);
      }
    }, 100);
  }

  onChange(event: any) {
    if (event.selectedIndex === 1) {
      this.setFocus();
    }
  }
}
