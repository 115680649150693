import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { LoadChannels } from '../../state/channels.state';
import { finalize } from 'rxjs/operators';
import { PostResponse, SharedPostService } from '../../api';
import { PostCopyDialogComponent } from '../../post-view/components/post-copy-dialog/post-copy-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FullScreenDialogService } from '../../shared/full-screen-dialog.service';

@UntilDestroy()
@Component({
  selector: 'tg-shared-post',
  templateUrl: './shared-post.component.html',
  styleUrls: ['./shared-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedPostComponent implements OnInit {
  post: PostResponse;
  loading = true;
  err;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private store: Store,
    private sharedPost: SharedPostService,
    private fullScreenDialogService: FullScreenDialogService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.sharedPost
      .get(this.route.snapshot.paramMap.get('shareCode'), this.route.snapshot.paramMap.get('postId'))
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.loading = false;
          this.cd.detectChanges();
        })
      )
      .subscribe(
        rs => {
          this.post = rs;
        },
        error1 => {
          this.err = error1;
        }
      );
  }

  copyPost() {
    // create dialog
    this.store.dispatch(new LoadChannels());
    this.fullScreenDialogService.open(PostCopyDialogComponent, {
      data: { postId: this.post.id, shareCode: this.post.shareCode },
      autoFocus: true,
      restoreFocus: true,
    });
  }
}
