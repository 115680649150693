/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AudioData } from './audioData';
import { DocumentData } from './documentData';
import { PhotoData } from './photoData';
import { StickerData } from './stickerData';
import { VideoData } from './videoData';
import { VoiceData } from './voiceData';


export interface FileResponse { 
    id: string;
    kind: FileResponse.KindEnum;
    filename: string;
    size: number;
    publicUrl: string;
    contentType: string;
    photo?: PhotoData;
    video?: VideoData;
    voice?: VoiceData;
    audio?: AudioData;
    sticker?: StickerData;
    document?: DocumentData;
    posterUrl?: string;
}
export namespace FileResponse {
    export type KindEnum = 'photo' | 'video' | 'audio' | 'voice' | 'document' | 'sticker';
    export const KindEnum = {
        Photo: 'photo' as KindEnum,
        Video: 'video' as KindEnum,
        Audio: 'audio' as KindEnum,
        Voice: 'voice' as KindEnum,
        Document: 'document' as KindEnum,
        Sticker: 'sticker' as KindEnum
    };
}
