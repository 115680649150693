import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PostsCrudService } from '../../../api';
import { FullScreenDialogService } from '../../../shared/full-screen-dialog.service';

@Component({
  selector: 'tg-links-clicks-dialog',
  templateUrl: './links-clicks-dialog.component.html',
  styleUrls: ['./links-clicks-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinksClicksDialogComponent implements OnInit {
  loading = true;

  stats = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { postId: string; channelId: string },
    private api: PostsCrudService,
    private cd: ChangeDetectorRef,
    public full: FullScreenDialogService
  ) {}

  ngOnInit(): void {
    this.api.getLinksClicks(this.data.postId).subscribe(rs => {
      this.stats = rs;
      this.loading = false;
      this.cd.detectChanges();
    });
  }
}
