<tg-mobile-full-screen-dialog>
  <h1 class="title" i18n="Voters dialog|title @@votersDialog.title">
    Список проголосовавших
  </h1>

  <div style="min-height: 150px">
    <mat-progress-spinner
      *ngIf="votersLoad && !voters"
      color="primary"
      mode="indeterminate"
      style="margin: 0 auto"
    ></mat-progress-spinner>
    <div *ngIf="voters">
      <h3 fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container i18n="Voters dialog|text @@votersDialog.allCount">Всего проголосовало: {{ voters.count }}</ng-container>
        <p *ngIf="voters.reactions">
          <mat-form-field>
            <mat-label i18n="Voters dialog|label @@votersDialog.selectReactionLabel">Реакции</mat-label>
            <mat-select (selectionChange)="filterVoters($event)">
              <mat-option [value]="" selected i18n="Voters dialog|option @@votersDialog.allOption">Все</mat-option>
              <mat-option *ngFor="let react of voters.reactions" [value]="react.value">
                {{ react.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </p>
      </h3>
      <table>
        <thead>
        <tr>
          <th style="text-align: left">id</th>
          <th>name</th>
          <th>username</th>
          <th *ngIf="voters.getChoice">choice</th>
        </tr>
        </thead>
        <ng-container *ngFor="let u of filterVotersByReaction(voters.items)">
          <tr *ngIf="u">
            <td width="100">{{ u.id }}</td>
            <td width="200">{{ u.first_name }} {{ u.last_name }}</td>
            <td>{{ u.username }}</td>
            <td *ngIf="voters.getChoice">{{ voters.getChoice(u.choice) }}</td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
</tg-mobile-full-screen-dialog>
