import { setOptions } from 'highcharts';
import { Info } from 'luxon';

// todo: i18n
export function configHighchart(instance: { setOptions: typeof setOptions }) {
  instance.setOptions({
    global: {
      useUTC: false
    } as any,
    lang: {
      loading: 'Загрузка...',
      months: Info.months('long').map(s => s.charAt(0).toUpperCase() + s.slice(1)),
      weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
      shortMonths: Info.months('short').map(s => s.charAt(0).toUpperCase() + s.slice(1)),
      exportData: {
        categoryHeader: 'Экспорт',
      },
      rangeSelectorFrom: 'С',
      rangeSelectorTo: 'По',
      rangeSelectorZoom: 'Период',
      downloadPNG: 'Скачать PNG',
      downloadJPEG: 'Скачать JPEG',
      downloadPDF: 'Скачать PDF',
      downloadSVG: 'Скачать SVG',
      printChart: 'Напечатать график',
    } as any,
  });
}
