/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InlineRowModel } from './inlineRowModel';


export interface MessageDefaultSettings { 
    notifications: boolean;
    previewLinks: boolean;
    enableComments: boolean;
    parseMode: MessageDefaultSettings.ParseModeEnum;
    reactions: Array<InlineRowModel>;
}
export namespace MessageDefaultSettings {
    export type ParseModeEnum = 'Markdown' | 'HTML' | 'Native';
    export const ParseModeEnum = {
        Markdown: 'Markdown' as ParseModeEnum,
        HTML: 'HTML' as ParseModeEnum,
        Native: 'Native' as ParseModeEnum
    };
}
