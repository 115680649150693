import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DateTimePickerDialogV2Component } from '../../components/date-time-picker-dialog-v2/date-time-picker-dialog-v2.component';
import { ScheduleDialogData } from '../../lib/types';
import { Platform } from '@angular/cdk/platform';
import { NativeDateTimePikerComponent } from '../../components/native-date-time-piker/native-date-time-piker.component';

@Injectable({ providedIn: 'root' })
export class DialogOpenService {
  config: MatDialogConfig<any> = {
    maxWidth: '520px',
    panelClass: '',
    autoFocus: false,
  };

  constructor(private dialog: MatDialog, private platform: Platform) {}

  openDialogSchedule(data: ScheduleDialogData, config = {}) {
    this.config['data'] = data;
    this.config.panelClass = 'tg-dialog-schedule';
    if (this.platform.IOS) {
      return this.dialog.open(NativeDateTimePikerComponent, { ...this.config, ...config })
    }
    return this.dialog.open(DateTimePickerDialogV2Component, { ...this.config, ...config });
  }

  // openDialogAddAdmin(data: ChannelAdminDialogData) {
  //   this.config['data'] = data;
  //   this.config.panelClass = 'tg-dialog-admin';
  //   return this.dialog.open(DialogAddAdminComponent, this.config);
  // }

  // openDialogShowChannelAdmin(data: ShowChannelAdminDialogData) {
  //   this.config['data'] = data;
  //   this.config.panelClass = 'tg-dialog-admin';
  //   this.config.maxWidth = this.media.isActive('xs') ? '400px' : '520px';
  //   return this.dialog.open(DialogShowChannelAdminComponent, this.config);
  // }
}
