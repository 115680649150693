import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { LoadPostsSuccess } from '../../../channel/state/posts-list.state';
import { OpenEditForm, OpenEditMessageForm } from '../../../post-form/post-from.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-post-dialog',
  templateUrl: './post-dialog.component.html',
  styleUrls: ['./post-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store,
    public dialogRef: MatDialogRef<PostDialogComponent>,
    private actions$: Actions
  ) {}

  ngOnInit() {
    this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(...[LoadPostsSuccess])).subscribe(() => {
      this.closeDialog(true);
    });
  }

  editPost(post) {
    this.store.dispatch(new OpenEditForm(post));
  }

  editPostMsg(msg) {
    this.store.dispatch(new OpenEditMessageForm(msg));
  }

  closeDialog(data?) {
    this.dialogRef.close(data);
  }
}
