import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UsersService, UtilsService } from '../../../api';
import { Store } from '@ngxs/store';
import { UserState } from '../../../auth/user.state';
import { FormBuilder, FormGroup } from '@angular/forms';
import { map, startWith, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { SetUserInfo } from '../../../auth/auth.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface Country {
  id: string;
  name: string;
  timezones: ReadonlyArray<string>;
}

export const _filter = (opt: Country, value: string): boolean => {
  const filterValue = value.toLowerCase();

  let ident = `${opt.id}${opt.name}${opt.timezones.join('')}`.toLowerCase();

  return ident.indexOf(filterValue) !== -1;
};

export const _filterFull = (opt: Country, value: string): boolean => {
  const filterValue = value.toLowerCase();
  if (filterValue === opt.id.toLowerCase()) {
    return true;
  }
  if (filterValue === opt.name.toLowerCase()) {
    return true;
  }
  return opt.timezones.some(z => z.toLowerCase() === filterValue);
};

@UntilDestroy()
@Component({
  selector: 'tg-timezone-settings',
  templateUrl: './timezone-settings.component.html',
  styleUrls: ['./timezone-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimezoneSettingsComponent implements OnInit {
  userTimeZone = this.store.selectSnapshot(UserState.timeZone);

  form: FormGroup = this._formBuilder.group({
    timeZone: this.userTimeZone,
  });

  countries: Country[] = null;
  countries$: Observable<Country[]>;
  geoip;

  lastError = null;

  constructor(
    private utilsApi: UtilsService,
    private store: Store,
    private _formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private userApi: UsersService,
    private snack: MatSnackBar
  ) {}

  ngOnInit() {
    this.countries$ = this.form.get('timeZone').valueChanges.pipe(
      startWith(''),
      map(value => this._filterCountries(value))
    );
    this.utilsApi
      .getTimezones()
      .pipe(untilDestroyed(this))
      .subscribe(rs => {
        this.countries = rs.countries;
        this.geoip = rs.geoip;
        this.cd.detectChanges();
      });
  }

  get dateTimeNow() {
    let date = DateTime.local();
    if (this.form.value.timeZone) {
      date = date.setZone(this.form.value.timeZone);
    }
    return date.toLocaleString(DateTime.DATETIME_FULL);
  }

  private _filterCountries(value: string): any[] {
    if (value) {
      let fullMatch = this.countries.filter(group => _filterFull(group, value));
      if (fullMatch.length) {
        return fullMatch;
      }
      return this.countries.filter(group => _filter(group, value));
    }

    return this.countries;
  }

  save() {
    this.lastError = null;
    this.userApi
      .setTimezone(this.form.value)
      .pipe(
        untilDestroyed(this),
        tap(
          rs => {
            this.store.dispatch(new SetUserInfo(rs));
            this.form.markAsPristine();
            this.snack.open($localize`:Timezone settings|button @@timezoneSettings.saveSuccess:Настройки сохранены`, 'Ok');
            this.cd.detectChanges();
          },
          e => {
            this.lastError = e;
            this.cd.detectChanges();
          }
        )
      )
      .subscribe();
  }
}
