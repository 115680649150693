import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SelectedChannelState, SelectedChannelStateModel } from '../../../channel/state/selected-channel.state';

@Component({
  selector: 'tg-telegram-post-dialog',
  templateUrl: './telegram-post-dialog.component.html',
  styleUrls: ['./telegram-post-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramPostDialogComponent implements OnInit {
  @Select(SelectedChannelState)
  channel$: Observable<SelectedChannelStateModel>;

  loaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private elementRef: ElementRef,
    private _renderer: Renderer2,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const script = this._renderer.createElement('script');
    script.src = `https://telegram.org/js/telegram-widget.js?5`;
    this._renderer.setAttribute(script, 'data-telegram-post', `${this.data.channel.username}/${this.data.telegramId}`);
    this._renderer.setAttribute(script, 'data-width', '100%');
    this._renderer.setAttribute(script, 'async', '');
    this._renderer.listen(script, 'load', () => {
      const iframeBlock = this.elementRef.nativeElement.querySelector('.content').querySelector('iframe');
      iframeBlock.addEventListener('load', () => {
        this.loaded = true;
        iframeBlock.removeEventListener('load');
        this.cd.detectChanges();
      });
    });
    this.elementRef.nativeElement.querySelector('.content').appendChild(script);
  }

  handleClickInput(e) {
    e.target.setSelectionRange(0, e.target.value.length);
  }

  copyLink(value) {
    if (this.toastr.currentlyActive) {
      this.toastr.clear();
    }
    const val = value;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success('Ссылка скопирована');
  }
}
