<div class="loader" *ngIf="loading">
  <tg-inline-loader></tg-inline-loader>
</div>
<mat-toolbar>
  <mat-toolbar-row>
    <!--    <button mat-icon-button><mat-icon>arrow_back</mat-icon></button>-->
    <button mat-icon-button (click)="prevMonth()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-icon-button (click)="nextMonth()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <span style="margin-left: 16px; text-transform: capitalize; width: 100px" fxHide.xs="">
      {{ currentView | dateTime: 'MMM yyyy' }}
    </span>
    <mat-form-field appearance="outline" style="width: 106px; margin-left: 16px" color="primary">
      <mat-label i18n="Posts calendar|label @@postsCalendar.monthLabel">Месяц</mat-label>
      <mat-select [value]="currentView.month" (valueChange)="selectMonth($event)">
        <mat-option *ngFor="let month of months; let i = index" [value]="i + 1">
          <span style="text-transform: capitalize;">{{ month }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 76px;" color="primary">
      <mat-label i18n="Posts calendar|label @@postsCalendar.yearLabel">Год</mat-label>
      <mat-select [value]="currentView.year" (valueChange)="selectYear($event)">
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span style="flex: 1 1 auto;"></span>

    <mat-form-field appearance="outline" style="width: 155px;" *ngIf="showStateSwitcher">
      <mat-label i18n="Posts calendar|label @@postsCalendar.channelsabel">Каналы</mat-label>
      <mat-select multiple [formControl]="channelsControl">
        <mat-option [value]="ch.id" *ngFor="let ch of allChannels">
          {{ ch.fullName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" style="width: 155px;" *ngIf="showStateSwitcher">
      <mat-label i18n="Posts calendar|label @@postsCalendar.typeLabel">Тип</mat-label>
      <mat-select [value]="state" (valueChange)="changeState($event)">
        <mat-option value="queue" i18n="Posts calendar|option @@postsCalendar.optionQueue">
          Запланированые
        </mat-option>
        <mat-option value="sent" i18n="Posts calendar|option @@postsCalendar.optionSent">
          Опубликованые
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-toolbar-row>
</mat-toolbar>
<div class="short-name-list" fxLayoutAlign="row" fxLayoutAlign="space-between start">
  <div
    class="short-name-item"
    *ngFor="let dayName of daysShortNames; let i = index"
    [class.rest-day]="i === 5 || i === 6"
    fxFlex="1 1 100%"
  >
    {{ dayName }}
  </div>
</div>
<div class="short-name-list" fxLayout="row wrap" fxLayoutAlign="start stretch" style="margin-bottom: 16px">
  <div
    class="day-item"
    *ngFor="let data of monthViewDates; let i = index"
    [class.rest-day]="[6, 7].includes(data.date.weekday)"
    [class.prev-month]="data.date.month !== currentView.month"
    [class.has-posts]="data.posts.length"
    (click)="openDayPosts(data.posts)"
  >
    <div class="day-item-content">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <span class="day-number">{{ data.date.day }}</span>
      </div>
      <span class="post-item" *ngFor="let post of data.posts | slice: 0:10">
        <tg-calendar-post-item *ngIf="post" [post]="post"></tg-calendar-post-item>
      </span>
      <button mat-button *ngIf="data.posts.length > 10" class="has-more-posts"
              i18n="Posts calendar|button @@postsCalendar.moreButton">
        + Еще {{ data.posts.length - 10 }}
      </button>
    </div>
  </div>
</div>
<div fxLayout="row wrap" class="legend">
  <div *ngFor="let item of legends" class="legend-item">
    <span class="legend-mark" [style.background]="item.color"></span> - {{ item.value }}
  </div>
</div>
