<div class="dialog-head" mat-dialog-title *ngIf="!spinnerType">
  <h2>{{type}}</h2>
  <button class="close-btn" mat-icon-button tabindex="-1" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="content" *ngIf="spinnerType" style="height: 180px;">
  <mat-spinner [diameter]="70" [strokeWidth]="3" style="margin: 20px auto;"></mat-spinner>
  <h2 style="text-align: center" i18n="Dialog common|label @@dialogCommon.loading">Идет загрузка...</h2>
</mat-dialog-content>

<mat-dialog-content class="content" *ngIf="!spinnerType">
  <p class="mat-subheading-2 message">{{data.message}}</p>
  <p class="remove-with-telegram" *ngIf="showCheckBox">
    <mat-checkbox color="warn" (change)="removeWithTelegram()" i18n="Dialog common|label @@dialogCommon.deleteTelegram">
      Удалить пост из канала в Telegram?
    </mat-checkbox>
  </p>
</mat-dialog-content>

<mat-dialog-actions class="btn-confirm" *ngIf="!spinnerType">
  <button id="dg_acs_cl_btn" mat-button mat-dialog-close color="secondary" *ngIf="confirmType || promptType"
          i18n="Dialog common|button @@dialogCommon.cancelButton">ОТМЕНА
  </button>
  <button id="dg_acs_ac_btn" mat-raised-button [color]="colorBtn" (click)="access()">
    {{button.name.toUpperCase()}}
  </button>
</mat-dialog-actions>
