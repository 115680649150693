import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FullScreenDialogService } from '../full-screen-dialog.service';

@Component({
  selector: 'tg-mobile-full-screen-dialog',
  templateUrl: './mobile-full-screen-dialog.component.html',
  styleUrls: ['./mobile-full-screen-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileFullScreenDialogComponent implements OnInit {
  @Input() fixedHeader = false;

  useFullScreen = this.full.useFullScreen;

  constructor(private full: FullScreenDialogService) {}

  ngOnInit() {}
}
