import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { DateTime, Info } from 'luxon';
import { PostResponse, PostsCrudService, PostsListResponse } from '../../api';
import { Store } from '@ngxs/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FullScreenDialogService } from '../../shared/full-screen-dialog.service';
import { DayPostsListDialogComponent } from '../day-posts-list-dialog/day-posts-list-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChannelsState } from '../../state/channels.state';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'tg-posts-calendar-v2',
  templateUrl: './posts-calendar-v2.component.html',
  styleUrls: ['./posts-calendar-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostsCalendarV2Component implements OnInit {
  public daysShortNames = Info.weekdays('short');
  public months = Info.months('long');
  public firstYear = 2017;
  public years: number[] = [];

  currentView = DateTime.local().startOf('month');

  loading = true;

  postsMap = new Map<any, PostResponse[]>();
  monthViewDates: { date: DateTime; posts: PostResponse[] }[] = [];

  @Input() dateParamName = 'scheduleAt';

  @Input() channels: string[];

  @Input() state: PostResponse.StateEnum;

  @Input() showStateSwitcher = false;

  allChannels = this.store.selectSnapshot(ChannelsState.items);

  channelsControl = new FormControl([]);

  legends = [
    { color: '#00B0FF', value: $localize`:Posts calendar|label @@postsCalendar.label.text:Текстовый пост` },
    { color: '#FF877E', value: $localize`:Posts calendar|label @@postsCalendar.label.photo:Фото пост` },
    { color: '#69E6BF', value: $localize`:Posts calendar|label @@postsCalendar.label.video:Видео пост` },
    { color: '#FFD668', value: $localize`:Posts calendar|label @@postsCalendar.label.document:Документ` },
    { color: '#DDABFC', value: $localize`:Posts calendar|label @@postsCalendar.label.poll:Опрос` },
    { color: '#a9f33f', value: $localize`:Posts calendar|label @@postsCalendar.label.album:Альбом` },
    { color: '#120cfc', value: $localize`:Posts calendar|label @@postsCalendar.label.audio:Аудио` },
    { color: '#fc30df', value: $localize`:Posts calendar|label @@postsCalendar.label.voice:Голосовое` },
    { color: '#fcf957', value: $localize`:Posts calendar|label @@postsCalendar.label.animation:Анимация` },
    { color: '#69E6BF', value: $localize`:Posts calendar|label @@postsCalendar.label.roundVideo:Круглое видео` },
    { color: '#575757', value: $localize`:Posts calendar|label @@postsCalendar.label.sticker:Стикер` },
  ];

  constructor(
    private apiCrud: PostsCrudService,
    private store: Store,
    private snack: MatSnackBar,
    private fullScreenDialogService: FullScreenDialogService,
    private cd: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {
    const currentYear = DateTime.local().year;
    for (let year = this.firstYear; year <= currentYear + 1; year++) {
      this.years.push(year);
    }
  }

  ngOnInit() {
    this.prepareMonthViewDates();
    this.loadPosts().subscribe();
    this.channelsControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        switchMap(rs => this.loadPosts(rs.length ? rs : this.channels))
      )
      .subscribe();
  }

  loadPosts(channels = this.channels) {
    this.loading = true;
    let { start, end } = this.viewWindow;
    return this.apiCrud
      .list(
        channels,
        this.state,
        0,
        1000,
        this.dateParamName,
        JSON.stringify({
          [this.dateParamName]: {
            $gte: start.toISO(),
            $lte: end.toISO(),
          },
        })
      )
      .pipe(
        untilDestroyed(this),
        tap(
          rs => {
            this.postsMap.clear();
            this.preparePosts(rs);
            this.prepareMonthViewDates();
          },
          err =>
            this.snack.open(
              $localize`:Posts calendar|tooltip @@postsCalendar.label.loadError:Не удалось получить посты ((`,
              'Ok'
            ),
          () => {
            this.loading = false;
            this.cd.detectChanges();
          }
        )
      );
  }

  preparePosts(rs: PostsListResponse) {
    for (let post of rs.items) {
      let key = DateTime.fromISO(post[this.dateParamName]).toFormat('yyyy-MM-dd');
      if (this.postsMap.has(key)) {
        let items = this.postsMap.get(key);
        items.push(post);
        this.postsMap.set(key, items);
      } else {
        this.postsMap.set(key, [post]);
      }
    }
  }

  prepareMonthViewDates(): { date: DateTime; posts: PostResponse[] }[] {
    let { start, end } = this.viewWindow;
    let dates: { date: DateTime; posts: PostResponse[] }[] = [];
    while (start <= end) {
      let key = start.toFormat('yyyy-MM-dd');
      dates.push({
        date: start,
        posts: this.postsMap.has(key) ? this.postsMap.get(key) : [],
      });
      start = start.plus({ day: 1 });
    }
    this.monthViewDates = dates;
    return dates;
  }

  get viewWindow() {
    let start = this.currentView.startOf('week');
    let end = this.currentView.endOf('month').endOf('week');
    return { start, end };
  }

  selectMonth(num: number) {
    this.currentView = this.currentView.set({ month: num });
    this.loadPosts().subscribe();
  }

  prevMonth() {
    this.currentView = this.currentView.minus({ months: 1 });
    this.loadPosts().subscribe();
  }

  nextMonth() {
    this.currentView = this.currentView.plus({ months: 1 });
    this.loadPosts().subscribe();
  }

  selectYear(num: number) {
    this.currentView = this.currentView.set({ year: num });
    this.loadPosts().subscribe();
  }

  changeState(newState: PostResponse.StateEnum) {
    this.state = newState;
    if (this.state === 'queue') {
      this.dateParamName = 'scheduleAt';
    }
    if (this.state === 'sent') {
      this.dateParamName = 'publishedAt';
    }
    this.loadPosts().subscribe();
  }

  openDayPosts(posts: PostResponse[]) {
    if (!posts.length) {
      return;
    }

    this.fullScreenDialogService.open(DayPostsListDialogComponent, {
      maxWidth: '600px',
      viewContainerRef: this.viewContainerRef,
      componentFactoryResolver: this.componentFactoryResolver,
      data: posts,
      autoFocus: false,
      restoreFocus: false,
    });
  }
}
