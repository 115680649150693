import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppRouterService } from './core/app-router.service';
import { UserState } from './auth/user.state';
import { Observable, Subject } from 'rxjs';
import { Actions, Select } from '@ngxs/store';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, distinctUntilKeyChanged, filter, skip, takeUntil } from 'rxjs/operators';
import { CheckForUpdateService } from './core/check-for-update.service';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { UserStateModel } from './auth/user.state.model';

declare var gtag: any;
@Component({
  selector: 'tg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(UserState)
  user$: Observable<UserStateModel>;

  @ViewChild('rootBlock', { static: true })
  rootBlock;
  isMobile = this.platform.ANDROID || this.platform.IOS;

  destroy$ = new Subject();

  constructor(
    private appRouter: AppRouterService,
    public platform: Platform,
    private router: Router,
    private cu: CheckForUpdateService,
    private actions$: Actions
  ) {}

  ngOnInit() {
    if (environment.production) {
      this.router.events.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-117945631-2', { page_path: window.location.pathname });
          // gtag('event', 'page_view', { 'send_to': 'UA-117945631-2' });
        }
      });
      this.cu.pollUpdates();
    }

    if (this.isMobile) {
      // this.rootBlock.nativeElement.style.height = 'auto';
    }

    // for fix ios zoom, in overlay too
    if (this.platform.IOS) {
      window.document.body.classList.add('is-ios');
    }

    this.user$.pipe(takeUntil(this.destroy$), distinctUntilKeyChanged('isAuth'), skip(1)).subscribe(state => {
      if (state.isAuth) {
        this.appRouter.goToHomePage();
      } else {
        this.appRouter.goToLoginPage();
      }
    });

    this.ravenLogActions();
  }

  ravenLogActions() {
    this.actions$
      .pipe(
        takeUntil(this.destroy$),
        filter(rs => rs.status === 'DISPATCHED')
      )
      .subscribe(rs => {
        Sentry.addBreadcrumb({
          message: rs.action.constructor.type,
          category: 'ngxs',
          level: Severity.Info,
          data: rs.action.payload,
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  componentAdded(e) {
    // if (!e.menuOpen && this.isMobile) {
    //   this.rootBlock.nativeElement.style.height = 'calc(var(--vh) * 100)';
    // }
    // if (e.menuIsOpen$ && this.isMobile) {
    //   e.menuIsOpen$.subscribe(menuIsOpen => {
    //     if (menuIsOpen) {
    //       this.rootBlock.nativeElement.style.height = 'calc(var(--vh) * 100)';
    //     } else {
    //       this.rootBlock.nativeElement.style.height = 'calc(var(--vh) * 100)';
    //     }
    //   });
    // }
  }
}
