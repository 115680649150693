import { Injectable } from '@angular/core';
import { LoginWithTgDto } from '../api';

import { Store } from '@ngxs/store';
import { Login, SignOut } from './auth.actions';

// TODO: add timer to auto delete token after it expired
@Injectable()
export class LoginService {
  constructor(private store: Store) {}

  login(authData: LoginWithTgDto) {
    this.store.dispatch(new Login(authData));
  }

  signOut() {
    this.store.dispatch(new SignOut());
  }
}
