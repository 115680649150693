import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
  pure: true,
})
export class DurationPipe implements PipeTransform {
  transform(value: number): any {
    if (!value) {
      return;
    }
    // get full minutes
    let minutes = String(Math.trunc(value / 60)).padStart(2, '0');
    let seconds = String(value % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  }
}
