import { Injectable } from '@angular/core';
import { BottomSheetComponent } from './bottom-sheet.component';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';

@Injectable({
  providedIn: 'root',
})
export class OpenBottomSheetService {
  config: MatBottomSheetConfig<any> = {
    data: {
      color: '#000000',
      visible: true,
      disabled: false,
    },
  };

  constructor(private bottomSheet: MatBottomSheet) {}

  openBottomSheet(data) {
    const config = { ...this.config, data: this.prepareConfig(data) };
    return this.bottomSheet.open(BottomSheetComponent, config);
  }

  prepareConfig(data) {
    data.forEach((item, index) => {
      data[index] = { ...this.config.data, ...item };
    });
    return data;
  }
}
