import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(value: any, term?: any): any {
    const res =
      term !== undefined && term !== null && term.charAt().length > 0
        ? value.filter(item => {
            if (item.hasOwnProperty('title') || item.hasOwnProperty('username')) {
              return (
                item.title.match(new RegExp(this.parseSymbol(term), 'i')) ||
                item.username.match(new RegExp(this.parseSymbol(term), 'i'))
              );
            }
          })
        : value;
    return res;
  }

  parseSymbol(text) {
    return text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
}
