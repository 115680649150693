import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  type: 'confirm' | 'alert' | 'prompt' | 'spinner';
  button: {
    name: string;
    typeBtn: 'access' | 'info' | 'error';
  };
  post;
}

@Component({
  selector: 'tg-dialog-access',
  templateUrl: './dialog-access.component.html',
  styleUrls: ['./dialog-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogAccessComponent implements OnInit {
  button;
  removePostWithTelegram = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<DialogAccessComponent>) {
    this.button = data.button
      ? { name: data.button.name.toUpperCase(), type: data.button.typeBtn }
      : { name: 'OK', type: this.confirmType ? 'info' : 'error' };
  }

  ngOnInit() {}

  get type() {
    return this.confirmType || this.promptType
      ? $localize`:Dialog common|title @@dialogCommon.title1:Подтверждение`
      : $localize`:Dialog common|title @@dialogCommon.title2:Предупреждение`;
  }

  get colorBtn() {
    return this.infoBtn ? 'primary' : this.accessBtn ? 'accent' : 'warn';
  }

  access() {
    this.dialogRef.close(this.promptType ? { removeTelegram: this.removePostWithTelegram ? 'yes' : 'no' } : true);
  }

  removeWithTelegram() {
    this.removePostWithTelegram = !this.removePostWithTelegram;
  }

  get infoBtn() {
    return this.button.type === 'info';
  }

  get accessBtn() {
    return this.button.type === 'access';
  }

  get spinnerType() {
    return this.data.type === 'spinner';
  }

  get confirmType() {
    return this.data.type === 'confirm';
  }

  get promptType() {
    return this.data.type === 'prompt';
  }

  get canDeleteFromTg() {
    if (!this.data || !this.data.post || !this.data.post.publishedAt) {
      return false;
    }
    let publishedAt = new Date(this.data.post.publishedAt);
    return Date.now() - publishedAt.getTime() < 48 * 24 * 3600 * 100;
  }

  get showCheckBox() {
    return this.promptType && !this.data.post.tgDeleted && this.data.post.state === 'sent' && this.canDeleteFromTg;
  }
}
