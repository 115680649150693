import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoadPosts } from '../../../channel/state/posts-list.state';
import { PostResponse, PostsCrudService } from '../../../api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-share-post-dialog',
  templateUrl: './share-post-dialog.component.html',
  styleUrls: ['./share-post-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharePostDialogComponent implements OnInit {
  loading = false;
  err;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { post: PostResponse },
    private postsService: PostsCrudService,
    private cd: ChangeDetectorRef,
    private store: Store,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  share(val: boolean) {
    this.loading = true;
    this.postsService
      .share({ share: val }, this.data.post.id)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.loading = false;
          this.cd.detectChanges();
          this.store.dispatch(new LoadPosts());
        })
      )
      .subscribe(
        rs => (this.data.post = rs),
        err => (this.err = err)
      );
  }

  handleClickInput(e) {
    e.target.setSelectionRange(0, e.target.value.length);
  }

  copyLink(value) {
    if (this.toastr.currentlyActive) {
      this.toastr.clear();
    }
    const val = value;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success($localize`:Share post dialog|notify @@sharePostDialog.copyLinkNotify:Ссылка скопирована`);
  }
}
