import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ChannelResponse, ChannelsService } from '../api';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ChannelsState, LoadChannels } from '../state/channels.state';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChannelsResolver implements Resolve<ChannelResponse[]> {
  constructor(private channelsApi: ChannelsService, private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const loaded = this.store.selectSnapshot(ChannelsState.loaded);
    if (!loaded) {
      return this.store.dispatch(new LoadChannels());
    }

    return of(null);
  }
}
