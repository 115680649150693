import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ChannelsState } from '../../state/channels.state';
import { FORCE_VIEW_CHANNEL } from '../../providers';

@Component({
  selector: 'tg-posts-calendar',
  templateUrl: './posts-calendar.component.html',
  styleUrls: ['./posts-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: FORCE_VIEW_CHANNEL, useValue: true}]
})
export class PostsCalendarComponent implements OnInit {
  channels = this.store.selectSnapshot(ChannelsState.items).map(c => c.id);

  constructor(private store: Store) {}

  ngOnInit() {}
}
