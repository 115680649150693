import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ChannelListItem, ChannelsListResponse, ChannelsService } from '../api/index';
import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export class LoadChannels {
  static readonly type = '[ChannelsState] LoadChannels';
}
export class ChannelsLoadedSuccess {
  static readonly type = '[ChannelsState] ChannelsLoadedSuccess';
  constructor(public payload: ChannelsListResponse) {}
}

export interface ChannelsStateModel {
  loading: boolean;
  loaded: boolean;
  count: number;
  items: ChannelListItem[];
}

@State<ChannelsStateModel>({
  name: 'channels',
  defaults: {
    loading: false,
    loaded: false,
    count: 0,
    items: [],
  },
})
@Injectable()
export class ChannelsState {
  @Selector()
  static items(state: ChannelsStateModel) {
    return state.items;
  }

  @Selector()
  static loaded(state: ChannelsStateModel) {
    return state.loaded;
  }

  constructor(private api: ChannelsService) {}

  @Action(LoadChannels)
  loadChannels({ getState, setState, dispatch }: StateContext<ChannelsStateModel>) {
    setState({ ...getState(), loading: true });
    return this.api.getChannels().pipe(
      tap(res => dispatch(new ChannelsLoadedSuccess(res))),
      finalize(() => setState({ ...getState(), loading: false }))
    );
  }

  @Action(ChannelsLoadedSuccess)
  channelsLoaded(
    { getState, setState, dispatch }: StateContext<ChannelsStateModel>,
    { payload }: ChannelsLoadedSuccess
  ) {
    setState({ ...getState(), ...payload, loaded: true });
  }
}
