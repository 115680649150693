import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AppRouterService {
  constructor(private router: Router) {}

  goToLoginPage() {
    console.log('goToLoginPage');
    return this.router.navigateByUrl('/login');
  }

  goToHomePage() {
    console.log('goToHomePage');
    return this.router.navigateByUrl('/');
  }

  goToChannel(id) {
    return this.router.navigateByUrl('/channel/' + id);
  }
}
