import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesToSize',
  pure: true,
})
export class BytesToSizePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (value === 0) return '0 B';
    const i = parseInt(String(Math.floor(Math.log(value) / Math.log(1024))), 10);
    return (value / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
  }
}
