<tg-mobile-full-screen-dialog [fixedHeader]="true">
  <h1 class="title" i18n="Hidden text clicks stats|title @@hiddenTextClicks.title">
    Статистика Читать продолжение
  </h1>
  <tg-inline-loader *ngIf="loading"></tg-inline-loader>
  <table *ngIf="stats && stats[data.channelId]">
    <thead>
    <tr>
      <th i18n="Hidden text clicks stats|header @@hiddenTextClicks.table.header.btn">Кнопка</th>
      <th i18n="Hidden text clicks stats|header @@hiddenTextClicks.table.header.clicks">Клики</th>
      <th i18n="Hidden text clicks stats|header @@hiddenTextClicks.table.header.uniqUsers">Уников</th>
      <th i18n="Hidden text clicks stats|header @@hiddenTextClicks.table.header.subscribe">Подписалось/осталось</th>
      <th i18n="Hidden text clicks stats|header @@hiddenTextClicks.table.header.notSubscribe">Не подписалось</th>
    </tr>
    <tr *ngFor="let stat of stats[data.channelId]">
      <td><b>{{data.buttons[stat.btnId]}}</b></td>
      <td>{{stat.allClicks}}</td>
      <td>{{stat.uniqClicks}}</td>
      <td>{{stat.subscribed}} / {{stat.stay}}</td>
      <td>{{stat.notSubscribed}}</td>
    </tr>
    </thead>
  </table>
</tg-mobile-full-screen-dialog>
