/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface CreateAdminDto { 
    userId: string;
    role?: CreateAdminDto.RoleEnum;
}
export namespace CreateAdminDto {
    export type RoleEnum = 'editor';
    export const RoleEnum = {
        Editor: 'editor' as RoleEnum
    };
}
