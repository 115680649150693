/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FileResponse } from '../model/fileResponse';
import { SetFilenameDto } from '../model/setFilenameDto';
import { SetPosterDto } from '../model/setPosterDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FilesService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get poster
     * 
     * @param key 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilePreview(key: string, id: string, observe?: 'body', reportProgress?: boolean): Observable<FileResponse>;
    public getFilePreview(key: string, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileResponse>>;
    public getFilePreview(key: string, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileResponse>>;
    public getFilePreview(key: string, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling getFilePreview.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFilePreview.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (key !== undefined && key !== null) {
            queryParameters = queryParameters.set('key', <any>key);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<FileResponse>(`${this.basePath}/file/${encodeURIComponent(String(id))}/preview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get file info
     * 
     * @param tusId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOne(tusId: string, observe?: 'body', reportProgress?: boolean): Observable<FileResponse>;
    public getOne(tusId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileResponse>>;
    public getOne(tusId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileResponse>>;
    public getOne(tusId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tusId === null || tusId === undefined) {
            throw new Error('Required parameter tusId was null or undefined when calling getOne.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<FileResponse>(`${this.basePath}/file/${encodeURIComponent(String(tusId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set poster
     * 
     * @param SetFilenameDto 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setFilename(SetFilenameDto: SetFilenameDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<FileResponse>;
    public setFilename(SetFilenameDto: SetFilenameDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileResponse>>;
    public setFilename(SetFilenameDto: SetFilenameDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileResponse>>;
    public setFilename(SetFilenameDto: SetFilenameDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SetFilenameDto === null || SetFilenameDto === undefined) {
            throw new Error('Required parameter SetFilenameDto was null or undefined when calling setFilename.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setFilename.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<FileResponse>(`${this.basePath}/file/${encodeURIComponent(String(id))}/set-filename`,
            SetFilenameDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set poster
     * 
     * @param SetPosterDto 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPoster(SetPosterDto: SetPosterDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<FileResponse>;
    public setPoster(SetPosterDto: SetPosterDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileResponse>>;
    public setPoster(SetPosterDto: SetPosterDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileResponse>>;
    public setPoster(SetPosterDto: SetPosterDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (SetPosterDto === null || SetPosterDto === undefined) {
            throw new Error('Required parameter SetPosterDto was null or undefined when calling setPoster.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setPoster.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<FileResponse>(`${this.basePath}/file/${encodeURIComponent(String(id))}/set-poster`,
            SetPosterDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
