import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogCreateBotComponent } from './dialog-create-bot/dialog-create-bot.component';
import { DialogCreateChannelComponent } from './dialog-create-channel/dialog-create-channel.component';
import { DialogAccessComponent } from './dialog-access/dialog-access.component';

@Injectable({ providedIn: 'root' })
export class DialogCreatesService {
  config = {
    maxWidth: '520px',
    panelClass: 'tg-dialog-form',
  };

  constructor(private dialog: MatDialog) {}

  openCreateBot() {
    return this.dialog.open(DialogCreateBotComponent, this.config);
  }

  openCreateChannel(selectedBotId?: number) {
    this.config['data'] = selectedBotId;
    return this.dialog.open(DialogCreateChannelComponent, this.config);
  }

  openAccessDialog(
    message: string,
    type: string,
    button?: { name: string; typeBtn: 'access' | 'info' | 'error' },
    post?
  ) {
    return this.dialog.open(DialogAccessComponent, {
      data: {
        message: message,
        type: type,
        button: button,
        post: post,
      },
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      panelClass: 'tg-dialog-access',
    });
  }
}
