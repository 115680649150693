import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import { LoginWithTokenComponent } from './login-with-token/login-with-token.component';
import { SharedPostComponent } from './shared-post/shared-post.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { PostFormDefaultSettingsComponent } from './user-settings/post-form-default-settings/post-form-default-settings.component';
import { TimezoneSettingsComponent } from './user-settings/timezone-settings/timezone-settings.component';
import { PostViewModule } from '../post-view/post-view.module';
import { PostsCalendarComponent } from './posts-calendar/posts-calendar.component';
import { PostsCalendarModule } from '../posts-calendar/posts-calendar.module';

@NgModule({
  imports: [SharedModule, PostsCalendarModule, ComponentsModule, PostViewModule],
  declarations: [
    HomeComponent,
    LoginComponent,
    LoginWithTokenComponent,
    SharedPostComponent,
    UserSettingsComponent,
    PostFormDefaultSettingsComponent,
    TimezoneSettingsComponent,
    PostsCalendarComponent,
  ],
})
export class PagesModule {}
