/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Message } from './message';
import { MessageTaskModel } from './messageTaskModel';


export interface UpdatePostV3Dto { 
    scheduleAt?: string;
    state?: UpdatePostV3Dto.StateEnum;
    /**
     * 0 - Disabled
     */
    deleteAfter?: number;
    pin: boolean;
    notifications?: boolean;
    enableComments?: boolean;
    postTasks?: MessageTaskModel;
    messages?: Array<Message>;
}
export namespace UpdatePostV3Dto {
    export type StateEnum = 'draft' | 'queue';
    export const StateEnum = {
        Draft: 'draft' as StateEnum,
        Queue: 'queue' as StateEnum
    };
}
