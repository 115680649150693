<mat-form-field
  style="width: 100%"
  appearance="outline"
  color="primary"
  [style.display]="channels && channels.length > 10 ? 'block' : 'none'"
>
  <mat-label i18n="Channels list cmp|label @@channelsListCmp.searchLabel">Поиск</mat-label>
  <input matInput #searchField />
</mat-form-field>
<mat-nav-list id="channelslists">
  <mat-list-item
    *ngFor="let channel of channels | search: searchField.value"
    [class.mat-elevation-z1]="type === 'elevation'"
    [routerLink]="['channel', channel.id]"
  >
    <div mat-line class="channel-block">
      <div class="block">
        <img matListAvatar [src]="avatarUrl(channel)" [alt]="channel.title" />
        <div class="channel-info">
          <h3 matLine style="max-width: 270px; overflow: hidden">{{ channel.title.toLocaleUpperCase() }}</h3>
          <p matLine>
            <span> @{{ channel.username }} </span>
            <span class="mat-secondary"
                  i18n="Channels list cmp|label @@channelsListCmp.labelMembers">- {{ channel.membersCount | number }}
              подп.</span>
            <span fxHide.xs="" *ngIf="channel.role !== 'owner'" class="already-admin"
                  i18n="Channels list cmp|label @@channelsListCmp.isEditorLabel">&nbsp;вы здесь редактор</span>
          </p>
        </div>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="channelMenu" aria-label="Open channel menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #channelMenu="matMenu" xPosition="before" [overlapTrigger]="true">
        <a mat-menu-item [href]="'https://t.me/' + channel.username" target="_blank"
           i18n="Channels list cmp|link @@channelsListCmp.linkOpenInTelegram">
          Открыть канал в Telegram
        </a>
      </mat-menu>
    </div>
  </mat-list-item>
  <div class="no-found" *ngIf="(channels | search: searchField.value).length === 0">
    <p i18n="Channels list cmp|text @@channelsListCmp.channelNotFoundText">
      Канала <b>{{ searchField.value }}</b> не найдено.
    </p>
  </div>
</mat-nav-list>
