/**
 * Telepost Api
 * Для авторизации зайти http://local.telepost.me/  В `/etc/hosts` добавить запись  ```sh 127.0.0.1 local.telepost.me ``` При авторизации добавть `Bearer` перед токеном         
 *
 * OpenAPI spec version: 2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InlineHiddenTextButton } from './inlineHiddenTextButton';
import { InlineLinkButton } from './inlineLinkButton';
import { InlineReactionButton } from './inlineReactionButton';


export interface InlineRowModel { 
    type: InlineRowModel.TypeEnum;
    links?: Array<InlineLinkButton>;
    reactions?: Array<InlineReactionButton>;
    hiddenTextButtons?: Array<InlineHiddenTextButton>;
    rowId?: string;
}
export namespace InlineRowModel {
    export type TypeEnum = 'links-row' | 'reaction' | 'hidden-text';
    export const TypeEnum = {
        LinksRow: 'links-row' as TypeEnum,
        Reaction: 'reaction' as TypeEnum,
        HiddenText: 'hidden-text' as TypeEnum
    };
}
