import { Pipe, PipeTransform } from '@angular/core';
import { QuillDelta } from '../api';
import { Platform } from '@angular/cdk/platform';

const map = {
  '<': '&lt;',
  '>': '&gt;',
  '&': '&amp;',
};

export function escapeHTML(s) {
  if (!s) {
    return s;
  }
  if (typeof s !== 'string') {
    return '';
  }
  return s.replace(/[<>\&]/g, m => map[m]);
}

@Pipe({
  name: 'quillRender',
  pure: true,
})
export class QuillRenderPipe implements PipeTransform {
  constructor(private platform: Platform) {}

  transform(delta: QuillDelta[], args?: any): any {
    // console.log(delta);
    if (!delta || delta.length === 0) {
      return '';
    }

    let html = '';
    for (let op of delta) {
      let text = escapeHTML(op.insert);

      if (!op.attributes) {
        html += text;
        continue;
      }
      if (op.attributes.code) {
        html += `<code>${text}</code>`;
        continue;
      }

      // pre should not be nested
      if (op.attributes.pre || op.attributes['code-block']) {
        html += `<pre>${text}</pre>`;
        continue;
      }

      if (op.attributes.bold) {
        text = `<b>${text}</b>`;
      }
      if (op.attributes.italic) {
        text = `<i>${text}</i>`;
      }
      if (op.attributes.underline) {
        text = `<u>${text}</u>`;
      }
      if (op.attributes.strike) {
        text = `<s>${text}</s>`;
      }
      if (op.attributes.spoiler) {
        text = `<span class="tg-spoiler">${text}</span>`;
      }
      if (op.attributes.link) {
        text = `<a href="${op.attributes.link}">${text}</a>`;
      }

      html += text;
    }
    return html.trim();
  }
}
