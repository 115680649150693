import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageDefaultSettings, UsersService } from '../../../api';
import { UserState } from '../../../auth/user.state';
import { UpdatePostFromSettings } from '../../../auth/auth.actions';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'tg-post-form-default-settings',
  templateUrl: './post-form-default-settings.component.html',
  styleUrls: ['./post-form-default-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostFormDefaultSettingsComponent implements OnInit {
  form: FormGroup;
  lastError;

  ___commentsFeature = this.store.selectSnapshot(UserState.feature('comments'))

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private userApi: UsersService,
    private snack: MatSnackBar
  ) {
    const defaults = this.store.selectSnapshot(UserState.messageDefaultSettings);
    this.initFns(defaults);
  }

  ngOnInit() {}

  initFns(postFormSettings: MessageDefaultSettings) {
    this.form = this.fb.group({
      previewLinks: this.fb.control(postFormSettings.previewLinks),
      notifications: this.fb.control(postFormSettings.notifications),
      enableComments: this.fb.control(postFormSettings.enableComments),
    });
  }

  save() {
    this.lastError = null;
    this.userApi
      .setPostFormSettings(this.form.value)
      .pipe(
        untilDestroyed(this),
        tap(
          rs => {
            this.store.dispatch(new UpdatePostFromSettings(rs));
            this.form.markAsPristine();
            this.snack.open($localize`:Post form settings|notify @@postFormSettings.saveSuccess:Настройки сохранены`, 'Ok');
            this.cd.detectChanges();
          },
          e => {
            this.lastError = e;
            this.cd.detectChanges();
          }
        )
      )
      .subscribe();
  }
}
