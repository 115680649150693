<tg-mobile-full-screen-dialog [fixedHeader]="true">
  <h1 class="title">
    <span *ngIf="!post" i18n="Post copy dialog|title @@postCopyDialog.title1">В какой канал скопировать пост?</span>
    <span *ngIf="post" i18n="Post copy dialog|title @@postCopyDialog.title2">Пост скопирован</span>
  </h1>
  <div>
    <tg-error-block [error]="err" *ngIf="err"></tg-error-block>
    <mat-nav-list *ngIf="!loading && !post">
      <mat-list-item *ngFor="let channel of (channels$ | async)" (click)="selectChannel(channel)">
        <div class="channel-block">
          <div>
            <img matListAvatar [src]="avatarUrl(channel)" [alt]="channel.title" />
            <div class="channel-info">
              <h3 matLine style="max-width: 400px; overflow: hidden">{{ channel.title }}</h3>
              <p matLine class="small">
                <span> @{{ channel.username }} </span>
                <span class="mat-secondary"
                      i18n="Post copy dialog|text @@postCopyDialog.membersCount">- {{ channel.membersCount | number }}
                  подп.</span>
              </p>
            </div>
          </div>
        </div>
      </mat-list-item>
    </mat-nav-list>
    <tg-inline-loader *ngIf="loading"></tg-inline-loader>
    <div *ngIf="post" i18n="Post copy dialog|text @@postCopyDialog.successText">Пост скопирован в черновики канала
      <b>{{ selectedChannel.title }}</b>.
    </div>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close *ngIf="!post" tabindex="-1"
              i18n="Post copy dialog|button @@postCopyDialog.cancelBtn">Отмена
      </button>
      <button
        mat-flat-button
        color="primary"
        *ngIf="post"
        [routerLink]="['channel', selectedChannel.id, 'draft', { edit: post.id }]"
        i18n="Post copy dialog|button @@postCopyDialog.editBtn"
      >
        Перейти к редактированию поста
      </button>
    </mat-dialog-actions>
  </div>
</tg-mobile-full-screen-dialog>


